import React from 'react'
import Branding from '../Sections/Branding'

export const Branding_design = () => {
  return (
    <>
        <Branding/>
    </>
  )
}

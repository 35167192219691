import React from 'react'
import Pricing_cards from '../Sections/Pricing_cards'
import Banner_packages from '../Sections/Banner_packages'


export const Packages = () => {
    return (
      <>
        <Banner_packages/>
        <Pricing_cards/>
      </>
    )
}
import React, { useState } from "react";
import BasicModal from "./Modal";
import axios from "axios";

export default function Modal({active,setActive}) {
  const [formData, setFormData] = useState({
    email: "",
    name: "",
    number: "",
    message: "",
    subject:"Request for Quotation"
  });

  const [open, setOpen] = useState(false);
const handleOpen = () => setOpen(true);
const [message,setMessage] = useState({error:true,mes:""})



  const onChange = (event) =>{
    const {name,value} = event.target
    setFormData({...formData,[name] : value})
  }

  const handleSubmit = async (event) =>{
    event.preventDefault()
    const data = {
      name: formData.name,
      email: formData.email,
      number: formData.number,
      message: formData.message,
     
    };
  
    try {
        
      if (formData.name && formData.email && formData.number && formData.message && formData.subject) {
        const response = await axios.post('https://thelogosdesigners.net/Backend/simpleemail/', data);
        setFormData('')
        setMessage({...message,mes:"Contact Form Submitted Successfully",error:false})
        handleOpen()
      
    
   } else{
    setMessage({...message,mes:"Fill All The values in the Form",error:true})
    handleOpen()
  }
  
    } catch (error) {
      setMessage({...message,mes:"Something Went Wrong Please Try Again",error:true})
      console.error(error);
      handleOpen()
    };
  

};
  return (
    <>
    <BasicModal  open={open} handleOpen={handleOpen} setOpen={setOpen} setMessage={setMessage} message={message}/>
    <div id="popup" style={{zIndex:99999999}} class={`custom-popup ${active? "acttive" : ""}`}>
      <div class="container">
        <a  id="popup-closed" style={{cursor:'pointer'}} class="closed" onClick={()=>{setActive(false)}}></a>
        <h3>Let's Build Something Great Together!</h3>
        <p>Drop us a line or view contact info.</p>
        <div id="popupform" class="fixedform-1" data-form-type="footer_form">
          <form
            class="row leadForm has-validation-callback"
            method="post"
            enctype="multipart/form-data"
            action="javascript:void(0)"
          >
            <input type="hidden" id="formType" name="formType" />
            <input type="hidden" id="referer" name="referer" />
            <input type="hidden" id="package_sku" value="" />
            <input type="hidden" id="price" value="0.00" />
            <input type="hidden" id="priceText" value="0.00" />
            <input type="hidden" id="title" value="" />
            <input type="hidden" id="package-id" value="" />

            <div class="col-md-6">
              <div class="mm-field namefield">
                
                <input placeholder="Your Name*" type="text" onChange={onChange} name="name" data-validation="required" />
              </div>
            </div>
            <div class="col-md-6">
              <div class="mm-field emailfield">
                
                <input placeholder="Your Email*" type="text" onChange={onChange} name="email" data-validation="required" />
              </div>
            </div>
            <div class="col-md-12">
              <div class="mm-field pnfields">
                
                <input placeholder="Phone Number*"
                onChange={onChange}
                  type="text"
                  name="number"
                  id="phone"
                  data-validation="required"
                />
              </div>
            </div>
            <div class="col-md-12">
              <div class="mm-field textarea">
                
                <textarea placeholder="Message"
                onChange={onChange}
                  name="message"
                  type="text"
                  id=""
                  rows="6"
                ></textarea>
              </div>
            </div>
            <div class="col-md-6">
              {/* <div
                class="g-recaptcha leadCaptcha"
                id="mainFormCaptcha"
                data-sitekey="6Lfy1nwmAAAAAJGIUDisHUjkFtcypEQmGa28C67J"
                data-validation="required"
                captcha-index="1"
              >
                <div style={{ 
                    width: "304px", height: "78px" }}>
                  <div>
                    <iframe
                      title="reCAPTCHA"
                      src="https://www.google.com/recaptcha/api2/anchor?ar=1&amp;k=6Lfy1nwmAAAAAOZu3oJDZPK6p46hWwQ6UyU-neyF&amp;co=ZmlsZTo.&amp;hl=en&amp;v=vkGiR-M4noX1963Xi_DB0JeI&amp;size=normal&amp;cb=yfh6jbff6cir"
                      width="304"
                      height="78"
                      role="presentation"
                      name="a-7fpxt1ft3ttt"
                      frameborder="0"
                      scrolling="no"
                      sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation allow-modals allow-popups-to-escape-sandbox"
                    ></iframe>
                  </div>
                  <textarea
                    id="g-recaptcha-response-1"
                    name="g-recaptcha-response"
                    class="g-recaptcha-response"
                    style={{width: "250px", height: "40px", border: "1px solid rgb(193, 193, 193)", margin: "10px 25px", padding: "0px", resize: "none", display: "none"}}
                  ></textarea>
                </div>
                <iframe style={{display: "none"}}></iframe>
              </div> */}
              <span id="mainFormCaptchaError"></span>
            </div>
            <div class="col-md-6">
              <div class="input-btn">
                <div id="formResult"></div>
                <div class="clearfix"></div>
                {/* <!-- <input type="submit" value="Submit Your Request"> --> */}
                <button
                  onClick={handleSubmit}
                  id="signupBtn"
                  class="contact-btn default-btn btn"
                >
                  Submit Your Request
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    
    </>
  );
}

import React from 'react'
import Review_client from '../Sections/Review_client'
import { Button } from '@mui/material'


export const Review = () => {
    return (
      <>
        <Review_client/>
    
      </>
    )
}
import React from 'react'

export default function Banner_contact() {

  return (
    <>
    <div className="contact">
       <section className="bannermm">
        <div className="container">
            <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="leftcont-ban">
                        <h1 className="montfont">
                            <span>Our Dedicated</span>
                            <br/>
                            <strong>Support Staff </strong> Is Active 24x7 to Assist You. </h1>
                        <p>Know Everything About Your Designs.</p>
                        {/* <!-- <a className="montfont" href="javascript:;" title="">Start Your Project <i className="sprite btnarow"></i></a> --> */}
                    </div>
                </div>
            </div>
        </div>
    </section>    
    </div>
    </>
  )
}
import React from 'react';
import ReactDOM from 'react-dom/client';
import './Styles/index.css';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import Navbar from './Sections/Navbar';
import Footer_site from './Sections/Footer_site';
import Chat from './Chat';
import { TawkProvider } from './TawkContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router>
      <Navbar />
      <TawkProvider>
      <App />
      <Chat />
    </TawkProvider>
      <Footer_site />
    </Router>
);

import React from "react";
import { useNavigate } from "react-router-dom";
import { scroller } from "react-scroll";

export default function CustomLink({ children, to, tabid, li_id }) {
  const navigate = useNavigate();

  function handleClick(to, tabid, li_id) {
    navigate(to);

    setTimeout(() => {
      const ul = document.getElementById("tabsclick");
      const div = document.getElementById('portfolio-mmbx')
      div.querySelectorAll("div").forEach((child) => {
        if (child.classList.contains("active","in")) {
            child.classList.remove("active","in");
           
        }
      });
      ul.querySelectorAll("li").forEach((li) => {
        if (li.classList.contains("active")) {
          li.classList.remove("active");
          li.querySelector('span').ariaExpanded = false
        }
      });
      const tab = document.getElementById(tabid);
      const li = document.getElementById(li_id);
      li.classList.add("active");
      li.querySelector('span').ariaExpanded = true

      tab.classList.add("active","in");
      scroller.scrollTo(tabid, {
        spy: true,
        smooth: true,
        offset: 0,
        duration: 500,
      });
    }, 100);
  }
  return (
    <a
      className="no-src"
      name="logo_2"
      onClick={() => handleClick(to, tabid, li_id)}
    >
      {children}
    </a>
  );
}

import React from 'react'
import Banner_porfolio from '../Sections/Banner_porfolio'
import Show_case from '../Sections/Show_case'
// import Testimonail_Section from './Sections/Testimonail_Section'
import Portfolio from '../Sections/Portfolio'

export const Portfoli = () => {
    return (
      <>
      <Banner_porfolio/>
      <Show_case/>  
      <Portfolio/>
      </>
    )
}
import React from 'react'
import $ from 'jquery';
import { useLocation, useNavigate } from 'react-router-dom';
import { scroller } from "react-scroll";
import Web_Portfolio from './Web_Portfolio';

export default function Show_case() {
   const navigate = useNavigate();
  
   const scrollTo = (ref, id) => {
     navigate(ref);
     setTimeout(() => {
       scroller.scrollTo(id, {
         spy: true,
         smooth: true,
         offset: 0,
         duration: 500,
       });
     }, 100);
   };
   const location = useLocation();
  const currentPath = location.pathname;


  
  return (
    <>
          <section className="homeport-mm" id='porfolio'>
         <div className="container">
            <div className="sechead">
               <h3 className="montfont">Take a Sneak Peek at our Portfolio</h3>
               <p>Improve the User Experience And Capture New Opportunities With Us</p>
            </div>
         </div>
         <div className="portfoliomaindivs tabsmainport">
            <ul className="toptabs1 tabsclick ">
               <li className="active">
               {/* data-href="#All" */}
                  <span type="button" id="stars" class="btn" href="#tab1" data-toggle="tab">All</span>
               </li>
               <li>
                  <span type="button" id="favorites" class="btn btn-default" href="#tab2" data-toggle="tab">Logo Design</span>
               </li>
               <li>
                  <span type="button" id="favorites" class="btn btn-default" href="#tab3" data-toggle="tab">Website Design</span>
               </li>
               <li>
                  <span type="button" id="favorites" class="btn btn-default" href="#tab4" data-toggle="tab">Animation</span>
               </li>
               <li>
                  <span  type="button" id="favorites" class="btn btn-default" href="#tab5" data-toggle="tab">Branding</span>
               </li>
               <li>
                  <span  type="button" id="favorites" class="btn btn-default" href="#tab6" data-toggle="tab">Illustration</span>
               </li>
            </ul>
            <div className="topbartabs">
               <div id="tab1"  className="tabs-portfolio innertabcontents active tab-pane fade in">
                  <div className="portfolio-mmbx">
                     <div id="allport" className="innertabcontents active">
                        {/* <div className="row md-hidden">
                           <div className="col-md-3">
                              <div className="box-ports">
                                 <img src="images/portfolio/logos/photography/01.jpg" alt=""/>
                                 <div className="hovereffects-det">
                                    <h3>Magic Smiles</h3>
                                    <div className="btnstyles-nn">
                                       <a data-fancybox="allportfolio" href="images/portfolio/logos/photography/01.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div className="col-md-9">
                              <div className="row">
                                 <div className="col-md-6">
                                    <div className="box-ports">
                                       <img src="images/portfolio/website/thumbnail/01.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>KA PAI</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="allportfolio" href="images/portfolio/website/web-1.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-6">
                                    <div className="box-ports">
                                       <img src="images/portfolio/website/thumbnail/02.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>Shipper Link</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="allportfolio" href="images/portfolio/website/web-2.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div> */}
                        {/* <div className="row md-hidden">
                           <div className="col-md-4">
                              <div className="box-ports">
                                 <img src="./images/portfolio/website/thumbnail/05.png" alt=""/>
                                 <div className="hovereffects-det">
                                    <h3>IT Consulting</h3>
                                    <div className="btnstyles-nn">
                                       <a data-fancybox="allportfolio" href="./images/portfolio/website/05.webp" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div className="col-md-9">
                              <div className="row">
                                 <div className="col-md-6">
                                    <div className="box-ports">
                                       <img src="./images/portfolio/website/thumbnail/02.png" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>Gaming</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="allportfolio" href="./images/portfolio/website/02.webp" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-6">
                                    <div className="box-ports">
                                       <img src="./images/portfolio/website/thumbnail/04.png" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>Zamil</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="allportfolio" href="./images/portfolio/website/04.webp" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="row md-hidden">
                           <div className="col-md-3">
                              <div className="box-ports">
                                 <img src="./images/portfolio/website/thumbnail/010.png" alt=""/>
                                 <div className="hovereffects-det">
                                    <h3>Beezo</h3>
                                    <div className="btnstyles-nn">
                                       <a data-fancybox="allportfolio" href="./images/portfolio/website/010.webp" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div className="col-md-9">
                              <div className="row">
                                 <div className="col-md-6">
                                    <div className="box-ports">
                                       <img src="./images/portfolio/website/thumbnail/08.png" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>PawWelfare</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="allportfolio" href="./images/portfolio/website/08.webp" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-6">
                                    <div className="box-ports">
                                       <img src="./images/portfolio/website/thumbnail/09.png" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>Gana</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="allportfolio" href="./images/portfolio/website/09.webp" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div> */}
                        <Web_Portfolio/>
                        <div className="row responsive-slider">
                           <div className="col-md-3">
                              <div className="box-ports">
                                 <img src="images/portfolio/branding/Stationary01.jpg" alt=""/>
                                 <div className="hovereffects-det">
                                    <h3>Divine Fresh</h3>
                                    <div className="btnstyles-nn">
                                       <a data-fancybox="allportfolio" href="images/portfolio/branding/Stationary01.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div className="col-md-3">
                              <div className="box-ports">
                                 <img src="images/portfolio/branding/Stationary02.jpg" alt=""/>
                                 <div className="hovereffects-det">
                                    <h3>Trinity Financial Group</h3>
                                    <div className="btnstyles-nn">
                                       <a data-fancybox="allportfolio" href="images/portfolio/branding/Stationary02.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div className="col-md-3">
                              <div className="box-ports">
                                 <img src="images/portfolio/logos/fashion/3.jpg" alt=""/>
                                 <div className="hovereffects-det">
                                    <h3>India's Couture Hair</h3>
                                    <div className="btnstyles-nn">
                                       <a data-fancybox="allportfolio" href="images/portfolio/logos/fashion/3.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div className="col-md-3">
                              <div className="box-ports">
                                 <img src="images/portfolio/logos/food/6.jpg" alt=""/>
                                 <div className="hovereffects-det">
                                    <h3>Preferito Cupcakes</h3>
                                    <div className="btnstyles-nn">
                                       <a data-fancybox="allportfolio" href="images/portfolio/logos/food/6.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     
                     { currentPath!=="/portfolio" &&<div className="container leftcont-ban">
                     <a  class="montfont" onClick={(ref, id) =>
                                scrollTo(
                                  (ref = "/portfolio"),
                                  (id = "top")
                                )
                              }  title="">View All Projects <i class="sprite btnarow"></i></a>
                     </div>}
                  </div>
               </div>
               <div id="tab2" className="tabs-portfolio innertabcontents tab-pane fade in">
                  <div className="container">
                     <div className="tabschildrens">
                        <ul className="tabsclick">
                           <li className="active">
                              <span type="button" id="stars" class="btn" href="#tab7" data-toggle="tab">All</span>
                           </li>
                           <li>
                              <span type="button" id="stars" class="btn" href="#tab8" data-toggle="tab">Security Services</span>
                           </li>
                           <li>
                              <span type="button" id="stars" class="btn" href="#tab9" data-toggle="tab">Sports and Fitness</span>
                           </li>
                           <li>
                              <span type="button" id="stars" class="btn" href="#tab10" data-toggle="tab">Technology</span>
                           </li>
                           <li>
                              <span type="button" id="stars" class="btn" href="#tab11" data-toggle="tab">Cleaning</span>
                           </li>
                           <li>
                              <span type="button" id="stars" class="btn" href="#tab12" data-toggle="tab">Construction</span>
                           </li>
                           <li>
                              <span type="button" id="stars" class="btn" href="#tab13" data-toggle="tab">Creative Arts</span>
                           </li>
                           <li>
                              <span type="button" id="stars" class="btn" href="#tab14" data-toggle="tab">Fashion</span>
                           </li>
                           <li>
                              <span type="button" id="stars" class="btn" href="#tab15" data-toggle="tab">Restaurants</span>
                           </li>
                           <li>
                              <span type="button" id="stars" class="btn" href="#tab16" data-toggle="tab">Pet Care</span>
                           </li>
                           <li>
                              <span type="button" id="stars" class="btn" href="#tab17" data-toggle="tab">Photography</span>
                           </li>
                           <li>
                              <span type="button" id="stars" class="btn" href="#tab18" data-toggle="tab">Real Estate</span>
                           </li>
                           <li>
                              <span type="button" id="stars" class="btn" href="#tab19" data-toggle="tab">Travel</span>
                           </li>
                        </ul>
                     </div>
                  </div>
                  <div className="portfolio-mmbx">
                     <div id="tab7" className="innertabcontents active tab-pane fade in">
                        <div className="row">
                           <div className="col-md-12">
                              <div className="row responsive-slider">
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/new/1.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>Royal Wash</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/new/1.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/new/2.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>Tanner Lawly art group</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/new/2.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/new/3.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>poco loco</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/new/3.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/new/4.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>chase fitness</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/new/4.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/new/5.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>oakworth fried chicken</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/new/5.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/new/6.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>doggy day care</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/new/6.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/new/7.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>ravelo travel and cruise</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/new/7.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/new/8.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>lord and smith travel</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/new/8.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div id="tab8" className="innertabcontents tab-pane fade in">
                        <div className="row">
                           <div className="col-md-12">
                              <div className="row responsive-slider">
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/cleaning/1.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>Prime Cleaning Solutions</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/cleaning/1.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/cleaning/2.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>10 Kudos Pools</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/cleaning/2.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/cleaning/3.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>Horizon Cleaning INC</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/cleaning/3.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/cleaning/4.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>Royal Wash</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/cleaning/4.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/cleaning/5.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>VIC Company Cleaning Services</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/cleaning/5.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/cleaning/6.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>Yaccs INC</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/cleaning/6.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/cleaning/7.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>Angel House</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/cleaning/7.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/cleaning/8.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>Dryer Vent 911</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/cleaning/8.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div id="tab9" className="innertabcontents tab-pane fade in">
                        <div className="row">
                           <div className="col-md-12">
                              <div className="row responsive-slider">
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/construction/2.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>National Ejtiaz</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/construction/2.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/construction/3.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>Parsons Construction Group</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/construction/3.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/construction/4.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>Herrmann Construction Corp</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/construction/4.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/construction/5.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>D boylan</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/construction/5.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/construction/6.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>OXBuilt Construction LTC</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/construction/6.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/construction/7.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>Ikonic Construction PTY LTD</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/construction/7.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/construction/8.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>Unique Reflections</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/construction/8.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/construction/9.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>SSCO</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/construction/9.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div id="tab10" className="innertabcontents tab-pane fade in">
                        <div className="row">
                           <div className="col-md-12">
                              <div className="row responsive-slider">
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/creative/2.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>Science Lab</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/creative/2.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/creative/3.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>Freddie's Creative Arts</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/creative/3.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/creative/4.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>Tanner Lawley Art Group</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/creative/4.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/creative/5.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>7th SUN</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/creative/5.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/creative/6.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>Picture Perfect by vini</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/creative/6.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/creative/7.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>Starline</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/creative/7.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/creative/8.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>Mandarin Home</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/creative/8.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/creative/10.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>Of the Axis Desert</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/creative/10.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div id="tab11" className="innertabcontents tab-pane fade in">
                        <div className="row">
                           <div className="col-md-12">
                              <div className="row responsive-slider">
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/fashion/2.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>Lust Pink</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/fashion/2.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/fashion/3.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>India's couture hair</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/fashion/3.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/fashion/4.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>Poco Loco</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/fashion/4.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/fashion/5.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>VIP Nail Bar</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/fashion/5.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/fashion/6.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>Oxygen Fashion & Beauty</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/fashion/6.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/fashion/7.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>Evolution Professional MakeUp Artistry</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/fashion/7.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/fashion/8.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>Beautiful you</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/fashion/8.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/fashion/10.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>Talia Cheng</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/fashion/10.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div id="tab12" className="innertabcontents tab-pane fade in">
                        <div className="row">
                           <div className="col-md-12">
                              <div className="row responsive-slider">
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/food/2.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>Jorco Mountain Coffee</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/food/2.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/food/3.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>GEORGIE'S Kettle corn</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/food/3.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/food/4.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>Oakworth Fried Chicken</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/food/4.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/food/5.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>Our Little Secret</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/food/5.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/food/6.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>Preferito Cupcakes</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/food/6.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/food/7.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>Sweet Sweeter</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/food/7.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/food/8.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>Pigs Q'n Hogs BBQ</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/food/8.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/food/10.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>Orange Flame</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/food/10.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div id="tab13" className="innertabcontents tab-pane fade in">
                        <div className="row">
                           <div className="col-md-12">
                              <div className="row responsive-slider">
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/pet/2.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>Doggy Day Care</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/pet/2.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/pet/3.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>The Dog and Bone</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/pet/3.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/pet/4.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>Golden West Cat Club</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/pet/4.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/pet/5.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>Tiny Tim's Pals</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/pet/5.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/pet/6.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>Wagon Tail Pet Supplies</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/pet/6.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/pet/7.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>Alpha Animal Hospital</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/pet/7.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/pet/8.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>Charlie's place</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/pet/8.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/pet/9.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>Mastiffs R Us, inc</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/pet/9.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 {/* <!-- <div className="col-md-3">
                                    <div className="box-ports">
                                    <img data-src="images/portfolio/logos/pet/10.jpg" alt=""/>
                                    <div className="hovereffects-det">
                                    	<h3>sociis natoque</h3>
                                    	<div className="btnstyles-nn">
                                    	<a data-fancybox="logos" href="images/portfolio/logos/pet/10.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                    	</div>
                                    </div>
                                    </div>
                                    </div> --> */}
                              </div>
                           </div>
                        </div>
                     </div>
                     <div id="tab14" className="innertabcontents tab-pane fade in">
                        <div className="row">
                           <div className="col-md-12">
                              <div className="row responsive-slider">
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/photography/01.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>Magic Smiles PhotoBooth</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/photography/01.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/photography/02.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>Krystal Vision</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/photography/02.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/photography/04.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>Chi Cagraphy</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/photography/04.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/photography/05.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>Abdulrahman Fakhroo</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/photography/05.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/photography/07.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>Celebrity Photobooth</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/photography/07.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/photography/08.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>Paul Rattray Photography</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/photography/08.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/photography/09.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>Darren M Turner</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/photography/09.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/photography/10.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>J.Molina Photography</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/photography/10.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div id="tab15" className="innertabcontents tab-pane fade in">
                        <div className="row">
                           <div className="col-md-12">
                              <div className="row responsive-slider">
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/realestate/02.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>MIG Moneil Investments Group, LLC</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/realestate/02.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/realestate/03.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>One Call realestate</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/realestate/03.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/realestate/04.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>Perl realestate</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/realestate/04.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/realestate/05.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>Harmony Homes</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/realestate/05.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/realestate/06.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>Christopher Fritts</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/realestate/06.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/realestate/07.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>Shafie's realestate</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/realestate/07.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/realestate/09.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>Pro Inventory</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/realestate/09.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/realestate/10.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>Naples Property Preview</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/realestate/10.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div id="tab16" className="innertabcontents tab-pane fade in">
                        <div className="row">
                           <div className="col-md-12">
                              <div className="row responsive-slider">
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/security/01.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>senpro group security services</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/security/01.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/security/02.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>impi risk consulting</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/security/02.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/security/03.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>solar facilities</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/security/03.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/security/04.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>all free scrore</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/security/04.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/security/05.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>amh security</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/security/05.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/security/06.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>cobra drape clip</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/security/06.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/security/07.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>mg armory</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/security/07.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/security/08.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>hi tech house</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/security/08.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div id="tab17" className="innertabcontents tab-pane fade in">
                        <div className="row">
                           <div className="col-md-12">
                              <div className="row responsive-slider">
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/sports/01.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>Chase Fitness</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/sports/01.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/sports/02.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>Versatao</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/sports/02.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/sports/03.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>epic hockey</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/sports/03.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/sports/04.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>Xtreme Champions</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/sports/04.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/sports/05.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>Qatar Flag Football</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/sports/05.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/sports/06.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>hebattanpabates</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/sports/06.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/sports/07.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>Hawks Lacrosse</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/sports/07.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/sports/08.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>Huskies Water polo</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/sports/08.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div id="tab18" className="innertabcontents tab-pane fade in">
                        <div className="row">
                           <div className="col-md-12">
                              <div className="row responsive-slider">
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/technology/01.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>tablet distributors</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/technology/01.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/technology/02.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>agrity</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/technology/02.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/technology/03.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>gamers online</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/technology/03.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/technology/04.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>cell tech one</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/technology/04.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/technology/05.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>hosting eyes</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/technology/05.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/technology/06.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>ecopy tech</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/technology/06.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/technology/07.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>hash erp</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/technology/07.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/technology/08.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>Hi Tec House</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/technology/08.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div id="tab19" className="innertabcontents tab-pane fade in">
                        <div className="row">
                           <div className="col-md-12">
                              <div className="row responsive-slider">
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/travel/09.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>Lord and Smith Travel</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/travel/09.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/travel/04.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>Amjaad Travel</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/travel/04.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/travel/05.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>Ravelo Travel & Cruise</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/travel/05.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/travel/06.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>Journeys & Jaunts</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/travel/06.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/travel/07.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>Khtoot Alkhayala Travel & Tourism</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/travel/07.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/travel/08.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>Emigrate to Australia</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/travel/08.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/travel/03.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>access to Global</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/travel/03.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/logos/travel/10.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>Diversified Travel</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="logos" href="images/portfolio/logos/travel/10.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     { currentPath!=="/portfolio" &&<div className="container leftcont-ban">
                     <a onClick={(ref, id) =>
                                scrollTo(
                                  (ref = "/portfolio"),
                                  (id = "top")
                                )
                              } class="montfont"  title="">View All Projects <i class="sprite btnarow"></i></a>
                        {/* <i className="sprite btnarow"></i> */}
                     </div>}
                  </div>
               </div>
               <div id="tab3" className="tabs-portfolio innertabcontents tab-pane fade in">
                  <div className="portfolio-mmbx">
<Web_Portfolio/>

{ currentPath!=="/portfolio" &&<div className="container leftcont-ban">
                     <a  onClick={(ref, id) =>
                                scrollTo(
                                  (ref = "/portfolio"),
                                  (id = "top")
                                )
                              } class="montfont"  title="">View All Projects <i class="sprite btnarow"></i></a>
                     </div>}
                  </div>
               </div>
               <div id="tab4" className="tabs-portfolio innertabcontents tab-pane fade in">
                  <div className="portfolio-mmbx">
                     <div id="innerall2" className="innertabcontents active">
                        <div className="row">
                           <div className="col-md-12">
                              <div className="row responsive-slider">
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/animation/Antonio.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>United Medical Alert</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="animation" href="https://digitdesignstudios.com/video-portfolio/01.mp4" title="">Preview <i className="fa fa-play-circle-o" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/animation/Broadway.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>BroadWay market <br/>Corned Beef</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="animation" href="https://digitdesignstudios.com/video-portfolio/02.mp4" title="">Preview <i className="fa fa-play-circle-o" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/animation/Cab.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>Ice Cream</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="animation" href="https://digitdesignstudios.com/video-portfolio/03.mp4" title="">Preview <i className="fa fa-play-circle-o" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/animation/CAB--Get-Along.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>Get along with each other</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="animation" href="https://digitdesignstudios.com/video-portfolio/04.mp4" title="">Preview <i className="fa fa-play-circle-o" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/animation/Camco.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>Immersive Reality</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="animation" href="https://digitdesignstudios.com/video-portfolio/05.mp4" title="">Preview <i className="fa fa-play-circle-o" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/animation/Carmel.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>Birthday parties</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="animation" href="https://digitdesignstudios.com/video-portfolio/06.mp4" title="">Preview <i className="fa fa-play-circle-o" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/animation/christina.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>Fibre King Engineering</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="animation" href="https://digitdesignstudios.com/video-portfolio/07.mp4" title="">Preview <i className="fa fa-play-circle-o" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-3">
                                    <div className="box-ports">
                                       <img data-src="images/portfolio/animation/COMIC_final.jpg" alt=""/>
                                       <div className="hovereffects-det">
                                          <h3>The Block Party Podcast</h3>
                                          <div className="btnstyles-nn">
                                             <a data-fancybox="animation" href="https://digitdesignstudios.com/video-portfolio/08.mp4" title="">Preview <i className="fa fa-play-circle-o" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     { currentPath!=="/portfolio" &&<div className="container leftcont-ban">
                     <a onClick={(ref, id) =>
                                scrollTo(
                                  (ref = "/portfolio"),
                                  (id = "top")
                                )
                              } class="montfont"  title="">View All Projects <i class="sprite btnarow"></i></a>
                     </div>}
                  </div>
               </div>
               <div id="tab5" className="tabs-portfolio innertabcontents tab-pane fade in">
                  <div className="container">
                     <div className="tabschildrens">
                        <ul className="tabsclick">
                           <li className="port5 active">  
                              <span data-href="#bannerport" title="">Banner</span>
                           </li>
                           <li className="port6">  
                              <span data-href="#stationeryport" title="">Stationery</span>
                           </li>
                           <li className="port7">  
                              <span data-href="#packaginngport" title="">Packaging</span>
                           </li>
                           <li className="port8">  
                              <span data-href="#broucherport" title="">Brochures </span>
                           </li>
                        </ul>
                     </div>
                  </div>
                  <div className="portfolio-mmbx">
                     <div id="bannerport" className="tabs-portfolio innertabcontents active">
                        <div className="portfolio-mmbx edit-1">
                           <div id="innerall2" className="innertabcontents active">
                              <div className="row">
                                 <div className="col-md-12">
                                    <div className="row responsive-slider">
                                       <div className="col-md-3">
                                          <div className="box-ports">
                                             <img data-src="images/portfolio/banner/01.jpg" alt=""/>
                                             <div className="hovereffects-det">
                                                <h3></h3>
                                                <div className="btnstyles-nn">
                                                   <a data-fancybox="bannerPortfo" href="images/portfolio/banner/01.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="col-md-3">
                                          <div className="box-ports">
                                             <img data-src="images/portfolio/banner/02.jpg" alt=""/>
                                             <div className="hovereffects-det">
                                                <h3></h3>
                                                <div className="btnstyles-nn">
                                                   <a data-fancybox="bannerPortfo" href="images/portfolio/banner/02.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="col-md-3">
                                          <div className="box-ports">
                                             <img data-src="images/portfolio/banner/06.jpg" alt=""/>
                                             <div className="hovereffects-det">
                                                <h3></h3>
                                                <div className="btnstyles-nn">
                                                   <a data-fancybox="bannerPortfo" href="images/portfolio/banner/06.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="col-md-3">
                                          <div className="box-ports">
                                             <img data-src="images/portfolio/banner/07.jpg" alt=""/>
                                             <div className="hovereffects-det">
                                                <h3></h3>
                                                <div className="btnstyles-nn">
                                                   <a data-fancybox="bannerPortfo" href="images/portfolio/banner/07.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="col-md-3">
                                          <div className="box-ports">
                                             <img data-src="images/portfolio/banner/08.jpg" alt=""/>
                                             <div className="hovereffects-det">
                                                <h3></h3>
                                                <div className="btnstyles-nn">
                                                   <a data-fancybox="bannerPortfo" href="images/portfolio/banner/08.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="col-md-3">
                                          <div className="box-ports">
                                             <img data-src="images/portfolio/banner/09.jpg" alt=""/>
                                             <div className="hovereffects-det">
                                                <h3></h3>
                                                <div className="btnstyles-nn">
                                                   <a data-fancybox="bannerPortfo" href="images/portfolio/banner/09.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="col-md-3">
                                          <div className="box-ports">
                                             <img data-src="images/portfolio/banner/10.jpg" alt=""/>
                                             <div className="hovereffects-det">
                                                <h3></h3>
                                                <div className="btnstyles-nn">
                                                   <a data-fancybox="bannerPortfo" href="images/portfolio/banner/10.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="col-md-3">
                                          <div className="box-ports">
                                             <img data-src="images/portfolio/banner/12.jpg" alt=""/>
                                             <div className="hovereffects-det">
                                                <h3></h3>
                                                <div className="btnstyles-nn">
                                                   <a data-fancybox="bannerPortfo" href="images/portfolio/banner/12.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="col-md-3">
                                          <div className="box-ports">
                                             <img data-src="images/portfolio/banner/13.jpg" alt=""/>
                                             <div className="hovereffects-det">
                                                <h3></h3>
                                                <div className="btnstyles-nn">
                                                   <a data-fancybox="bannerPortfo" href="images/portfolio/banner/13.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="col-md-3">
                                          <div className="box-ports">
                                             <img data-src="images/portfolio/banner/14.jpg" alt=""/>
                                             <div className="hovereffects-det">
                                                <h3></h3>
                                                <div className="btnstyles-nn">
                                                   <a data-fancybox="bannerPortfo" href="images/portfolio/banner/14.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="col-md-3">
                                          <div className="box-ports">
                                             <img data-src="images/portfolio/banner/15.jpg" alt=""/>
                                             <div className="hovereffects-det">
                                                <h3></h3>
                                                <div className="btnstyles-nn">
                                                   <a data-fancybox="bannerPortfo" href="images/portfolio/banner/15.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="col-md-3">
                                          <div className="box-ports">
                                             <img data-src="images/portfolio/banner/16.jpg" alt=""/>
                                             <div className="hovereffects-det">
                                                <h3></h3>
                                                <div className="btnstyles-nn">
                                                   <a data-fancybox="bannerPortfo" href="images/portfolio/banner/16.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="col-md-3">
                                          <div className="box-ports">
                                             <img data-src="images/portfolio/banner/18.jpg" alt=""/>
                                             <div className="hovereffects-det">
                                                <h3></h3>
                                                <div className="btnstyles-nn">
                                                   <a data-fancybox="bannerPortfo" href="images/portfolio/banner/18.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="col-md-3">
                                          <div className="box-ports">
                                             <img data-src="images/portfolio/banner/19.jpg" alt=""/>
                                             <div className="hovereffects-det">
                                                <h3></h3>
                                                <div className="btnstyles-nn">
                                                   <a data-fancybox="bannerPortfo" href="images/portfolio/banner/19.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="col-md-3">
                                          <div className="box-ports">
                                             <img data-src="images/portfolio/banner/20.jpg" alt=""/>
                                             <div className="hovereffects-det">
                                                <h3></h3>
                                                <div className="btnstyles-nn">
                                                   <a data-fancybox="bannerPortfo" href="images/portfolio/banner/20.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="col-md-3">
                                          <div className="box-ports">
                                             <img data-src="images/portfolio/banner/21.jpg" alt=""/>
                                             <div className="hovereffects-det">
                                                <h3></h3>
                                                <div className="btnstyles-nn">
                                                   <a data-fancybox="bannerPortfo" href="images/portfolio/banner/21.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="col-md-3">
                                          <div className="box-ports">
                                             <img data-src="images/portfolio/banner/22.jpg" alt=""/>
                                             <div className="hovereffects-det">
                                                <h3></h3>
                                                <div className="btnstyles-nn">
                                                   <a data-fancybox="bannerPortfo" href="images/portfolio/banner/22.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="col-md-3">
                                          <div className="box-ports">
                                             <img data-src="images/portfolio/banner/23.jpg" alt=""/>
                                             <div className="hovereffects-det">
                                                <h3></h3>
                                                <div className="btnstyles-nn">
                                                   <a data-fancybox="bannerPortfo" href="images/portfolio/banner/23.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="col-md-3">
                                          <div className="box-ports">
                                             <img data-src="images/portfolio/banner/24.jpg" alt=""/>
                                             <div className="hovereffects-det">
                                                <h3></h3>
                                                <div className="btnstyles-nn">
                                                   <a data-fancybox="bannerPortfo" href="images/portfolio/banner/24.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="col-md-3">
                                          <div className="box-ports">
                                             <img data-src="images/portfolio/banner/25.jpg" alt=""/>
                                             <div className="hovereffects-det">
                                                <h3></h3>
                                                <div className="btnstyles-nn">
                                                   <a data-fancybox="bannerPortfo" href="images/portfolio/banner/25.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="col-md-3">
                                          <div className="box-ports">
                                             <img data-src="images/portfolio/banner/26.jpg" alt=""/>
                                             <div className="hovereffects-det">
                                                <h3></h3>
                                                <div className="btnstyles-nn">
                                                   <a data-fancybox="bannerPortfo" href="images/portfolio/banner/26.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="col-md-3">
                                          <div className="box-ports">
                                             <img data-src="images/portfolio/banner/27.jpg" alt=""/>
                                             <div className="hovereffects-det">
                                                <h3></h3>
                                                <div className="btnstyles-nn">
                                                   <a data-fancybox="bannerPortfo" href="images/portfolio/banner/27.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="col-md-3">
                                          <div className="box-ports">
                                             <img data-src="images/portfolio/banner/28.jpg" alt=""/>
                                             <div className="hovereffects-det">
                                                <h3></h3>
                                                <div className="btnstyles-nn">
                                                   <a data-fancybox="bannerPortfo" href="images/portfolio/banner/28.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="col-md-3">
                                          <div className="box-ports">
                                             <img data-src="images/portfolio/banner/29.jpg" alt=""/>
                                             <div className="hovereffects-det">
                                                <h3></h3>
                                                <div className="btnstyles-nn">
                                                   <a data-fancybox="bannerPortfo" href="images/portfolio/banner/29.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="col-md-3">
                                          <div className="box-ports">
                                             <img data-src="images/portfolio/banner/30.jpg" alt=""/>
                                             <div className="hovereffects-det">
                                                <h3></h3>
                                                <div className="btnstyles-nn">
                                                   <a data-fancybox="bannerPortfo" href="images/portfolio/banner/30.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="col-md-3">
                                          <div className="box-ports">
                                             <img data-src="images/portfolio/banner/31.jpg" alt=""/>
                                             <div className="hovereffects-det">
                                                <h3></h3>
                                                <div className="btnstyles-nn">
                                                   <a data-fancybox="bannerPortfo" href="images/portfolio/banner/31.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="col-md-3">
                                          <div className="box-ports">
                                             <img data-src="images/portfolio/banner/32.jpg" alt=""/>
                                             <div className="hovereffects-det">
                                                <h3></h3>
                                                <div className="btnstyles-nn">
                                                   <a data-fancybox="bannerPortfo" href="images/portfolio/banner/32.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="col-md-3">
                                          <div className="box-ports">
                                             <img data-src="images/portfolio/banner/33.jpg" alt=""/>
                                             <div className="hovereffects-det">
                                                <h3></h3>
                                                <div className="btnstyles-nn">
                                                   <a data-fancybox="bannerPortfo" href="images/portfolio/banner/33.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div className="container leftcont-ban fixportfoliobtns">
                              <a className="montfont" href="#" title="">View Complete Portfolio <i className="sprite btnarow"></i></a>
                           </div>
                        </div>
                     </div>
                     <div id="stationeryport" className="tabs-portfolio innertabcontents">
                        <div className="portfolio-mmbx">
                           <div id="innerall2" className="innertabcontents active">
                              <div className="row">
                                 <div className="col-md-12">
                                    <div className="row responsive-slider">
                                       <div className="col-md-3">
                                          <div className="box-ports">
                                             <img data-src="images/portfolio/branding/Stationary01.jpg" alt=""/>
                                             <div className="hovereffects-det">
                                                <h3>Divine Fresh</h3>
                                                <div className="btnstyles-nn">
                                                   <a data-fancybox="branding" href="images/portfolio/branding/Stationary01.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="col-md-3">
                                          <div className="box-ports">
                                             <img data-src="images/portfolio/branding/Stationary02.jpg" alt=""/>
                                             <div className="hovereffects-det">
                                                <h3>Trinity Financial Group</h3>
                                                <div className="btnstyles-nn">
                                                   <a data-fancybox="branding" href="images/portfolio/branding/Stationary02.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="col-md-3">
                                          <div className="box-ports">
                                             <img data-src="images/portfolio/branding/Stationary03.jpg" alt=""/>
                                             <div className="hovereffects-det">
                                                <h3>Cutting Edge</h3>
                                                <div className="btnstyles-nn">
                                                   <a data-fancybox="branding" href="images/portfolio/branding/Stationary03.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="col-md-3">
                                          <div className="box-ports">
                                             <img data-src="images/portfolio/branding/Stationary04.jpg" alt=""/>
                                             <div className="hovereffects-det">
                                                <h3>Golden Fox</h3>
                                                <div className="btnstyles-nn">
                                                   <a data-fancybox="branding" href="images/portfolio/branding/Stationary04.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="col-md-3">
                                          <div className="box-ports">
                                             <img data-src="images/portfolio/branding/Stationary05.jpg" alt=""/>
                                             <div className="hovereffects-det">
                                                <h3>Karaken</h3>
                                                <div className="btnstyles-nn">
                                                   <a data-fancybox="branding" href="images/portfolio/branding/Stationary05.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="col-md-3">
                                          <div className="box-ports">
                                             <img data-src="images/portfolio/branding/Stationary06.jpg" alt=""/>
                                             <div className="hovereffects-det">
                                                <h3>Manish Khatri</h3>
                                                <div className="btnstyles-nn">
                                                   <a data-fancybox="branding" href="images/portfolio/branding/Stationary06.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="col-md-3">
                                          <div className="box-ports">
                                             <img data-src="images/portfolio/branding/Stationary07.jpg" alt=""/>
                                             <div className="hovereffects-det">
                                                <h3>One eighty</h3>
                                                <div className="btnstyles-nn">
                                                   <a data-fancybox="branding" href="images/portfolio/branding/Stationary07.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="col-md-3">
                                          <div className="box-ports">
                                             <img data-src="images/portfolio/branding/Stationary08.jpg" alt=""/>
                                             <div className="hovereffects-det">
                                                <h3>MFR</h3>
                                                <div className="btnstyles-nn">
                                                   <a data-fancybox="branding" href="images/portfolio/branding/Stationary08.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="col-md-3">
                                          <div className="box-ports">
                                             <img data-src="images/portfolio/branding/Stationary09.jpg" alt=""/>
                                             <div className="hovereffects-det">
                                                <h3>Pedikidz</h3>
                                                <div className="btnstyles-nn">
                                                   <a data-fancybox="branding" href="images/portfolio/branding/Stationary09.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="col-md-3">
                                          <div className="box-ports">
                                             <img data-src="images/portfolio/branding/Stationary10.jpg" alt=""/>
                                             <div className="hovereffects-det">
                                                <h3>MedStar</h3>
                                                <div className="btnstyles-nn">
                                                   <a data-fancybox="branding" href="images/portfolio/branding/Stationary10.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           { currentPath!=="/portfolio" &&<div className="container leftcont-ban">
                           <a onClick={(ref, id) =>
                                scrollTo(
                                  (ref = "/portfolio"),
                                  (id = "top")
                                )
                              } class="montfont"  title="">View All Projects <i class="sprite btnarow"></i></a>
                           </div>}
                        </div>
                     </div>
                     <div id="packaginngport" className="tabs-portfolio innertabcontents">
                        <div className="portfolio-mmbx">
                           <div id="innerall2" className="innertabcontents active">
                              <div className="row">
                                 <div className="col-md-12">
                                    <div className="row responsive-slider">
                                       <div className="col-md-3">
                                          <div className="box-ports">
                                             <img data-src="images/portfolio/packging/1.jpg" alt="" src="images/portfolio/packging/1.jpg" className="imgadded" />
                                             <div className="hovereffects-det">
                                                <h3>Pride Junior’s Club</h3>
                                                <div className="btnstyles-nn">
                                                   <a data-fancybox="brandPackging" href="images/portfolio/packging/1.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="col-md-3">
                                          <div className="box-ports">
                                             <img data-src="images/portfolio/packging/2.jpg" alt="" src="images/portfolio/packging/2.jpg" className="imgadded" />
                                             <div className="hovereffects-det">
                                                <h3>Meritova</h3>
                                                <div className="btnstyles-nn">
                                                   <a data-fancybox="brandPackging" href="images/portfolio/packging/2.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="col-md-3">
                                          <div className="box-ports">
                                             <img data-src="images/portfolio/packging/3.jpg" alt="" src="images/portfolio/packging/3.jpg" className="imgadded" />
                                             <div className="hovereffects-det">
                                                <h3>Xpressions Salon</h3>
                                                <div className="btnstyles-nn">
                                                   <a data-fancybox="brandPackging" href="images/portfolio/packging/3.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="col-md-3">
                                          <div className="box-ports">
                                             <img data-src="images/portfolio/packging/4.jpg" alt="" src="images/portfolio/packging/4.jpg" className="imgadded" />np
                                             <div className="hovereffects-det">
                                                <h3>Sugarie</h3>
                                                <div className="btnstyles-nn">
                                                   <a data-fancybox="brandPackging" href="images/portfolio/packging/4.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="col-md-3">
                                          <div className="box-ports">
                                             <img data-src="images/portfolio/packging/5.jpg" alt="" src="images/portfolio/packging/5.jpg" className="imgadded" />
                                             <div className="hovereffects-det">
                                                <h3>Modern Bakers</h3>
                                                <div className="btnstyles-nn">
                                                   <a data-fancybox="brandPackging" href="images/portfolio/packging/5.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="col-md-3">
                                          <div className="box-ports">
                                             <img data-src="images/portfolio/packging/6.jpg" alt="" src="images/portfolio/packging/6.jpg" className="imgadded"/>
                                             <div className="hovereffects-det">
                                                <h3>Beauty Inn</h3>
                                                <div className="btnstyles-nn">
                                                   <a data-fancybox="brandPackging" href="images/portfolio/packging/6.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="col-md-3">
                                          <div className="box-ports">
                                             <img data-src="images/portfolio/packging/7.jpg" alt="" src="images/portfolio/packging/7.jpg" className="imgadded" />
                                             <div className="hovereffects-det">
                                                <h3>Zertifye</h3>
                                                <div className="btnstyles-nn">
                                                   <a data-fancybox="brandPackging" href="images/portfolio/packging/7.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div className="container leftcont-ban fixportfoliobtns">
                              <a className="montfont" href="#" title="">View Complete Portfolio <i className="sprite btnarow"></i></a>
                           </div>
                        </div>
                     </div>
                     <div id="broucherport" className="tabs-portfolio innertabcontents">
                        <div className="portfolio-mmbx">
                           <div id="innerall2" className="innertabcontents active">
                              <div className="row">
                                 <div className="col-md-12">
                                    <div className="row responsive-slider">
                                       <div className="col-md-3">
                                          <div className="box-ports">
                                             <img data-src="images/portfolio/brochure/01/Brochure.jpg" alt=""/>
                                             <div className="hovereffects-det">
                                                <h3>World of Difference</h3>
                                                <div className="btnstyles-nn">
                                                   <a data-fancybox="br01" href="images/portfolio/brochure/01/Brochure-01.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                                   <a className="bd-none" data-fancybox="br01" href="images/portfolio/brochure/01/Brochure-02.jpg" title=""></a>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="col-md-3">
                                          <div className="box-ports">
                                             <img data-src="images/portfolio/brochure/02/FrontBack.jpg" alt=""/>
                                             <div className="hovereffects-det">
                                                <h3>Mansoura News</h3>
                                                <div className="btnstyles-nn">
                                                   <a data-fancybox="br02" href="images/portfolio/brochure/02/FrontBack.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                                   <a className="bd-none" data-fancybox="br02" href="images/portfolio/brochure/02/Inners.jpg" title=""></a>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="col-md-3">
                                          <div className="box-ports">
                                             <img data-src="images/portfolio/brochure/03/TRIFOLD-03.jpg" alt=""/>
                                             <div className="hovereffects-det">
                                                <h3>Jeffrey Leiken</h3>
                                                <div className="btnstyles-nn">
                                                   <a data-fancybox="br03" href="images/portfolio/brochure/03/TRIFOLD-01.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                                   <a className="bd-none" data-fancybox="br03" href="images/portfolio/brochure/03/TRIFOLD-02.jpg" title=""></a>
                                                   <a className="bd-none" data-fancybox="br03" href="images/portfolio/brochure/03/TRIFOLD-03.jpg" title=""></a>
                                                   <a className="bd-none" data-fancybox="br03" href="images/portfolio/brochure/03/TRIFOLD-04.jpg" title=""></a>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="col-md-3">
                                          <div className="box-ports">
                                             <img data-src="images/portfolio/brochure/04/Bifold.jpg" alt=""/>
                                             <div className="hovereffects-det">
                                                <h3>Right Choice</h3>
                                                <div className="btnstyles-nn">
                                                   <a data-fancybox="br04" href="images/portfolio/brochure/04/Brochure-02.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                                   <a className="bd-none" data-fancybox="br04" href="images/portfolio/brochure/04/Bifold.jpg" title=""></a>
                                                   <a className="bd-none" data-fancybox="br04" href="images/portfolio/brochure/04/Brochure-01.jpg" title=""></a>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="col-md-3">
                                          <div className="box-ports">
                                             <img data-src="images/portfolio/brochure/05/Brochure-01.jpg" alt=""/>
                                             <div className="hovereffects-det">
                                                <h3>Confia</h3>
                                                <div className="btnstyles-nn">
                                                   <a data-fancybox="br05" href="images/portfolio/brochure/05/Brochure-01.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                                   <a className="bd-none" data-fancybox="br05" href="images/portfolio/brochure/05/Brochure-02.jpg" title=""></a>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="col-md-3">
                                          <div className="box-ports">
                                             <img data-src="images/portfolio/brochure/06/Trifold.jpg" alt=""/>
                                             <div className="hovereffects-det">
                                                <h3>All About Salads</h3>
                                                <div className="btnstyles-nn">
                                                   <a data-fancybox="br06" href="images/portfolio/brochure/06/Trifold.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                                   <a className="bd-none" data-fancybox="br06" href="images/portfolio/brochure/06/Brochure-01.jpg" title=""></a>
                                                   <a className="bd-none" data-fancybox="br06" href="images/portfolio/brochure/06/Brochure-02.jpg" title=""></a>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="col-md-3">
                                          <div className="box-ports">
                                             <img data-src="images/portfolio/brochure/07/01.jpg" alt=""/>
                                             <div className="hovereffects-det">
                                                <h3>TIF TIF</h3>
                                                <div className="btnstyles-nn">
                                                   <a data-fancybox="br07" href="images/portfolio/brochure/07/01.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                                   <a className="bd-none" data-fancybox="br07" href="images/portfolio/brochure/07/02.jpg" title=""></a>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="col-md-3">
                                          <div className="box-ports">
                                             <img data-src="images/portfolio/brochure/08/Brochure-01.jpg" alt=""/>
                                             <div className="hovereffects-det">
                                                <h3>Snowday Marketing</h3>
                                                <div className="btnstyles-nn">
                                                   <a data-fancybox="br08" href="images/portfolio/brochure/08/Brochure-01.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                                   <a className="bd-none" data-fancybox="br08" href="images/portfolio/brochure/08/Brochure-02.jpg" title=""></a>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="col-md-3">
                                          <div className="box-ports">
                                             <img data-src="images/portfolio/brochure/09/Bifold-01.jpg" alt=""/>
                                             <div className="hovereffects-det">
                                                <h3>The Whet Spot</h3>
                                                <div className="btnstyles-nn">
                                                   <a data-fancybox="br09" href="images/portfolio/brochure/09/Bifold-01.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                                   <a className="bd-none" data-fancybox="br09" href="images/portfolio/brochure/09/Bifold-02.jpg" title=""></a>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="col-md-3">
                                          <div className="box-ports">
                                             <img data-src="images/portfolio/brochure/10/Trifold.jpg" alt=""/>
                                             <div className="hovereffects-det">
                                                <h3>Cretic</h3>
                                                <div className="btnstyles-nn">
                                                   <a data-fancybox="br10" href="images/portfolio/brochure/10/Trifold.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                                   <a className="bd-none" data-fancybox="br10" href="images/portfolio/brochure/10/Trifold-01.jpg" title=""></a>
                                                   <a className="bd-none" data-fancybox="br10" href="images/portfolio/brochure/10/Trifold-02.jpg" title=""></a>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div className="container leftcont-ban fixportfoliobtns">
                              <a className="montfont" href="#" title="">View Complete Portfolio <i className="sprite btnarow"></i></a>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div id="tab6" className="tabs-portfolio innertabcontents tab-pane fade in">
                  <div className="portfolio-mmbx">
                     <div className="row responsive-slider">
                        <div className="col-md-3">
                           <div className="box-ports">
                              <img data-src="images/portfolio/illustration/01.jpg" alt=""/>
                              <div className="hovereffects-det">
                                 <h3>Royal Wash</h3>
                                 <div className="btnstyles-nn">
                                    <a data-fancybox="logos" href="images/portfolio/illustration/01.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="col-md-3">
                           <div className="box-ports">
                              <img data-src="images/portfolio/illustration/02.jpg" alt=""/>
                              <div className="hovereffects-det">
                                 <h3>Tanner Lawly art group</h3>
                                 <div className="btnstyles-nn">
                                    <a data-fancybox="logos" href="images/portfolio/illustration/02.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="col-md-3">
                           <div className="box-ports">
                              <img data-src="images/portfolio/illustration/03.jpg" alt=""/>
                              <div className="hovereffects-det">
                                 <h3>poco loco</h3>
                                 <div className="btnstyles-nn">
                                    <a data-fancybox="logos" href="images/portfolio/illustration/03.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="col-md-3">
                           <div className="box-ports">
                              <img data-src="images/portfolio/illustration/04.jpg" alt=""/>
                              <div className="hovereffects-det">
                                 <h3>chase fitness</h3>
                                 <div className="btnstyles-nn">
                                    <a data-fancybox="logos" href="images/portfolio/illustration/04.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="col-md-3">
                           <div className="box-ports">
                              <img data-src="images/portfolio/illustration/05.jpg" alt=""/>
                              <div className="hovereffects-det">
                                 <h3>oakworth fried chicken</h3>
                                 <div className="btnstyles-nn">
                                    <a data-fancybox="logos" href="images/portfolio/illustration/05.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="col-md-3">
                           <div className="box-ports">
                              <img data-src="images/portfolio/illustration/06.jpg" alt=""/>
                              <div className="hovereffects-det">
                                 <h3>doggy day care</h3>
                                 <div className="btnstyles-nn">
                                    <a data-fancybox="logos" href="images/portfolio/illustration/06.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="col-md-3">
                           <div className="box-ports">
                              <img data-src="images/portfolio/illustration/07.jpg" alt=""/>
                              <div className="hovereffects-det">
                                 <h3>ravelo travel and cruise</h3>
                                 <div className="btnstyles-nn">
                                    <a data-fancybox="logos" href="images/portfolio/illustration/07.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="col-md-3">
                           <div className="box-ports">
                              <img data-src="images/portfolio/illustration/08.jpg" alt=""/>
                              <div className="hovereffects-det">
                                 <h3>lord and smith travel</h3>
                                 <div className="btnstyles-nn">
                                    <a data-fancybox="logos" href="images/portfolio/illustration/08.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     { currentPath!=="/portfolio" &&<div className="container leftcont-ban">
                        <a className="montfont" onClick={(ref, id) =>
                                scrollTo(
                                  (ref = "/portfolio"),
                                  (id = "top")
                                )
                              } title="">View All Projects <i className="sprite btnarow"></i></a>
                     </div>}
                  </div>
               </div>
            </div>
         </div>
      </section>
    </> 
)
}
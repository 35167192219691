import React from 'react'
import Banner_about from "../Sections/banner_about"
import Site_about from "../Sections/Site_about"
import Portfolio from '../Sections/Portfolio'
import Ideology_section from '../Sections/Ideology_section'
// import Testimonail_Section from './section/Testimonail_Section'
import Footer_site from '../Sections/Footer_site'

export const About = () => {
  return (
    <>
    <Banner_about/>
    <Site_about/>
    <Portfolio/>
    {/* <Testimonail_Section/> */}
    <Ideology_section/>
    </>
  )
}

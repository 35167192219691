import React from 'react'

export default function Terms() {
  return (
    <section class="main-privacy">
    <div class="container">
        <div class="row">
            <div class="col-md-12 col-sm-12 col-xs-12 privacy">
                <div class="text-center terms">
                    <h4>Terms &amp; Conditions</h4>
                    <p>The following terms and conditions apply to projects undertaken by
                        The Logos Designers</p>
                    
                </div>
                <div class="term-text">
                    <h3>Refund Policy</h3>
                    <p>Refund policy will be voided if,</p>
                    <p>
                        <b>1.</b> A discounted package has been chosen.<br/>
                        <b>2.</b> The primary design concept has been approved.<br/>
                        <b>3.</b> You have demanded revisions.<br/>
                        <b>4.</b> Urgent design projects are non-refundable.<br/>
                        <b>5.</b> The cancellation has been made due to reasons non-related to the company.<br/>
                        <b>6.</b> The client is unresponsive or out of contact for more than 2 weeks of project.<br/>
                        <b>7.</b> Company’s policies, or policy, have been violated.<br/>
                        <b>8.</b> Other company or designer has been approached for the same project.<br/>
                        <b>9.</b> The creative brief is lacking in required information.<br/>
                        <b>10.</b> A complete design change has been demanded.<br/>
                        <b>11.</b> The claim has crossed the given ‘request for refund’ time span.<br/>
                        <b>12.</b> The business is closing or changing name or business.<br/>
                        <b>13.</b> Reasons such as ‘change of mind’, ‘disagreement with partner’ or other reasons that
                        do not pertain to the service will not be subject to refund under any circumstances.<br/>
                        <b>14.</b> If a client subscribes for a service bundle and happens to be dissatisfied with a
                        particular service, refund will only be applicable on that particular service and ‘not’ the
                        entire bundle.<br/>
                        <b>15.</b> No refund can be claimed/processed once after the final files have been delivered to
                        you following your request.<br/>
                        <b>16.</b> Refund and revision explicitly does not apply on items offered free.<br/>
                        <b>17.</b> Services including but not limited to Social Media, SEO, Domain Registration and Web
                        Hosting are not entitled to refund under any circumstances.<br/>
                        <b>18.</b> In case of websites, refunds will not be entertained once the client has approved the
                        design and the website is sent for development.<br/>
                        <b>19.</b> In case of logo, refunds will not be entertained once client has shared positive
                        feedback on revisions and design is sent for final files.<br/>
                        <b>20.</b> Refund will not be applicable on the work outsourced by third party.<br/>
                    </p>
                    <h3>Terms of Use</h3>
                    <ul class="__web-inspector-hide-shortcut__">
                        <li> Terms of Use</li>
                        <li> Site Contents Usage</li>
                        <li> Testimonials</li>
                        <li> Electronic Communications</li>
                        <li> Copyright/Trademarkss</li>
                        <li> Copyright Complaints</li>
                        <li> Refund Policy</li>
                        <li> Account Area</li>
                        <li> Delivery Policy</li>
                        <li> Revision Policy</li>
                        <li> Your Account</li>
                        <li> Notice and Procedure for Making Claims of Copyright Violation</li>
                        <li> Applicable Law</li>
                        <li> Disputes</li>
                        <li> Site Policies, Modification &amp; Severability</li>
                        <li> Bill of Rights</li>
                        <li>After the finalization of the logo it needs to go through a registration process that will be
                        done through the agency, The Logos Designers (USPTO charges applied), in order to have complete
                        ownership and 100% rights over the logo.</li>
                    </ul>
                    <h3>Site Contents Usage</h3>
                    <p>The choice of visiting our Homepage is solely your decision. Any dispute over privacy is subject
                        to this notice and our Conditions of Use. This includes limitations on damages, arbitration of
                        disputes, and the applicable law of state. If the client has any issues regarding the Privacy
                        Policy, they should contact us at their best convenience. Our customer services shall be most
                        happy to serve the clients.</p>
                    <p>As our Policies are liable to change at any time, it is recommended that the client go through
                        the policy each time they enter the site to purchase.</p>
                    <p>The company gathers navigational information about where visitors go on our website and
                        information about the technical efficiencies of our website and service (i.e. time to connect
                        and time to download pages). This information allows us to see which areas of our Company’s
                        Website are most visited and helps us understand our clients better than before. This also helps
                        us improve the quality of those sites which are lacking in some way or the other. Unless
                        otherwise noted, all materials, including images, illustrations, designs, icons, photographs,
                        written and other materials that are part of this Site
                        (collectively, the “Contents”) are copyrights, trademarks, trade dress and/or other intellectual
                        property owned, controlled or licensed by the Company.</p>
                    <p>This Site and all its Contents are intended solely for personal and non-commercial use. The
                        client may download or copy the Contents and other downloadable materials displayed on the Site
                        for their personal use only. No right, title or interest in any downloaded materials or software
                        is transferred to the client as a result of any such downloading or copying. The client may not
                        reproduce (except as noted above), publish, transmit, distribute, display, modify, create
                        derivatives, sell or participate in any sale or exploitation of the site, its content, or any
                        related software.</p>
                    <h3>Testimonials</h3>
                    <p>All comments, feedbacks, postcards, suggestions, ideas, and other submissions disclosed,
                        submitted or offered to Our Company on or by this Site or otherwise disclosed, submitted or
                        offered in connection with your use of this Site (collectively, the “Comments”) shall remain the
                        Company’s property.</p>
                    <p>The Company will own exclusive rights, titles and interests and shall not be limited in any way
                        in its use, commercial or otherwise, with reference to the Comments. The Company is and shall be
                        under no obligation:</p>
                    <ul style={{marginLeft: '26px'}}>
                        <li>(a.) To maintain any Comments in confidence;<br/></li>
                        <li>(b.) To pay to user any compensation for any Comments; or<br/></li>
                        <li> (c.) To respond to any user Comments.</li>
                       
                    </ul>
                    <p>The company can also use the testimonials and logos of the clients on its other web and offline
                        properties.</p>
                    <h3>Electronic Communications</h3>
                    <p>The moment the client visits our site or sends e-mails to our contacts, the client is
                        communicating with our company electronically. It’s involuntary for the client to receive our
                        communications electronically. Our Company will communicate with the client by e-mail or by
                        posting notices on this site. Thus the client agrees that all agreements, notices, disclosures
                        and relevant communication satisfy and fulfill all legal requirements and are equivalent to any
                        legal statement in writing.</p>
                    <h3>Copyright/Trademarks</h3>
                    <p>All content that is included on this site, such as text, graphics, logos, button icons, images,
                        digital downloads, data compilations, and software, is the property of this company or its
                        content suppliers and protected and international copyright laws. The compilation of all content
                        at this site is the exclusive property of this company and protected by international copyright
                        laws. All software used at this site is the property of this company or its software suppliers
                        and protected and international copyright laws.</p>
                    <p>The trademark names used within our sites are the property of their respective company or its
                        subsidiaries and cannot be used in connection with any product or service that is not part of
                        that company.</p>

                    {/* <!-- <p>*Unlimited revisions require that the initial design and concept have to be the same, customer cannot ask for a redesign once an initial concept has been selected.</p> --> */}

                    <p><b>Note: </b>We have all rights to hold or reject any project or cancel the contract whenever it
                        deems necessary. After the refund/Dispute, you will not have any rights to use the designs for
                        any purpose, they will be our sole property. The company will be the right full owner of
                        designs.</p>
                    <h3>Your Account</h3>
                    <p>The use of this site is solely the responsibility of the user, for maintaining the
                        confidentiality of their account and password and for restricting access to their computer, and
                        as the client agrees to accept responsibility for all activities that occur under your account
                        or password..</p>
                    <h3>Copyright Complaints</h3>
                    <p>The Company and its affiliates respect the intellectual property of others. If anyone browsing
                        the site believes that their work has been copied in a way that constitutes copyright
                        infringement, please follow our Notice and Procedure for Making Claims of Copyright Violation.
                    </p>
                    <h3>Account Area</h3>
                    <p>The Account Area is a convenient way to communicate. It is your sole responsibility to check the
                        account area to address any queries, concerns, or additional instructions required by the
                        designer. Not checking or using the Account Area frequently shall not provide you adequate
                        grounds for a refund. However, if you are uncertain how to use the area, you may contact the
                        customer support team at any time for assistance.</p>
                    <h3>Delivery Policy</h3>
                    <p>All design order files are delivered to Account Area as per the date specified on the Order
                        Confirmation Email. An e-mail is also sent to inform the client about their design order
                        delivery made to their specific account area. All policies pertaining to revision &amp; refund are
                        subject to date and time of design order delivered to client’s account area.</p>
                    <p>We provide a design order reference number soon after the order is placed, so that the customer
                        may track the status of that order and post revisions against the reference number.</p>
                    <p>We deliver all our customized design orders via e-mail within 2 to 3 days of receiving your
                        order.</p>
                    <p>We offer a RUSH DELIVERY service through which you can have your first logo samples within 24
                        hours by paying just $100 extra! For further assistance, contact us at 24-Hour Customer Support
                        Center.</p>
                    <h3>Revision Policy</h3>
                    <p>We provide guaranteed unlimited revision. Customers can ask us for unlimited free revisions and
                        we will revise their design without any additional charges provided that the design and concept
                        remains the same. Revision Turnaround Time would be 48 hours</p>
                    <h3>Notice and Procedure for Making Claims of Copyright Violation</h3>
                    <p>The Company and its affiliates respect the intellectual property of others. If anyone browsing
                        the site believes that their work has been copied in a way that result in copyright violation,
                        please provide the Company the copyright agent, and the written information specified below:</p>
                    <ul>
                        <li> An electronic or physical signature of the person authorized to act on behalf of the owner
                            of the copyright interest.</li>
                        <li> A description of the copyrighted work that the person claims has been violated.</li>
                        <li> A description of where the material that the person claims is copied from is located on the
                            site, including any other ID number, if applicable.</li>
                        <li> The person’s address, telephone number, and e-mail address.</li>
                        <li> A statement by the person that s/he has a good-faith belief that the disputed use is not
                            authorized by the copyright owner, its agent, or the law;</li>
                        <li> A statement by the person, made under penalty of perjury, that the above information in the
                            person’ notice is accurate and that the person is the copyright owner or authorized to act
                            on the copyright owner’s behalf.</li>
                    </ul>
                    <h3>Applicable Law</h3>
                    <p>You are required to comply with all applicable laws (including, without limitation, any
                        applicable export controls) in connection with your use of the Service, and such further
                        limitations as may be set forth in any written or on-screen notice from Logomines.</p>
                    <h3>Disputes</h3>
                    <p>If there is any dispute relating in any way to the client’s visit to company’s Website or to
                        services that they purchase through the site, the disputes shall be submitted to Dispute
                        Handling Department.</p>
                    <h3>Site Policies, Modification &amp; Severability</h3>
                    <p>We would appreciate the visitor to review all other policies, such as the Pricing policy, posted
                        at this site. These policies also govern the client’s visit to our site. The Company reserves
                        the right to make changes within the site, policies, and the Conditions of Use at any time. If
                        any of these conditions are deemed invalid, void, or for any reason unenforceable, it shall be
                        deemed severable and shall not affect the validity and enforceability of any remaining
                        condition.</p>
                    <p>Means of Reaching the Company:</p>
                    <p>The path way to all communications, starting from the purchase to the delivery process to the
                        completion of order is done in any of the following methods:</p>
                    <ul>
                        <li>(1) Chat</li>
                        <li>(2) E-mail</li>
                        <li>(3) Customer Service Call Center:</li>
                        <li>(4) Fax</li>
                    </ul>
                    <p>Other than these methods of communication, none are valid.</p>
                    <h3>Bill of Rights</h3>
                    <p><strong>Safe Shopping:</strong><br/>
                        When a client shops at this site, they shall be one of numerous customers who have safely
                        shopped with us without credit card fraud. If the customer feels more comfortable, they may
                        place an order by phone.</p>
                    <p><strong>No Obligation:</strong><br/>
                        Our Personal Notification Services, such as E-mail, are provided free of charge, and the client
                        is under no obligation to buy anything.</p>
                    <p> <strong>Updates:</strong><br/>
                        As a customer, the person will occasionally receive e-mail updates about important functionality
                        changes to the Web site, new services, and special offers that our Client Services think the
                        client might find valuable. But if the clients do not receive them, please visit the My Account
                        section at our site to change preferences by logging in.</p>
                    <p><strong>Note:</strong><br/>Use of the stolen credit cards is strictly prohibited and is considered
                        to be a serious crime. We work in close collaboration to fight cybercrime and make sure that all
                        fraudulent orders are reported to the Federal and State Agencies.</p>


                </div>
            </div>
        </div>
    </div>
</section>
  )
}

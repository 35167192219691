import React from 'react'
import Website_development from '../Sections/Website_development'
import Testimonail_Section from '../Sections/Testimonail_Section'


export const Website = () => {
    return (
      <>
        <Website_development/>
        <Testimonail_Section/>
      </>
    )
}
import React from 'react'
import Banner_contact from '../Sections/Banner_contact'
import Contact_form from '../Sections/Contact_form'
import Portfolio from '../Sections/Portfolio'

export const Contact_main = () => {
    return (
      <>
        <Banner_contact/>
        <Contact_form/>
        <Portfolio/>
      </>
    )
}

import { useLocation, useNavigate } from "react-router-dom";
import { scroller } from "react-scroll";
import Modal from "./Modal2";
import React, { useState } from "react";
import TawkContext from "../TawkContext";
import { useContext } from "react";

export default function Pricing_cards() {
  const navigate = useNavigate();
  const [active, setActive] = useState(false);
  const scrollTo = (ref, id) => {
    navigate(ref);
    setTimeout(() => {
      scroller.scrollTo(id, {
        spy: true,
        smooth: true,
        offset: 0,
        duration: 500,
      });
    }, 100);
  };
  const location = useLocation();
  const currentPath = location.pathname;

  const tawkRef = useContext(TawkContext);

  // You can access the TawkMessengerReact ref through tawkRef.current

  // Example usage
  const handleClick = () => {
    
      tawkRef.current.maximize()
    
  };

  return (
    <>
    <Modal active={active} setActive={setActive} />
    
      <section class="det-packages" id="pricing">
        <div class="container">
          <div class="sechead">
            <h3 class="montfont">
              Affordable Packages Crafted As Per The Needs Of Your Business
            </h3>
            <p>
              The Logos Designers helps you with design solutions, branding
              solutions, social media solutions and a whole host of creative
              design ideas.
            </p>
          </div>
          <div class="packages-wrapper">
            <ul class="nav nav-tabs">
              <li class="active">
                <a data-toggle="tab" href="#pack0">
                  Logo
                </a>
              </li>
              <li class="">
                <a data-toggle="tab" href="#pack1">
                  SEO
                </a>
              </li>
              <li class="">
                <a data-toggle="tab" href="#pack2">
                  Animation
                </a>
              </li>
              <li class="">
                <a data-toggle="tab" href="#pack3">
                  Print
                </a>
              </li>
              <li class="">
                <a data-toggle="tab" href="#pack4">
                  Website
                </a>
              </li>
              <li class="">
                <a data-toggle="tab" href="#pack5">
                  Branding
                </a>
              </li>
              <li class="">
                <a data-toggle="tab" href="#pack6">
                  Web Portal
                </a>
              </li>
              <li class="">
                <a data-toggle="tab" href="#pack7">
                  SMM
                </a>
              </li>
              <li class="">
                <a data-toggle="tab" href="#pack8">
                  Ecommerce Website
                </a>
              </li>
            </ul>

            <div class="tab-content">
              <div id="pack0" class="fade tab-pane active in">
                <div class="row responsive-slider">
                  <div class="col-lg-3">
                    <div class="boxpackages" data-package-box>
                      <div class="packheads">
                        <h3 class="montfont">Logo Special</h3>
                        <p style={{ display: "none" }}>
                          Ideal for beginners and start-ups
                        </p>
                      </div>
                      <div class="packdetails">
                        <div class="packtitles">
                          <div class="fleft">
                            <h3 class="opensansfont">
                              $21 <sub class="">60% off</sub>
                            </h3>
                          </div>
                          <div class="fright">
                            <h4>
                              WAS
                              <b>
                                <span class="cutprice">$196.7</span>
                              </b>
                            </h4>
                          </div>
                        </div>
                        <div class="scroll listpacks" data-package-scroll>
                          <ul>
                            <li>4 Original Logo Concepts</li>
                            <li>2 Dedicated Logo Designer</li>
                            <li>4 Revisions</li>
                            <li>With Grey Scale Format</li>
                            <li>Free Icon Design</li>
                            <li>Formats: JPEG Only</li>
                            <li>24 - 48 Hours Turn Around Time</li>
                            <span>MORE FEATURES</span>
                            <li>100% Satisfaction</li>
                            <li>100% Ownership Rights</li>
                            <li>Money Back Guarantee</li>
                            <li>Dedicated Account Manager</li>
                          </ul>{" "}
                        </div>
                        <div class="botarea-pack">
                          <div class="fleft">
                            <a
                              class="montfont btn-orders order-package"
                              onClick={() => setActive(true)}
                              data-sku="LOGO_SPECIAL"
                              data-promotion-id="0"
                              data-price="59"
                              data-price-text="$21.00"
                              data-title="Logo Special"
                              data-package-id="2778"
                            >
                              Order Now
                            </a>
                          </div>
                          <div class="fright">
                            <div class="liovechats-bx">
                              <a
                                href="javascript:;"
                                title=""
                                  onClick={handleClick}
                              >
                                <span>Click here to</span>
                                <h5 class="montfont"   onClick={handleClick}>
                                  Live Chat
                                </h5>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                  <div class="col-lg-3">
                    <div class="boxpackages" data-package-box>
                      <div class="packheads">
                        <h3 class="montfont">Logo Plus</h3>
                        <p style={{ display: "none" }}>
                          Ideal for beginners and start-ups
                        </p>
                      </div>
                      <div class="packdetails">
                        <div class="packtitles">
                          <div class="fleft">
                            <h3 class="opensansfont">
                              $199<sub class="">60% off</sub>
                            </h3>
                          </div>
                          <div class="fright">
                            <h4>
                              WAS
                              <b>
                                <span class="cutprice">$663.3</span>
                              </b>
                            </h4>
                          </div>
                        </div>
                        <div class="scroll listpacks" data-package-scroll>
                          <ul class="overflow">
                            <li>12 Original Logo Concepts</li>
                            <li>
                              4 Dedicated Logo Designer (Industry Specific)
                            </li>
                            <li>Unlimited Revisions</li>
                            <li>
                              Stationery Design (Business Card, Letterhead,
                              Envelope)
                            </li>
                            <li>Email Signature Design</li>
                            <li>With Grey Scale Format</li>
                            <li>Free Icon Design</li>
                            <li>Formats: JPEG, PSD, EPS, AI, PNG, TIFF, SVG</li>
                            <li>24 - 48 Hours Turn Around Time</li>

                            <li>
                              <span>MORE FEATURES</span>
                            </li>
                            <li>100% Satisfaction</li>
                            <li>100% Ownership Rights</li>
                            <li>Money Back Guarantee</li>
                            <li>Dedicated Account Manager</li>
                            <li>
                              24/7 Support (Email, Chat, Call, SMS, Whatsapp)
                            </li>
                          </ul>{" "}
                        </div>
                        <div class="botarea-pack">
                          <div class="fleft">
                            <a
                              class="montfont btn-orders order-package"
                              onClick={() => setActive(true)}
                              data-sku="LOGO_LOGO_PLUS"
                              data-promotion-id="0"
                              data-price="199"
                              data-price-text="$199.00"
                              data-title="Logo Plus"
                              data-package-id="2779"
                            >
                              Order Now
                            </a>
                          </div>
                          <div class="fright">
                            <div class="liovechats-bx">
                              <a
                                href="javascript:;"
                                title=""
                                  onClick={handleClick}
                              >
                                <span>Click here to</span>
                                <h5 class="montfont"   onClick={handleClick}>
                                  Live Chat
                                </h5>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                  <div class="col-lg-3">
                    <div class="boxpackages" data-package-box>
                      <div class="packheads">
                        <h3 class="montfont">Logo Platinum</h3>
                        <p style={{ display: "none" }}>
                          Ideal for beginners and start-ups
                        </p>
                      </div>
                      <div class="packdetails">
                        <div class="packtitles">
                          <div class="fleft">
                            <h3 class="opensansfont">
                              $399 <sub class="">60% off</sub>
                            </h3>
                          </div>
                          <div class="fright">
                            <h4>
                              WAS
                              <b>
                                <span class="cutprice">$1330</span>
                              </b>
                            </h4>
                          </div>
                        </div>
                        <div class="scroll listpacks" data-package-scroll>
                          <ul>
                            <li>Unlimited Original Logo Concepts</li>
                            <li>
                              8 Dedicated Logo Designer (Industry Specific)
                            </li>
                            <li>Unlimited Revisions</li>
                            <li>
                              Stationery Design (Business Card, Letterhead,
                              Envelope)
                            </li>
                            <li> 5 pages basic website</li>
                            <li>Email Signature Design</li>
                            <li>With Grey Scale Format</li>
                            <li>Free Icon Design</li>
                            <li>Formats: JPEG, PSD, EPS, AI, PNG, TIFF, SVG</li>
                            <li>24 - 48 Hours Turn Around Time</li>

                            <li>
                              <span>MORE FEATURES</span>
                            </li>
                            <li>100% Satisfaction</li>
                            <li>100% Ownership Rights</li>
                            <li>Money Back Guarantee</li>
                            <li>Dedicated Account Manager</li>
                          </ul>{" "}
                        </div>
                        <div class="botarea-pack">
                          <div class="fleft">
                            <a
                              class="montfont btn-orders order-package"
                              onClick={() => setActive(true)}
                              data-sku="LOGO_LOGO_PLATINUM"
                              data-promotion-id="0"
                              data-price="399"
                              data-price-text="$399.00"
                              data-title="Logo Platinum"
                              data-package-id="2775"
                            >
                              Order Now
                            </a>
                          </div>
                          <div class="fright">
                            <div class="liovechats-bx">
                              <a
                                href="javascript:;"
                                title=""
                                  onClick={handleClick}
                              >
                                <span>Click here to</span>
                                <h5 class="montfont"   onClick={handleClick}>
                                  Live Chat
                                </h5>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                  <div class="col-lg-3">
                    <div class="boxpackages" data-package-box>
                      <div class="packheads">
                        <h3 class="montfont">Logo Infinite</h3>
                        <p style={{ display: "none" }}>
                          Ideal for beginners and start-ups
                        </p>
                      </div>
                      <div class="packdetails">
                        <div class="packtitles">
                          <div class="fleft">
                            <h3 class="opensansfont">
                              $999 <sub class="">60% off</sub>
                            </h3>
                          </div>
                          <div class="fright">
                            <h4>
                              WAS
                              <b>
                                <span class="cutprice">$3330</span>
                              </b>
                            </h4>
                          </div>
                        </div>
                        <div class="scroll listpacks" data-package-scroll>
                          <ul>
                            <li>Unlimited Original Logo Concepts </li>
                            <li>
                              12 Dedicated Logo Designer (Industry Specific){" "}
                            </li>
                            <li>Unlimited Revisions </li>
                            <li>5 Page Informative Website </li>
                            <li>
                              Stationery Design (Business Card, Letterhead,
                              Envelope){" "}
                            </li>
                            <li>Brand Book</li>
                            <li> 5 pages basic website</li>
                            <li>Email Signature Design</li>
                            <li>
                              Social Media Designs (Facebook, Twitter,
                              Instagram)
                            </li>
                            <li>2 Sided Flyer OR Bi-Fold Brochure Design</li>
                            <li>With Grey Scale Format </li>
                            <li>Free Icon Design </li>
                            <li>Formats: JPEG, PSD, EPS, AI, PNG, TIFF, SVG</li>
                            <li>24 - 48 Hours Turn Around Time</li>
                            <li>
                              <span>MORE FEATURES</span>
                            </li>
                            <li>100% Satisfaction </li>
                            <li>100% Ownership Rights </li>
                            <li>Money Back Guarantee </li>
                            <li>Dedicated Account Manager </li>
                          </ul>{" "}
                        </div>
                        <div class="botarea-pack">
                          <div class="fleft">
                            <a
                              class="montfont btn-orders order-package"
                              onClick={() => setActive(true)}
                              data-sku="LOGO_INFINITE_LOGO"
                              data-promotion-id="0"
                              data-price="999"
                              data-price-text="$999.00"
                              data-title="Logo Infinite"
                              data-package-id="2776"
                            >
                              Order Now
                            </a>
                          </div>
                          <div class="fright">
                            <div class="liovechats-bx">
                              <a
                                href="javascript:;"
                                title=""
                                  onClick={handleClick}
                              >
                                <span>Click here to</span>
                                <h5 class="montfont"   onClick={handleClick}>
                                  Live Chat
                                </h5>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                  <div class="col-lg-3">
                    <div class="boxpackages" data-package-box>
                      <div class="packheads">
                        <h3 class="montfont">Mascot Logo</h3>
                        <p style={{ display: "none" }}>
                          Ideal for beginners and start-ups
                        </p>
                      </div>
                      <div class="packdetails">
                        <div class="packtitles">
                          <div class="fleft">
                            <h3 class="opensansfont">
                              $599 <sub class="">60% off</sub>
                            </h3>
                          </div>
                          <div class="fright">
                            <h4>
                              WAS
                              <b>
                                <span class="cutprice">$1996.7</span>
                              </b>
                            </h4>
                          </div>
                        </div>
                        <div class="scroll listpacks" data-package-scroll>
                          <ul>
                            <li>2 Mascot Logo Concepts</li>
                            <li>Unlimited Revisions </li>
                            <li>Hand drawn Sketch</li>
                            <li>Presentation on mockup</li>
                            <li>24 - 48 Hours Turn Around Time</li>
                            <li>File Formats: Ai,Eps,Png,Jpeg,pdf</li>
                            <li>
                              <span>MORE FEATURES</span>
                            </li>
                            <li>100% Satisfaction</li>
                            <li>100% Ownership Rights</li>
                            <li>Money Back Guarantee</li>
                            <li>Dedicated Account Manager</li>
                          </ul>{" "}
                        </div>
                        <div class="botarea-pack">
                          <div class="fleft">
                            <a
                              class="montfont btn-orders order-package"
                              onClick={() => setActive(true)}
                              data-sku="LOGO_MASCOT_LOGO"
                              data-promotion-id="0"
                              data-price="599"
                              data-price-text="$599.00"
                              data-title="Mascot Logo"
                              data-package-id="2785"
                            >
                              Order Now
                            </a>
                          </div>
                          <div class="fright">
                            <div class="liovechats-bx">
                              <a
                                href="javascript:;"
                                title=""
                                  onClick={handleClick}
                              >
                                <span>Click here to</span>
                                <h5 class="montfont"   onClick={handleClick}>
                                  Live Chat
                                </h5>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                  <div class="col-lg-3">
                    <div class="boxpackages" data-package-box>
                      <div class="packheads">
                        <h3 class="montfont">3D Logo</h3>
                        <p style={{ display: "none" }}>
                          Ideal for beginners and start-ups
                        </p>
                      </div>
                      <div class="packdetails">
                        <div class="packtitles">
                          <div class="fleft">
                            <h3 class="opensansfont">
                              $349 <sub class="">60% off</sub>
                            </h3>
                          </div>
                          <div class="fright">
                            <h4>
                              WAS
                              <b>
                                <span class="cutprice">$2330</span>
                              </b>
                            </h4>
                          </div>
                        </div>
                        <div class="scroll listpacks" data-package-scroll>
                          <ul>
                            <li>3 Unique 3D Logo Concepts</li>
                            <li>Light Effects and VFX</li>
                            <li>Fully Rendered</li>
                            <li>Multiple 3D Angles</li>

                            <li>3 Dedicated Designers</li>
                            <li>72 hours Turn Around Time</li>
                            <li>UNLIMITED Revisions</li>

                            <li>
                              <span>MORE FEATURES</span>
                            </li>
                            <li>100% Ownership Rights</li>
                            <li>100% Satisfaction Guarantee</li>
                            <li>100% Unique Design Guarantee</li>
                            <li>100% Money Back Guarantee*</li>
                          </ul>{" "}
                        </div>
                        <div class="botarea-pack">
                          <div class="fleft">
                            <a
                              class="montfont btn-orders order-package"
                              onClick={() => setActive(true)}
                              data-sku="LOGO_3D_LOGO"
                              data-promotion-id="0"
                              data-price="349"
                              data-price-text="$349.00"
                              data-title="3D Logo"
                              data-package-id="2786"
                            >
                              Order Now
                            </a>
                          </div>
                          <div class="fright">
                            <div class="liovechats-bx">
                              <a
                                href="javascript:;"
                                title=""
                                  onClick={handleClick}
                              >
                                <span>Click here to</span>
                                <h5 class="montfont"   onClick={handleClick}>
                                  Live Chat
                                </h5>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                </div>
              </div>
              <div id="pack1" class="fade tab-pane ">
                <div class="row responsive-slider">
                  <div class="col-lg-3">
                    <div class="boxpackages" data-package-box>
                      <div class="packheads">
                        <h3 class="montfont">Basic</h3>
                        <p style={{ display: "none" }}>
                          Ideal for beginners and start-ups
                        </p>
                      </div>
                      <div class="packdetails">
                        <div class="packtitles">
                          <div class="fleft">
                            <h3 class="opensansfont">
                              $299 <sub class="">60% off</sub>
                            </h3>
                          </div>
                          <div class="fright">
                            <h4>
                              WAS
                              <b>
                                <span class="cutprice">$599</span>
                              </b>
                            </h4>
                          </div>
                        </div>
                        <div class="scroll listpacks" data-package-scroll>
                          <ul>
                            <li>10 Keywords Optimization</li>
                            <li>4 Keywords in top 10</li>
                            <li>Search Engine Submissions</li>
                            <li> 2 Blogs Writing</li>
                            <li> 4 Blog Posting Links</li>
                            <li> 10 Blogs Social Bookmarking Links</li>
                            <li> 1 Press Release Writing</li>
                            <li>2 Press Release Links</li>
                          </ul>{" "}
                        </div>
                        <div class="botarea-pack">
                          <div class="fleft">
                            <a
                              class="montfont btn-orders order-package"
                              onClick={() => setActive(true)}
                              data-sku="SEO_Basic"
                              data-promotion-id="0"
                              data-price="299"
                              data-price-text="$299.00"
                              data-title="Basic"
                              data-package-id="2800"
                            >
                              Order Now
                            </a>
                          </div>
                          <div class="fright">
                            <div class="liovechats-bx">
                              <a
                                href="javascript:;"
                                title=""
                                  onClick={handleClick}
                              >
                                <span>Click here to</span>
                                <h5 class="montfont"   onClick={handleClick}>
                                  Live Chat
                                </h5>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                  <div class="col-lg-3">
                    <div class="boxpackages" data-package-box>
                      <div class="packheads">
                        <h3 class="montfont">Standard</h3>
                        <p style={{ display: "none" }}>
                          Ideal for beginners and start-ups
                        </p>
                      </div>
                      <div class="packdetails">
                        <div class="packtitles">
                          <div class="fleft">
                            <h3 class="opensansfont">
                              $499 <sub class="">60% off</sub>
                            </h3>
                          </div>
                          <div class="fright">
                            <h4>
                              WAS
                              <b>
                                <span class="cutprice">$1399</span>
                              </b>
                            </h4>
                          </div>
                        </div>
                        <div class="scroll listpacks" data-package-scroll>
                          <ul>
                            <li>25 Keywords Optimization</li>
                            <li> 10 Keywords in Top 10</li>
                            <li>Search Engine Submissions</li>
                            <li> 6 Blogs Writing</li>
                            <li> 12 Blog Posting Links</li>
                            <li> 20 Blogs Social Bookmarking Links</li>
                            <li>2 Press Release Writing</li>
                            <li> 4 Press Release Links</li>
                          </ul>{" "}
                        </div>
                        <div class="botarea-pack">
                          <div class="fleft">
                            <a
                              class="montfont btn-orders order-package"
                              onClick={() => setActive(true)}
                              data-sku="SEO_standard"
                              data-promotion-id="0"
                              data-price="499"
                              data-price-text="$499.00"
                              data-title="Standard"
                              data-package-id="2801"
                            >
                              Order Now
                            </a>
                          </div>
                          <div class="fright">
                            <div class="liovechats-bx">
                              <a
                                href="javascript:;"
                                title=""
                                  onClick={handleClick}
                              >
                                <span>Click here to</span>
                                <h5 class="montfont"   onClick={handleClick}>
                                  Live Chat
                                </h5>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                  <div class="col-lg-3">
                    <div class="boxpackages" data-package-box>
                      <div class="packheads">
                        <h3 class="montfont">Professional</h3>
                        <p style={{ display: "none" }}>
                          Ideal for beginners and start-ups
                        </p>
                      </div>
                      <div class="packdetails">
                        <div class="packtitles">
                          <div class="fleft">
                            <h3 class="opensansfont">
                              $799 <sub class="">60% off</sub>
                            </h3>
                          </div>
                          <div class="fright">
                            <h4>
                              WAS
                              <b>
                                <span class="cutprice">$1599</span>
                              </b>
                            </h4>
                          </div>
                        </div>
                        <div class="scroll listpacks" data-package-scroll>
                          <ul>
                            <li> 50 Keywords Optimization</li>
                            <li> 20 Keywords in Top 10</li>
                            <li>14 Blogs Writing</li>
                            <li>28 Blog Posting Links</li>
                            <li> 30 Blogs Social Bookmarking Links</li>
                            <li> 4 Press Release Writing</li>
                            <li> 8 Press Release Links</li>
                            <li> 4 Guest Blogging Links</li>
                          </ul>{" "}
                        </div>
                        <div class="botarea-pack">
                          <div class="fleft">
                            <a
                              class="montfont btn-orders order-package"
                              onClick={() => setActive(true)}
                              data-sku="SEO_professional"
                              data-promotion-id="0"
                              data-price="799"
                              data-price-text="$799.00"
                              data-title="Professional"
                              data-package-id="2802"
                            >
                              Order Now
                            </a>
                          </div>
                          <div class="fright">
                            <div class="liovechats-bx">
                              <a
                                href="javascript:;"
                                title=""
                                  onClick={handleClick}
                              >
                                <span>Click here to</span>
                                <h5 class="montfont"   onClick={handleClick}>
                                  Live Chat
                                </h5>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                  <div class="col-lg-3">
                    <div class="boxpackages" data-package-box>
                      <div class="packheads">
                        <h3 class="montfont">Enterprise</h3>
                        <p style={{ display: "none" }}>
                          Ideal for beginners and start-ups
                        </p>
                      </div>
                      <div class="packdetails">
                        <div class="packtitles">
                          <div class="fleft">
                            <h3 class="opensansfont">
                              $1499 <sub class="">60% off</sub>
                            </h3>
                          </div>
                          <div class="fright">
                            <h4>
                              WAS
                              <b>
                                <span class="cutprice">$2999</span>
                              </b>
                            </h4>
                          </div>
                        </div>
                        <div class="scroll listpacks" data-package-scroll>
                          <ul>
                            <li>100 Keywords Optimization</li>
                            <li>40 Keywords in Top 10</li>
                            <li>30 Blogs Writing</li>
                            <li>60 Blog Posting Links</li>
                            <li>40 Blogs Social Bookmarking Links</li>
                            <li> 8 Press Release Writing</li>
                            <li> 8 Press Release Links</li>
                            <li> 8 Guest Blogging Links </li>
                          </ul>{" "}
                        </div>
                        <div class="botarea-pack">
                          <div class="fleft">
                            <a
                              class="montfont btn-orders order-package"
                              onClick={() => setActive(true)}
                              data-sku="SEO_enterprise"
                              data-promotion-id="0"
                              data-price="1499"
                              data-price-text="$1499.00"
                              data-title="Enterprise"
                              data-package-id="2803"
                            >
                              Order Now
                            </a>
                          </div>
                          <div class="fright">
                            <div class="liovechats-bx">
                              <a
                                href="javascript:;"
                                title=""
                                  onClick={handleClick}
                              >
                                <span>Click here to</span>
                                <h5 class="montfont"   onClick={handleClick}>
                                  Live Chat
                                </h5>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                </div>
              </div>
              <div id="pack2" class="fade tab-pane ">
                <div class="row responsive-slider">
                  <div class="col-lg-3">
                    <div class="boxpackages" data-package-box>
                      <div class="packheads">
                        <h3 class="montfont">Teaser</h3>
                        <p style={{ display: "none" }}>
                          Ideal for beginners and start-ups
                        </p>
                      </div>
                      <div class="packdetails">
                        <div class="packtitles">
                          <div class="fleft">
                            <h3 class="opensansfont">
                              $499 <sub class="">60% off</sub>
                            </h3>
                          </div>
                          <div class="fright">
                            <h4>
                              WAS
                              <b>
                                <span class="cutprice">$1000</span>
                              </b>
                            </h4>
                          </div>
                        </div>
                        <div class="scroll listpacks" data-package-scroll>
                          <ul>
                            <li>30sec Duration</li>
                            <li>Script Writing</li>
                            <li>Custom Artwork</li>
                            <li>Background Music</li>
                            <li>HD Format Video</li>
                            <li>Dedicated Support </li>
                            <li>100% Ownership Rights</li>
                            <li>100% Money-back Guarantee</li>
                          </ul>{" "}
                        </div>
                        <div class="botarea-pack">
                          <div class="fleft">
                            <a
                              class="montfont btn-orders order-package"
                              onClick={() => setActive(true)}
                              data-sku="ANIMATION_TEASER"
                              data-promotion-id="0"
                              data-price="499"
                              data-price-text="$499.00"
                              data-title="Teaser"
                              data-package-id="2804"
                            >
                              Order Now
                            </a>
                          </div>
                          <div class="fright">
                            <div class="liovechats-bx">
                              <a
                                href="javascript:;"
                                title=""
                                  onClick={handleClick}
                              >
                                <span>Click here to</span>
                                <h5 class="montfont"   onClick={handleClick}>
                                  Live Chat
                                </h5>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                  <div class="col-lg-3">
                    <div class="boxpackages" data-package-box>
                      <div class="packheads">
                        <h3 class="montfont">Startup</h3>
                        <p style={{ display: "none" }}>
                          Ideal for beginners and start-ups
                        </p>
                      </div>
                      <div class="packdetails">
                        <div class="packtitles">
                          <div class="fleft">
                            <h3 class="opensansfont">
                              $799 <sub class="">60% off</sub>
                            </h3>
                          </div>
                          <div class="fright">
                            <h4>
                              WAS
                              <b>
                                <span class="cutprice">$1650</span>
                              </b>
                            </h4>
                          </div>
                        </div>
                        <div class="scroll listpacks" data-package-scroll>
                          <ul>
                            <li>30sec Duration</li>
                            <li>Script Writing</li>
                            <li>Professional Voice-over &amp; SFX </li>
                            <li>Hand-drawn Illustrations</li>
                            <li>Unlimited Revisions</li>
                            <li>HD Format Video</li>
                            <li>Dedicated Support</li>
                            <li>100% Ownership Rights</li>
                            <li>100% Money-back Guarantee</li>
                          </ul>{" "}
                        </div>
                        <div class="botarea-pack">
                          <div class="fleft">
                            <a
                              class="montfont btn-orders order-package"
                              onClick={() => setActive(true)}
                              data-sku="ANIMATION_STARTUP"
                              data-promotion-id="0"
                              data-price="799"
                              data-price-text="$799.00"
                              data-title="Startup"
                              data-package-id="2805"
                            >
                              Order Now
                            </a>
                          </div>
                          <div class="fright">
                            <div class="liovechats-bx">
                              <a
                                href="javascript:;"
                                title=""
                                  onClick={handleClick}
                              >
                                <span>Click here to</span>
                                <h5 class="montfont"   onClick={handleClick}>
                                  Live Chat
                                </h5>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                  <div class="col-lg-3">
                    <div class="boxpackages" data-package-box>
                      <div class="packheads">
                        <h3 class="montfont">Classic</h3>
                        <p style={{ display: "none" }}>
                          Ideal for beginners and start-ups
                        </p>
                      </div>
                      <div class="packdetails">
                        <div class="packtitles">
                          <div class="fleft">
                            <h3 class="opensansfont">
                              $999 <sub class="">60% off</sub>
                            </h3>
                          </div>
                          <div class="fright">
                            <h4>
                              WAS
                              <b>
                                <span class="cutprice">$2500</span>
                              </b>
                            </h4>
                          </div>
                        </div>
                        <div class="scroll listpacks" data-package-scroll>
                          <ul>
                            <li>30sec Duration</li>
                            <li>Script Writing</li>
                            <li>Professional Voice-over &amp; SFX </li>
                            <li>Custom 2D Characters</li>
                            <li>Unlimited Revisions</li>
                            <li>HD Format Video</li>
                            <li>Dedicated Support</li>
                            <li>100% Ownership Rights</li>
                            <li>100% Money-back Guarantee </li>
                          </ul>{" "}
                        </div>
                        <div class="botarea-pack">
                          <div class="fleft">
                            <a
                              class="montfont btn-orders order-package"
                              onClick={() => setActive(true)}
                              data-sku="ANIMATION_CLASSIC"
                              data-promotion-id="0"
                              data-price="999"
                              data-price-text="$999.00"
                              data-title="Classic"
                              data-package-id="2806"
                            >
                              Order Now
                            </a>
                          </div>
                          <div class="fright">
                            <div class="liovechats-bx">
                              <a
                                href="javascript:;"
                                title=""
                                  onClick={handleClick}
                              >
                                <span>Click here to</span>
                                <h5 class="montfont"   onClick={handleClick}>
                                  Live Chat
                                </h5>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                  <div class="col-lg-3">
                    <div class="boxpackages" data-package-box>
                      <div class="packheads">
                        <h3 class="montfont">3D Video</h3>
                        <p style={{ display: "none" }}>
                          Ideal for beginners and start-ups
                        </p>
                      </div>
                      <div class="packdetails">
                        <div class="packtitles">
                          <div class="fleft">
                            <h3 class="opensansfont">
                              $3999 <sub class="">60% off</sub>
                            </h3>
                          </div>
                          <div class="fright">
                            <h4>
                              WAS
                              <b>
                                <span class="cutprice">$5500</span>
                              </b>
                            </h4>
                          </div>
                        </div>
                        <div class="scroll listpacks" data-package-scroll>
                          <ul>
                            <li>2 3D Object Models</li>
                            <li>Unlimited Storyboard Revisions</li>
                            <li>Professional Voice - Over</li>
                            <li>Background Music</li>
                            <li>Exotic Animations</li>
                            <li>HD Video Format</li>
                            <li>6 Weeks Delivery Time</li>
                            <li>100% Ownership Rights</li>
                            <li>100% Money-back Guarantee </li>
                          </ul>{" "}
                        </div>
                        <div class="botarea-pack">
                          <div class="fleft">
                            <a
                              class="montfont btn-orders order-package"
                              onClick={() => setActive(true)}
                              data-sku="ANIMATION_3D"
                              data-promotion-id="0"
                              data-price="3999"
                              data-price-text="$3999.00"
                              data-title="3D Video"
                              data-package-id="4219"
                            >
                              Order Now
                            </a>
                          </div>
                          <div class="fright">
                            <div class="liovechats-bx">
                              <a
                                href="javascript:;"
                                title=""
                                  onClick={handleClick}
                              >
                                <span>Click here to</span>
                                <h5 class="montfont"   onClick={handleClick}>
                                  Live Chat
                                </h5>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                </div>
              </div>
              <div id="pack3" class="fade tab-pane ">
                <div class="row responsive-slider">
                  <div class="col-lg-3">
                    <div class="boxpackages" data-package-box>
                      <div class="packheads">
                        <h3 class="montfont">Basic</h3>
                        <p style={{ display: "none" }}>
                          Ideal for beginners and start-ups
                        </p>
                      </div>
                      <div class="packdetails">
                        <div class="packtitles">
                          <div class="fleft">
                            <h3 class="opensansfont">
                              $249 <sub class="">60% off</sub>
                            </h3>
                          </div>
                          <div class="fright">
                            <h4>
                              WAS
                              <b>
                                <span class="cutprice">$430</span>
                              </b>
                            </h4>
                          </div>
                        </div>
                        <div class="scroll listpacks" data-package-scroll>
                          <ul>
                            <li>Print Media Design </li>
                            <li>
                              Stationery Design (Business Card, Leterhead,
                              Envelope{" "}
                            </li>
                            <li>Flyer Design/Postcard (any one) </li>
                            <li>Printing Service </li>
                            <li>
                              500 x Business Card Prints OR 250 x Flyer Prints{" "}
                            </li>
                            <li>Multiple File Format(Ai, PNG, JPEG, etc) </li>
                            <li>Turn around time 24 - 48 Hours </li>
                            <li>100% Satisfaction Guaranteed* </li>
                            <li>
                              <span>Add on:</span>{" "}
                            </li>
                            <li>$30 for 24 - 48 Hours Rush Delivery</li>
                          </ul>{" "}
                        </div>
                        <div class="botarea-pack">
                          <div class="fleft">
                            <a
                              class="montfont btn-orders order-package"
                              onClick={() => setActive(true)}
                              data-sku="PRINT_BASIC"
                              data-promotion-id="0"
                              data-price="249"
                              data-price-text="$249.00"
                              data-title="Basic"
                              data-package-id="2810"
                            >
                              Order Now
                            </a>
                          </div>
                          <div class="fright">
                            <div class="liovechats-bx">
                              <a
                                href="javascript:;"
                                title=""
                                  onClick={handleClick}
                              >
                                <span>Click here to</span>
                                <h5 class="montfont"   onClick={handleClick}>
                                  Live Chat
                                </h5>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                  <div class="col-lg-3">
                    <div class="boxpackages" data-package-box>
                      <div class="packheads">
                        <h3 class="montfont">Plus</h3>
                        <p style={{ display: "none" }}>
                          Ideal for beginners and start-ups
                        </p>
                      </div>
                      <div class="packdetails">
                        <div class="packtitles">
                          <div class="fleft">
                            <h3 class="opensansfont">
                              $499 <sub class="">60% off</sub>
                            </h3>
                          </div>
                          <div class="fright">
                            <h4>
                              WAS
                              <b>
                                <span class="cutprice">$1663</span>
                              </b>
                            </h4>
                          </div>
                        </div>
                        <div class="scroll listpacks" data-package-scroll>
                          <ul>
                            <li>Print Media Design </li>
                            <li>
                              Stationery Design (Business Card, Letterhead,
                              Envelope){" "}
                            </li>
                            <li>Flyer Design/Postcard/Menu Card (any one) </li>
                            <li>Printing Service </li>
                            <li>500 x Business Card Prints </li>
                            <li>500 x Letterhead Prints </li>
                            <li>500 x Envelop Prints </li>
                            <li>100 x Flyer Prints </li>
                            <li>
                              <span>Add on:</span>
                            </li>
                            <li>$60 for 24 - 48 Hours Rush Delivery</li>
                          </ul>{" "}
                        </div>
                        <div class="botarea-pack">
                          <div class="fleft">
                            <a
                              class="montfont btn-orders order-package"
                              onClick={() => setActive(true)}
                              data-sku="PRINT_PLUS"
                              data-promotion-id="0"
                              data-price="499"
                              data-price-text="$499.00"
                              data-title="Plus"
                              data-package-id="2811"
                            >
                              Order Now
                            </a>
                          </div>
                          <div class="fright">
                            <div class="liovechats-bx">
                              <a
                                href="javascript:;"
                                title=""
                                  onClick={handleClick}
                              >
                                <span>Click here to</span>
                                <h5 class="montfont"   onClick={handleClick}>
                                  Live Chat
                                </h5>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                  <div class="col-lg-3">
                    <div class="boxpackages" data-package-box>
                      <div class="packheads">
                        <h3 class="montfont">Classic</h3>
                        <p style={{ display: "none" }}>
                          Ideal for beginners and start-ups
                        </p>
                      </div>
                      <div class="packdetails">
                        <div class="packtitles">
                          <div class="fleft">
                            <h3 class="opensansfont">
                              $1299 <sub class="">60% off</sub>
                            </h3>
                          </div>
                          <div class="fright">
                            <h4>
                              WAS
                              <b>
                                <span class="cutprice">$4330</span>
                              </b>
                            </h4>
                          </div>
                        </div>
                        <div class="scroll listpacks" data-package-scroll>
                          <ul>
                            <li>Print Media Design </li>
                            <li>
                              Stationery Design (Business Card, Letterhead,
                              Envelope) Flyer Design/Postcard/Menu Card (any
                              one){" "}
                            </li>
                            <li>
                              Brochure/Product Packaging Design (any one){" "}
                            </li>
                            <li>
                              Apparel Design (T-Shirt, Cap, Bumper Sticker{" "}
                            </li>
                            <li>Printing Service </li>
                            <li>
                              1000 Prints Each (Business Card,Letterhead,
                              Envelope){" "}
                            </li>
                            <li>250 x Flyer/Brochure/ Menu Card Prints </li>
                            <li>50 x T-Shirt Prints </li>
                            <li>Turn around time 24 - 48 Hours </li>
                            <li>
                              Multiple Files Format Ai, PSD, Png, Jpeg Etc{" "}
                            </li>
                            <li>100% Satisfaction Guaranteed* </li>
                            <li>
                              <span>Add on:</span>
                            </li>
                            <li>$90 for 24 - 48 Hours Rush Delivery</li>
                          </ul>{" "}
                        </div>
                        <div class="botarea-pack">
                          <div class="fleft">
                            <a
                              class="montfont btn-orders order-package"
                              onClick={() => setActive(true)}
                              data-sku="PRINT_CLASSIC"
                              data-promotion-id="0"
                              data-price="1299"
                              data-price-text="$1299.00"
                              data-title="Classic"
                              data-package-id="2812"
                            >
                              Order Now
                            </a>
                          </div>
                          <div class="fright">
                            <div class="liovechats-bx">
                              <a
                                href="javascript:;"
                                title=""
                                  onClick={handleClick}
                              >
                                <span>Click here to</span>
                                <h5 class="montfont"   onClick={handleClick}>
                                  Live Chat
                                </h5>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                </div>
              </div>
              <div id="pack4" class="fade tab-pane ">
                <div class="row responsive-slider">
                  <div class="col-lg-3">
                    <div class="boxpackages" data-package-box>
                      <div class="packheads">
                        <h3 class="montfont">Basic Website</h3>
                        <p style={{ display: "none" }}>
                          Ideal for beginners and start-ups
                        </p>
                      </div>
                      <div class="packdetails">
                        <div class="packtitles">
                          <div class="fleft">
                            <h3 class="opensansfont">
                              $399 <sub class="">60% off</sub>
                            </h3>
                          </div>
                          <div class="fright">
                            <h4>
                              WAS
                              <b>
                                <span class="cutprice">$1330</span>
                              </b>
                            </h4>
                          </div>
                        </div>
                        <div class="scroll listpacks" data-package-scroll>
                          <ul>
                            <li>3 Page Website </li>
                            <li>Custom Layout Design </li>
                            <li>Contact/Query Form </li>
                            <li>1 Banner Design </li>
                            <li>2 Stock Photos </li>
                            <li>
                              <span>FREE Favicon Design</span>
                            </li>
                            <li>Cross Browser Compatible </li>
                            <li> Complete W3C Certified HTML </li>
                            <li>Website Initial Concepts in 48 Hours</li>
                            <li>Complete Design & Deployment </li>
                            <li>Complete Source Files </li>
                            <li>Dedicated Project Manager </li>
                            <li>100% Ownership Rights </li>
                            <li>100% Satisfaction Guarantee </li>
                            <li>100% Money Back Guarantee </li>
                            <li>Value Added Services </li>
                            <li>Mobile Responsive will be Additional $200* </li>
                            <li>CMS will be Additional $250* </li>
                            <li> *NO MONTHLY OR ANY HIDDEN FEE*</li>
                          </ul>{" "}
                        </div>
                        <div class="botarea-pack">
                          <div class="fleft">
                            <a
                              class="montfont btn-orders order-package"
                              onClick={() => setActive(true)}
                              data-sku="WEB_INFORMATIVE_BASIC_WEBSITE"
                              data-promotion-id="0"
                              data-price="399"
                              data-price-text="$399.00"
                              data-title="Basic Website"
                              data-package-id="2787"
                            >
                              Order Now
                            </a>
                          </div>
                          <div class="fright">
                            <div class="liovechats-bx">
                              <a
                                href="javascript:;"
                                title=""
                                  onClick={handleClick}
                              >
                                <span>Click here to</span>
                                <h5 class="montfont"   onClick={handleClick}>
                                  Live Chat
                                </h5>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                  <div class="col-lg-3">
                    <div class="boxpackages" data-package-box>
                      <div class="packheads">
                        <h3 class="montfont">Startup Website</h3>
                        <p style={{ display: "none" }}>
                          Ideal for beginners and start-ups
                        </p>
                      </div>
                      <div class="packdetails">
                        <div class="packtitles">
                          <div class="fleft">
                            <h3 class="opensansfont">
                              $799 <sub class="">60% off</sub>
                            </h3>
                          </div>
                          <div class="fright">
                            <h4>
                              WAS
                              <b>
                                <span class="cutprice">$2663</span>
                              </b>
                            </h4>
                          </div>
                        </div>
                        <div class="scroll listpacks" data-package-scroll>
                          <ul>
                            <li>5 Page Website </li>
                            <li>Custom Layout Design </li>
                            <li>Contact/Query Form </li>
                            <li>3 Banner Designs </li>
                            <li>5 Stock Photos</li>
                            <li>
                              <span>FREE Favicon Design</span>
                            </li>
                            <li>FREE Google Friendly Sitemap</li>
                            <li>
                              <span>Unlimited Revisions</span>
                            </li>
                            <li>Complete W3C Certified HTML </li>
                            <li>Website Initial Concepts in 48 Hours </li>
                            <li>Complete Design & Deployment </li>
                            <li>Complete Source Files </li>
                            <li>Dedicated Project Manager </li>
                            <li>100% Ownership Rights </li>
                            <li>100% Satisfaction Guarantee </li>
                            <li>100% Money Back Guarantee </li>
                            <li>Value Added Services </li>
                            <li>Mobile Responsive will be Additional $200* </li>
                            <li>CMS will be Additional $250* </li>
                            <li>*NO MONTHLY OR ANY HIDDEN FEE*</li>
                          </ul>{" "}
                        </div>
                        <div class="botarea-pack">
                          <div class="fleft">
                            <a
                              class="montfont btn-orders order-package"
                              onClick={() => setActive(true)}
                              data-sku="WEB_INFORMATIVE_STARTUP_WEBSITE"
                              data-promotion-id="0"
                              data-price="799"
                              data-price-text="$799.00"
                              data-title="Startup Website"
                              data-package-id="2788"
                            >
                              Order Now
                            </a>
                          </div>
                          <div class="fright">
                            <div class="liovechats-bx">
                              <a
                                href="javascript:;"
                                title=""
                                  onClick={handleClick}
                              >
                                <span>Click here to</span>
                                <h5 class="montfont"   onClick={handleClick}>
                                  Live Chat
                                </h5>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                  <div class="col-lg-3">
                    <div class="boxpackages" data-package-box>
                      <div class="packheads">
                        <h3 class="montfont">Professional Website</h3>
                        <p style={{ display: "none" }}>
                          Ideal for beginners and start-ups
                        </p>
                      </div>
                      <div class="packdetails">
                        <div class="packtitles">
                          <div class="fleft">
                            <h3 class="opensansfont">
                              $1199 <sub class="">60% off</sub>
                            </h3>
                          </div>
                          <div class="fright">
                            <h4>
                              WAS
                              <b>
                                <span class="cutprice">$3996</span>
                              </b>
                            </h4>
                          </div>
                        </div>
                        <div class="scroll listpacks" data-package-scroll>
                          <ul>
                            <li>Up to 10 Unique Pages Website </li>
                            <li>CMS /Admin Panel Integration </li>
                            <li>5+ Stock Photos & Banner Designs </li>
                            <li>FREE Social Media Integration </li>
                            <li>FREE Favicon Design </li>
                            <li>FREE Google Friendly Sitemap</li>
                            <li>Unlimited Revisions</li>
                            <li>Cross Browser Compatible </li>
                            <li>Complete W3C Certified HTML </li>
                            <li>Website Initial Concepts in 48 Hours</li>
                            <li>Complete Design & Deployment</li>
                            <li>Custom, Interactive & Dynamic Web Design </li>
                            <li>
                              Industry specified Team of Expert Designers and
                              Developers{" "}
                            </li>
                            <li>Complete Source Files </li>
                            <li>Dedicated Project Manager </li>
                            <li>100% Ownership Rights </li>
                            <li>100% Satisfaction Guarantee </li>
                            <li>100% Money Back Guarantee </li>
                            <li>Value Added Services </li>
                            <li>Mobile Responsive will be Additional $200* </li>
                            <li>CMS will be Additional $250* </li>
                            <li>*NO MONTHLY OR ANY HIDDEN FEE*</li>
                          </ul>{" "}
                        </div>
                        <div class="botarea-pack">
                          <div class="fleft">
                            <a
                              class="montfont btn-orders order-package"
                              onClick={() => setActive(true)}
                              data-sku="WEB_INFORMATIVE_PROFESSIONAL_WEBSITE"
                              data-promotion-id="0"
                              data-price="1199"
                              data-price-text="$1199.00"
                              data-title="Professional Website"
                              data-package-id="2789"
                            >
                              Order Now
                            </a>
                          </div>
                          <div class="fright">
                            <div class="liovechats-bx">
                              <a
                                href="javascript:;"
                                title=""
                                  onClick={handleClick}
                              >
                                <span>Click here to</span>
                                <h5 class="montfont"   onClick={handleClick}>
                                  Live Chat
                                </h5>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                  <div class="col-lg-3">
                    <div class="boxpackages" data-package-box>
                      <div class="packheads">
                        <h3 class="montfont">E-Commerce Website</h3>
                        <p style={{ display: "none" }}>
                          Ideal for beginners and start-ups
                        </p>
                      </div>
                      <div class="packdetails">
                        <div class="packtitles">
                          <div class="fleft">
                            <h3 class="opensansfont">
                              $1799 <sub class="">60% off</sub>
                            </h3>
                          </div>
                          <div class="fright">
                            <h4>
                              WAS
                              <b>
                                <span class="cutprice">$5996</span>
                              </b>
                            </h4>
                          </div>
                        </div>
                        <div class="scroll listpacks" data-package-scroll>
                          <ul>
                            <li>Custom Ecommerce Website </li>
                            <li>Up to 200 Products </li>
                            <li>CMS /Admin Panel Integration </li>
                            <li>Fully Mobile Responsive </li>
                            <li>Shopping Cart Integration </li>
                            <li>Payment Gateway Integration</li>
                            <li>
                              <span>Product Listing & Management</span>
                            </li>
                            <li>Order Management & Tracking </li>
                            <li>Banner Designs </li>
                            <li>Unlimited Revisions</li>
                            <li>
                              <span>FREE Social Media Integration</span>
                            </li>
                            <li>FREE Favicon Design </li>
                            <li>
                              FREE Google Friendly Sitemap Search Engine
                              Submission{" "}
                            </li>
                            <li>Complete W3C Certified HTML </li>
                            <li>
                              Industry specified Team of Expert Designers and
                              Developers{" "}
                            </li>
                            <li>Complete Deployment </li>
                            <li>Complete Source Files </li>
                            <li>Dedicated Project Manager </li>
                            <li>100% Ownership Rights </li>
                            <li>100% Satisfaction Guarantee </li>
                            <li>100% Money Back Guarantee </li>
                            <li>*NO MONTHLY OR ANY HIDDEN FEE*</li>
                          </ul>{" "}
                        </div>
                        <div class="botarea-pack">
                          <div class="fleft">
                            <a
                              class="montfont btn-orders order-package"
                              onClick={() => setActive(true)}
                              data-sku="WEB_INFORMATIVE_E_COMMERCE_WEBSITE"
                              data-promotion-id="0"
                              data-price="1799"
                              data-price-text="$1799.00"
                              data-title="E-Commerce Website"
                              data-package-id="2790"
                            >
                              Order Now
                            </a>
                          </div>
                          <div class="fright">
                            <div class="liovechats-bx">
                              <a
                                href="javascript:;"
                                title=""
                                  onClick={handleClick}
                              >
                                <span>Click here to</span>
                                <h5 class="montfont"   onClick={handleClick}>
                                  Live Chat
                                </h5>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                  <div class="col-lg-3">
                    <div class="boxpackages" data-package-box>
                      <div class="packheads">
                        <h3 class="montfont">Corporate Website</h3>
                        <p style={{ display: "none" }}>
                          Ideal for beginners and start-ups
                        </p>
                      </div>
                      <div class="packdetails">
                        <div class="packtitles">
                          <div class="fleft">
                            <h3 class="opensansfont">
                              $2599 <sub class="">60% off</sub>
                            </h3>
                          </div>
                          <div class="fright">
                            <h4>
                              WAS
                              <b>
                                <span class="cutprice">$8663</span>
                              </b>
                            </h4>
                          </div>
                        </div>
                        <div class="scroll listpacks" data-package-scroll>
                          <ul>
                            <li>Up to 15 Unique Pages Website </li>
                            <li>Custom Made, Interactive & Dynamic Design </li>
                            <li>Customized WordPress or PHP Development </li>
                            <li>Fully Mobile Responsive </li>
                            <li>Interactive Sliding Banners </li>
                            <li>Up to 10 Custom Made Banner Designs </li>
                            <li>
                              <span>10 Stock Images</span>{" "}
                            </li>
                            <li>Unlimited Revisions </li>
                            <li>
                              <span>Content Management System</span>{" "}
                            </li>
                            <li>
                              Online Signup Area (For Newsletters, Offers etc.){" "}
                            </li>
                            <li>Search Bar </li>
                            <li>
                              Live Feeds of Social Networks integration
                              (Optional){" "}
                            </li>
                            <li>Google Friendly Sitemap </li>
                            <li>Search Engine Submission </li>
                            <li>Cross Browser Compatible </li>
                            <li>Complete W3C Certified HTML </li>
                            <li>
                              Industry specified Team of Expert Designers and
                              Developers{" "}
                            </li>
                            <li>Complete Deployment </li>
                            <li>Complete Source Files </li>
                            <li>Dedicated Project Manager </li>
                            <li>100% Ownership Rights </li>
                            <li>100% Satisfaction Guarantee </li>
                            <li>100% Money Back Guarantee </li>
                            <li>*NO MONTHLY OR ANY HIDDEN FEE* </li>
                          </ul>{" "}
                        </div>
                        <div class="botarea-pack">
                          <div class="fleft">
                            <a
                              class="montfont btn-orders order-package"
                              onClick={() => setActive(true)}
                              data-sku="WEB_INFORMATIVE_CORPORATE_WEBSITE"
                              data-promotion-id="0"
                              data-price="2599"
                              data-price-text="$2599.00"
                              data-title="Corporate Website"
                              data-package-id="2791"
                            >
                              Order Now
                            </a>
                          </div>
                          <div class="fright">
                            <div class="liovechats-bx">
                              <a
                                href="javascript:;"
                                title=""
                                  onClick={handleClick}
                              >
                                <span>Click here to</span>
                                <h5 class="montfont"   onClick={handleClick}>
                                  Live Chat
                                </h5>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                  <div class="col-lg-3">
                    <div class="boxpackages" data-package-box>
                      <div class="packheads">
                        <h3 class="montfont">Platinum Website</h3>
                        <p style={{ display: "none" }}>
                          Ideal for beginners and start-ups
                        </p>
                      </div>
                      <div class="packdetails">
                        <div class="packtitles">
                          <div class="fleft">
                            <h3 class="opensansfont">
                              $3999 <sub class="">60% off</sub>
                            </h3>
                          </div>
                          <div class="fright">
                            <h4>
                              WAS
                              <b>
                                <span class="cutprice">$13330</span>
                              </b>
                            </h4>
                          </div>
                        </div>
                        <div class="scroll listpacks" data-package-scroll>
                          <ul>
                            <li>Up to 20 Unique Pages Website </li>
                            <li>
                              Custom Made, Interactive, Dynamic & High-End
                              Design{" "}
                            </li>
                            <li>Customized WordPress or PHP Development </li>
                            <li>Fully Mobile Responsive </li>
                            <li>Interactive Sliding Banners </li>
                            <li>Up to 15 Custom Made Banner Designs</li>
                            <li>
                              <span>15 Stock Images</span>
                            </li>
                            <li>Unlimited Revisions</li>
                            <li>
                              <span>Content Management System</span>
                            </li>
                            <li>
                              <span>
                                Online Appointment/Booking/Scheduling/Online
                                Ordering Integration (Optional)
                              </span>
                            </li>
                            <li>Online Payment Integration (Optional) </li>
                            <li>Multi Lingual (Optional) </li>
                            <li>Custom Dynamic Forms (Optional) </li>
                            <li>Signup Area (For Newsletters, Offers etc.)</li>
                            <li>
                              <span>Search Bar</span>
                            </li>
                            <li>
                              Live Feeds of Social Networks integration
                              (Optional){" "}
                            </li>
                            <li>Google Friendly Sitemap </li>
                            <li>Search Engine Submission </li>
                            <li>Cross Browser Compatible </li>
                            <li>Complete W3C Certified HTML </li>
                            <li>
                              Industry specified Team of Expert Designers and
                              Developers{" "}
                            </li>
                            <li>Complete Deployment </li>
                            <li>Complete Source Files </li>
                            <li>Dedicated Project Manager </li>
                            <li>100% Ownership Rights </li>
                            <li>100% Satisfaction Guarantee </li>
                            <li>100% Money Back Guarantee </li>
                            <li>*NO MONTHLY OR ANY HIDDEN FEE*</li>
                          </ul>{" "}
                        </div>
                        <div class="botarea-pack">
                          <div class="fleft">
                            <a
                              class="montfont btn-orders order-package"
                              onClick={() => setActive(true)}
                              data-sku="WEB_INFORMATIVE_PLATINUM_WEBSITE"
                              data-promotion-id="0"
                              data-price="3999"
                              data-price-text="$3999.00"
                              data-title="Platinum Website"
                              data-package-id="2792"
                            >
                              Order Now
                            </a>
                          </div>
                          <div class="fright">
                            <div class="liovechats-bx">
                              <a
                                href="javascript:;"
                                title=""
                                  onClick={handleClick}
                              >
                                <span>Click here to</span>
                                <h5 class="montfont"   onClick={handleClick}>
                                  Live Chat
                                </h5>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                </div>
              </div>
              <div id="pack5" class="fade tab-pane ">
                <div class="row responsive-slider">
                  <div class="col-lg-3">
                    <div class="boxpackages" data-package-box>
                      <div class="packheads">
                        <h3 class="montfont">Branding Startup</h3>
                        <p style={{ display: "none" }}>
                          Ideal for beginners and start-ups
                        </p>
                      </div>
                      <div class="packdetails">
                        <div class="packtitles">
                          <div class="fleft">
                            <h3 class="opensansfont">
                              $499 <sub class="">60% off</sub>
                            </h3>
                          </div>
                          <div class="fright">
                            <h4>
                              WAS
                              <b>
                                <span class="cutprice">$1663</span>
                              </b>
                            </h4>
                          </div>
                        </div>
                        <div class="scroll listpacks" data-package-scroll>
                          <ul>
                            <li>
                              <span>Logo Design </span>{" "}
                            </li>
                            <li>6 Unique Logo Concepts</li>
                            <li>FREE Icon </li>
                            <li>FREE Grayscale Copy </li>
                            <li>Unlimited Revisions </li>
                            <li>100% Ownership Right </li>
                            <li>AI, PSD, EPS, GIF, BMP, JPEG, PNG Formats </li>
                            <li>
                              <span>Print Media</span>{" "}
                            </li>
                            <li>
                              Stationery Design (Business Card, Letterhead,
                              Envelope)
                            </li>
                            <li>
                              <span>Website Design</span>
                            </li>
                            <li>5 Pages Custom Business Website</li>
                            <li>Complete Deployment</li>
                          </ul>{" "}
                        </div>
                        <div class="botarea-pack">
                          <div class="fleft">
                            <a
                              class="montfont btn-orders order-package"
                              onClick={() => setActive(true)}
                              data-sku="BRANDING_BRANDING_STARTUP"
                              data-promotion-id="0"
                              data-price="499"
                              data-price-text="$499.00"
                              data-title="Branding Startup"
                              data-package-id="2808"
                            >
                              Order Now
                            </a>
                          </div>
                          <div class="fright">
                            <div class="liovechats-bx">
                              <a
                                href="javascript:;"
                                title=""
                                  onClick={handleClick}
                              >
                                <span>Click here to</span>
                                <h5 class="montfont"   onClick={handleClick}>
                                  Live Chat
                                </h5>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                  <div class="col-lg-3">
                    <div class="boxpackages" data-package-box>
                      <div class="packheads">
                        <h3 class="montfont">Branding Plus</h3>
                        <p style={{ display: "none" }}>
                          Ideal for beginners and start-ups
                        </p>
                      </div>
                      <div class="packdetails">
                        <div class="packtitles">
                          <div class="fleft">
                            <h3 class="opensansfont">
                              $999 <sub class="">60% off</sub>
                            </h3>
                          </div>
                          <div class="fright">
                            <h4>
                              WAS
                              <b>
                                <span class="cutprice">$3333</span>
                              </b>
                            </h4>
                          </div>
                        </div>
                        <div class="scroll listpacks" data-package-scroll>
                          <ul>
                            <li>
                              <span>Logo Design</span>
                            </li>
                            <li>12 Unique Logo Concepts </li>
                            <li>FREE Icon </li>
                            <li>FREE Grayscale Copy </li>
                            <li>Unlimited Revisions </li>
                            <li>100% Ownership Right </li>
                            <li>AI, PSD, EPS, GIF, BMP, JPEG, PNG Formats</li>
                            <li>
                              <span>Print Material</span>
                            </li>
                            <li>
                              Stationery Design(Business
                              Card,Letterhead,Envelope)
                            </li>
                            <li>Flyer Design / Label Design</li>
                            <li>
                              <span>Website Design</span>
                            </li>
                            <li>Custom 5 Pages Professional Website</li>
                            <li>Content Management System (WordPress)</li>
                            <li>Responsive Design (Mobile Compatible)</li>
                            <li>Complete Deployment</li>
                            <li>Social Media Design</li>
                            <li>Facebook Fanpage Splash Page + Cover Photo</li>
                          </ul>{" "}
                        </div>
                        <div class="botarea-pack">
                          <div class="fleft">
                            <a
                              class="montfont btn-orders order-package"
                              onClick={() => setActive(true)}
                              data-sku="BRANDING_BRANDING_PLUS"
                              data-promotion-id="0"
                              data-price="999"
                              data-price-text="$999.00"
                              data-title="Branding Plus"
                              data-package-id="2809"
                            >
                              Order Now
                            </a>
                          </div>
                          <div class="fright">
                            <div class="liovechats-bx">
                              <a
                                href="javascript:;"
                                title=""
                                  onClick={handleClick}
                              >
                                <span>Click here to</span>
                                <h5 class="montfont"   onClick={handleClick}>
                                  Live Chat
                                </h5>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                  <div class="col-lg-3">
                    <div class="boxpackages" data-package-box>
                      <div class="packheads">
                        <h3 class="montfont">Branding Classic</h3>
                        <p style={{ display: "none" }}>
                          Ideal for beginners and start-ups
                        </p>
                      </div>
                      <div class="packdetails">
                        <div class="packtitles">
                          <div class="fleft">
                            <h3 class="opensansfont">
                              $1499 <sub class="">60% off</sub>
                            </h3>
                          </div>
                          <div class="fright">
                            <h4>
                              WAS
                              <b>
                                <span class="cutprice">$4993</span>
                              </b>
                            </h4>
                          </div>
                        </div>
                        <div class="scroll listpacks" data-package-scroll>
                          <ul>
                            <li>
                              <span>Logo Design</span>
                            </li>
                            <li>12 Unique Logo Concepts</li>
                            <li>FREE Icon </li>
                            <li>FREE Grayscale Copy </li>
                            <li>Unlimited Revisions </li>
                            <li>100% Ownership Right </li>
                            <li>AI, PSD, EPS, GIF, BMP, JPEG, PNG Formats</li>
                            <li>Print Material</li>
                            <li>
                              Stationery Design(Business
                              Card,Letterhead,Envelope)
                            </li>
                            <li>
                              <span>Any Product of your Choice:</span>
                            </li>
                            <li>
                              Bi-fold/Tri-fold Brochure / Menu Card / Corporate
                              Profile)
                            </li>
                            <li>Website Design</li>
                            <li>10+ Page Website</li>
                            <li>
                              Content Management System (WordPress/Joomla)
                            </li>
                            <li>Complete Deployment</li>
                            <li>Search Engine Submission</li>
                            <li>Google, Bing, Yahoo and More</li>
                            <li>Social Media Design</li>
                            <li>Facebook Fanpage Splash Page + Cover Photo</li>
                            <li>Twitter Background Design</li>
                            <li>Google+ Profile Banner</li>
                          </ul>{" "}
                        </div>
                        <div class="botarea-pack">
                          <div class="fleft">
                            <a
                              class="montfont btn-orders order-package"
                              onClick={() => setActive(true)}
                              data-sku="BRANDING_BRANDING_CLASSIC"
                              data-promotion-id="0"
                              data-price="1499"
                              data-price-text="$1499.00"
                              data-title="Branding Classic"
                              data-package-id="4217"
                            >
                              Order Now
                            </a>
                          </div>
                          <div class="fright">
                            <div class="liovechats-bx">
                              <a
                                href="javascript:;"
                                title=""
                                  onClick={handleClick}
                              >
                                <span>Click here to</span>
                                <h5 class="montfont"   onClick={handleClick}>
                                  Live Chat
                                </h5>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                  <div class="col-lg-3">
                    <div class="boxpackages" data-package-box>
                      <div class="packheads">
                        <h3 class="montfont">Branding Ultimate</h3>
                        <p style={{ display: "none" }}>
                          Ideal for beginners and start-ups
                        </p>
                      </div>
                      <div class="packdetails">
                        <div class="packtitles">
                          <div class="fleft">
                            <h3 class="opensansfont">
                              $1999 <sub class="">60% off</sub>
                            </h3>
                          </div>
                          <div class="fright">
                            <h4>
                              WAS
                              <b>
                                <span class="cutprice">$6663</span>
                              </b>
                            </h4>
                          </div>
                        </div>
                        <div class="scroll listpacks" data-package-scroll>
                          <ul>
                            <li>
                              <span>Logo Design</span>
                            </li>
                            <li>Infinite Unique Logo Concepts</li>
                            <li>FREE Icon </li>
                            <li>FREE Grayscale Copy </li>
                            <li>Unlimited Revisions </li>
                            <li>100% Ownership Right </li>
                            <li>AI, PSD, EPS, GIF, BMP, JPEG, PNG Formats</li>
                            <li>
                              <span>Print Material</span>
                            </li>
                            <li>
                              Stationery Design(Business
                              Card,Letterhead,Envelope)
                            </li>
                            <li>
                               5 pages basic website, Letterhead, Envelop Prints
                            </li>
                            <li>
                              <span>Any Product of your Choice:</span>
                            </li>
                            <li>
                              Bi-fold/Tri-fold Brochure / Menu Card / Corporate
                              Profile
                            </li>
                            <li>Website Design</li>
                            <li>10+ Page Website</li>
                            <li>
                              Content Management System (WordPress/Joomla)
                            </li>
                            <li>Responsive Design (Mobile Compatible)</li>
                            <li>Complete Deployment</li>
                            <li>Social Media Design</li>
                            <li>Facebook Fanpage Splash Page + Cover Photo</li>
                            <li>Twitter Background Design</li>
                            <li>Google+ Profile Banner</li>
                            <li>
                              Complete Storyboard, VoiceOver &amp; Animation
                            </li>
                          </ul>{" "}
                        </div>
                        <div class="botarea-pack">
                          <div class="fleft">
                            <a
                              class="montfont btn-orders order-package"
                              onClick={() => setActive(true)}
                              data-sku="BRANDING_BRANDING_ULTIMATE"
                              data-promotion-id="0"
                              data-price="1999"
                              data-price-text="$1999.00"
                              data-title="Branding Ultimate"
                              data-package-id="4218"
                            >
                              Order Now
                            </a>
                          </div>
                          <div class="fright">
                            <div class="liovechats-bx">
                              <a
                                href="javascript:;"
                                title=""
                                  onClick={handleClick}
                              >
                                <span>Click here to</span>
                                <h5 class="montfont"   onClick={handleClick}>
                                  Live Chat
                                </h5>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                </div>
              </div>
              <div id="pack6" class="fade tab-pane ">
                <div class="row responsive-slider">
                  <div class="col-lg-3">
                    <div class="boxpackages" data-package-box>
                      <div class="packheads">
                        <h3 class="montfont">Startup Web Portal</h3>
                        <p style={{ display: "none" }}>
                          Ideal for beginners and start-ups
                        </p>
                      </div>
                      <div class="packdetails">
                        <div class="packtitles">
                          <div class="fleft">
                            <h3 class="opensansfont">
                              $4999 <sub class="">60% off</sub>
                            </h3>
                          </div>
                          <div class="fright">
                            <h4>
                              WAS
                              <b>
                                <span class="cutprice">$16663</span>
                              </b>
                            </h4>
                          </div>
                        </div>
                        <div class="scroll listpacks" data-package-scroll>
                          <ul>
                            <li class="first">Web portal Startup</li>
                            <li>Dating Portal</li>
                            <li>Job Portal</li>
                            <li>Professional Network</li>
                            <li>Social Network</li>
                            <li>Real Estate Portal</li>
                            <li>Medical Portal</li>
                            <li>News Portal</li>
                            <li>Enterprise Portal</li>
                            <li>Custom Coding</li>
                            <li>Module-wise Architecture</li>
                            <li>Extensive Admin Panel</li>
                            <li>Complete Deployment</li>
                            <li>100% Custom Designs – No Templates</li>
                            <li class="last">
                              Free 12 month Hosting &amp; Domain Registration
                            </li>
                          </ul>{" "}
                        </div>
                        <div class="botarea-pack">
                          <div class="fleft">
                            <a
                              class="montfont btn-orders order-package"
                              onClick={() => setActive(true)}
                              data-sku="WEB_PORTAL_STARTUP_WEB_PORTAL"
                              data-promotion-id="0"
                              data-price="4999"
                              data-price-text="$4999.00"
                              data-title="Startup Web Portal"
                              data-package-id="2814"
                            >
                              Order Now
                            </a>
                          </div>
                          <div class="fright">
                            <div class="liovechats-bx">
                              <a
                                href="javascript:;"
                                title=""
                                  onClick={handleClick}
                              >
                                <span>Click here to</span>
                                <h5 class="montfont"   onClick={handleClick}>
                                  Live Chat
                                </h5>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                  <div class="col-lg-3">
                    <div class="boxpackages" data-package-box>
                      <div class="packheads">
                        <h3 class="montfont">Web Portal Plus</h3>
                        <p style={{ display: "none" }}>
                          Ideal for beginners and start-ups
                        </p>
                      </div>
                      <div class="packdetails">
                        <div class="packtitles">
                          <div class="fleft">
                            <h3 class="opensansfont">
                              $7999 <sub class="">60% off</sub>
                            </h3>
                          </div>
                          <div class="fright">
                            <h4>
                              WAS
                              <b>
                                <span class="cutprice">$26663</span>
                              </b>
                            </h4>
                          </div>
                        </div>
                        <div class="scroll listpacks" data-package-scroll>
                          <ul>
                            <li class="first">Logo Design</li>
                            <li>12 Unique Logo Concepts</li>
                            <li>Unlimited Revisions</li>
                            <li>100% Ownership Right</li>
                            <li>AI, PSD, EPS, GIF, BMP, JPEG, PNG Formats</li>
                            <li>Print Material</li>
                            <li>
                              Stationery Design (Business Card, Letterhead,
                              Envelope)
                            </li>
                            <li>Flyer Design</li>
                            <li>Email Signature</li>
                            <li>
                              Comprehensive Web Portal (any one of your choice*)
                            </li>
                            <li>* Dating Portal</li>
                            <li>* Job Portal</li>
                            <li>* Professional Network</li>
                            <li>* Social Network</li>
                            <li>* Media Portal (Photos/Videos)</li>
                            <li>* Real Estate Portal</li>
                            <li>* Medical Portal</li>
                            <li>* News Portal</li>
                            <li>* Enterprise Portal</li>
                            <li>100% Custom Designs – No Templates</li>
                            <li>Custom Coding</li>
                            <li>Module-wise Architecture</li>
                            <li>Extensive Admin Panel</li>
                            <li>Complete Deployment</li>
                            <li>Search Engine Optimization</li>
                            <li>3 Months SEO Plan</li>
                            <li>10 Keywords</li>
                            <li>Top Ranking on Google</li>
                            <li>Social Media Design</li>
                            <li>Facebook Fanpage Splash Page + Cover Photo</li>
                            <li>Twitter Background Design</li>
                            <li>Google+ Profile Banner</li>
                            <li>Explainer Video</li>
                            <li>30 Second HD Explainer Video</li>
                            <li>
                              Complete Storyboard, VoiceOver &amp; Animation
                            </li>
                            <li>Value-added Services</li>
                            <li>Discounted Price</li>
                            <li>Dedicated Account Manager</li>
                            <li>Satisfaction Guarantee</li>
                            <li>30-days Moneyback Guarantee</li>
                            <li class="last">
                              Free 12 month Hosting &amp; Domain Registration
                            </li>
                          </ul>{" "}
                        </div>
                        <div class="botarea-pack">
                          <div class="fleft">
                            <a
                              class="montfont btn-orders order-package"
                              onClick={() => setActive(true)}
                              data-sku="WEB_PORTAL_WEB_PORTAL_PLUS"
                              data-promotion-id="0"
                              data-price="7999"
                              data-price-text="$7999.00"
                              data-title="Web Portal Plus"
                              data-package-id="2815"
                            >
                              Order Now
                            </a>
                          </div>
                          <div class="fright">
                            <div class="liovechats-bx">
                              <a
                                href="javascript:;"
                                title=""
                                  onClick={handleClick}
                              >
                                <span>Click here to</span>
                                <h5 class="montfont"   onClick={handleClick}>
                                  Live Chat
                                </h5>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                </div>
              </div>
              <div id="pack7" class="fade tab-pane ">
                <div class="row responsive-slider">
                  <div class="col-lg-3">
                    <div class="boxpackages" data-package-box>
                      <div class="packheads">
                        <h3 class="montfont">Bronze - Monthly</h3>
                        <p style={{ display: "none" }}>
                          Ideal for beginners and start-ups
                        </p>
                      </div>
                      <div class="packdetails">
                        <div class="packtitles">
                          <div class="fleft">
                            <h3 class="opensansfont">
                              $397 <sub class="">60% off</sub>
                            </h3>
                          </div>
                          <div class="fright">
                            <h4>
                              WAS
                              <b>
                                <span class="cutprice">$794</span>
                              </b>
                            </h4>
                          </div>
                        </div>
                        <div class="scroll listpacks" data-package-scroll>
                          <ul>
                            <li>Optimize FB Page</li>
                            <li>1 Social Cover</li>
                            <li>Engagement</li>
                            <li>Reviews</li>
                            <li>Check-ins</li>
                            <li>Posts</li>
                            <li>3 Posts per Week</li>
                            <li>1 Facebook Event</li>
                            <li>Monthly Reporting</li>
                            <li>Ad spend - Up to $50</li>
                          </ul>{" "}
                        </div>
                        <div class="botarea-pack">
                          <div class="fleft">
                            <a
                              class="montfont btn-orders order-package"
                              onClick={() => setActive(true)}
                              data-sku="SMM_Bronze - Monthly"
                              data-promotion-id="0"
                              data-price="397"
                              data-price-text="$397.00"
                              data-title="Bronze - Monthly"
                              data-package-id="2793"
                            >
                              Order Now
                            </a>
                          </div>
                          <div class="fright">
                            <div class="liovechats-bx">
                              <a
                                href="javascript:;"
                                title=""
                                  onClick={handleClick}
                              >
                                <span>Click here to</span>
                                <h5 class="montfont"   onClick={handleClick}>
                                  Live Chat
                                </h5>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                  <div class="col-lg-3">
                    <div class="boxpackages" data-package-box>
                      <div class="packheads">
                        <h3 class="montfont">Silver- Monthly</h3>
                        <p style={{ display: "none" }}>
                          Ideal for beginners and start-ups
                        </p>
                      </div>
                      <div class="packdetails">
                        <div class="packtitles">
                          <div class="fleft">
                            <h3 class="opensansfont">
                              $597 <sub class="">60% off</sub>
                            </h3>
                          </div>
                          <div class="fright">
                            <h4>
                              WAS
                              <b>
                                <span class="cutprice">$1194</span>
                              </b>
                            </h4>
                          </div>
                        </div>
                        <div class="scroll listpacks" data-package-scroll>
                          <ul>
                            <li>Optimize FB Page</li>
                            <li>1 Social Cover</li>
                            <li>Engagement</li>
                            <li>Reviews</li>
                            <li>Check-ins</li>
                            <li>Posts</li>
                            <li>Shares</li>
                            <li>Mentions</li>
                            <li>Invite to Like</li>
                            <li>Inbox Responses</li>
                            <li>4 Posts per Week</li>
                            <li>2 Facebook Events</li>
                            <li>Monthly Reporting</li>
                            <li>Ad Spend - Up to $65</li>
                          </ul>{" "}
                        </div>
                        <div class="botarea-pack">
                          <div class="fleft">
                            <a
                              class="montfont btn-orders order-package"
                              onClick={() => setActive(true)}
                              data-sku="SMM_Silver- Monthly"
                              data-promotion-id="0"
                              data-price="597"
                              data-price-text="$597.00"
                              data-title="Silver- Monthly"
                              data-package-id="2794"
                            >
                              Order Now
                            </a>
                          </div>
                          <div class="fright">
                            <div class="liovechats-bx">
                              <a
                                href="javascript:;"
                                title=""
                                  onClick={handleClick}
                              >
                                <span>Click here to</span>
                                <h5 class="montfont"   onClick={handleClick}>
                                  Live Chat
                                </h5>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                  <div class="col-lg-3">
                    <div class="boxpackages" data-package-box>
                      <div class="packheads">
                        <h3 class="montfont">Gold- Monthly</h3>
                        <p style={{ display: "none" }}>
                          Ideal for beginners and start-ups
                        </p>
                      </div>
                      <div class="packdetails">
                        <div class="packtitles">
                          <div class="fleft">
                            <h3 class="opensansfont">
                              $997 <sub class="">60% off</sub>
                            </h3>
                          </div>
                          <div class="fright">
                            <h4>
                              WAS
                              <b>
                                <span class="cutprice">$1994</span>
                              </b>
                            </h4>
                          </div>
                        </div>
                        <div class="scroll listpacks" data-package-scroll>
                          <ul>
                            <li>Optimize FB Page</li>
                            <li>1 Social Cover</li>
                            <li>Engagement</li>
                            <li>Reviews</li>
                            <li>Check-ins</li>
                            <li>Posts</li>
                            <li>Shares</li>
                            <li>Mentions</li>
                            <li>Invite to Like</li>
                            <li>Inbox Responses</li>
                            <li>5 Posts per Week</li>
                            <li>2 Facebook Events</li>
                            <li>1 Lead Generation Campaign</li>
                            <li>Monthly Reporting</li>
                            <li>Ad Spend - Up to $120</li>
                          </ul>{" "}
                        </div>
                        <div class="botarea-pack">
                          <div class="fleft">
                            <a
                              class="montfont btn-orders order-package"
                              onClick={() => setActive(true)}
                              data-sku="SMM_Gold- Monthly"
                              data-promotion-id="0"
                              data-price="997"
                              data-price-text="$997.00"
                              data-title="Gold- Monthly"
                              data-package-id="2795"
                            >
                              Order Now
                            </a>
                          </div>
                          <div class="fright">
                            <div class="liovechats-bx">
                              <a
                                href="javascript:;"
                                title=""
                                  onClick={handleClick}
                              >
                                <span>Click here to</span>
                                <h5 class="montfont"   onClick={handleClick}>
                                  Live Chat
                                </h5>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                  <div class="col-lg-3">
                    <div class="boxpackages" data-package-box>
                      <div class="packheads">
                        <h3 class="montfont">Platinum- Monthly</h3>
                        <p style={{ display: "none" }}>
                          Ideal for beginners and start-ups
                        </p>
                      </div>
                      <div class="packdetails">
                        <div class="packtitles">
                          <div class="fleft">
                            <h3 class="opensansfont">
                              $1497 <sub class="">60% off</sub>
                            </h3>
                          </div>
                          <div class="fright">
                            <h4>
                              WAS
                              <b>
                                <span class="cutprice">$2994</span>
                              </b>
                            </h4>
                          </div>
                        </div>
                        <div class="scroll listpacks" data-package-scroll>
                          <ul>
                            <li>Optimize FB Page</li>
                            <li>1 Social Cover + Holiday Covers</li>
                            <li>Engagement</li>
                            <li>Reviews</li>
                            <li>Check-ins</li>
                            <li>Posts</li>
                            <li>Shares</li>
                            <li>Mentions</li>
                            <li>Invite to Like</li>
                            <li>Inbox Responses</li>
                            <li>6 Posts per Week</li>
                            <li>2 Facebook Events</li>
                            <li>1 Lead Generation Campaign</li>
                            <li>Monthly Reporting</li>
                            <li>Ad Spend - Up to $170</li>
                          </ul>{" "}
                        </div>
                        <div class="botarea-pack">
                          <div class="fleft">
                            <a
                              class="montfont btn-orders order-package"
                              onClick={() => setActive(true)}
                              data-sku="SMM_Platinum- Monthly"
                              data-promotion-id="0"
                              data-price="1497"
                              data-price-text="$1497.00"
                              data-title="Platinum- Monthly"
                              data-package-id="2796"
                            >
                              Order Now
                            </a>
                          </div>
                          <div class="fright">
                            <div class="liovechats-bx">
                              <a
                                href="javascript:;"
                                title=""
                                  onClick={handleClick}
                              >
                                <span>Click here to</span>
                                <h5 class="montfont"   onClick={handleClick}>
                                  Live Chat
                                </h5>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                </div>
              </div>
              <div id="pack8" class="fade tab-pane ">
                <div class="row responsive-slider"></div>
              </div>
            </div>

            <div class="row packagesall">
              <div class="col-md-12 portfolio-mmbx">
                {currentPath !== "/packages" && (
                  <div class="leftcont-ban">
                    <a
                      class="montfont"
                      onClick={(ref, id) =>
                        scrollTo((ref = "/packages"), (id = "pricing"))
                      }
                      title=""
                    >
                      View All Packages
                      <i class="sprite btnarow"></i>
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

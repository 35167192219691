import React from 'react'
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import { useContext } from 'react';
import TawkContext from './TawkContext';





export default function Chat() {
    const tawkRef = useContext(TawkContext);
  return (
   <>

<TawkMessengerReact 
                propertyId="64969b3d94cf5d49dc5f8eac"
        widgetId="1h3m4sqga"
        ref={tawkRef}
                />

   </> 
  )
}

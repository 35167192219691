import React from 'react'

export default function Banner_packages() {

  return (
    <>
    <div className="logopackages-p" id="package_top">
    <section class="bannermm">
         <div class="container">
            <div class="row">
               <div class="col-lg-6 col-md-6 col-sm-12">
                  <div class="leftcont-ban">
                     <h1 class="montfont"><span>Highly Affordable  </span> <br/><strong>Design Solutions</strong> To Meet Your Brand's Needs</h1>
                     <p>Experience the industry's best deals with The Logos Designers.</p>
                  </div>
               </div>
            </div>
         </div>
      </section>  
    </div>
    </>
  )
}
import React from 'react'
import Annimation from '../Sections/Annimation'
import Testimonail_Section from '../Sections/Testimonail_Section'



export const Animation_design = () => {
  return (
    <>
    <Annimation/>
    <Testimonail_Section/>
    </>
  )
}


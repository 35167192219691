import React, { useState } from 'react'
import Modal from './Modal2'


export default function Banner_porfolio() {
   const [active,setActive] = useState(false)
  return (
    <>
    <div className='portfolio'>
    <section className="bannermm">
         <div className="container">
            <div className="row">
               <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="leftcont-ban">
                     <h1 className="montfont"><span>Get Visually  </span> <br/><strong>Striking Designs  </strong> and Achieve an Edge</h1>
                     <p>Work With Creative Team of Designers.</p>
                     <a className="montfont fixedfomrclick popup-open" onClick={()=>setActive(true)} title="">Start Your Project <i className="sprite btnarow"></i></a>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <Modal active={active} setActive={setActive}/>
    </div>
    </>
  )
}

import { Route, Routes } from 'react-router-dom';
import { Home } from './Pages/Home';
import { About } from './Pages/About';
import { Portfoli } from './Pages/Porfoli'
import { Contact_main } from './Pages/Contact_main';
import { Packages } from './Pages/Packages';
import { Main_logo } from './Pages/Main_logo';
import { Review } from './Pages/Review'
import { Website } from './Pages/Website'
import { Nft_design } from './Pages/Nft_design'
import { Animation_design } from './Pages/Animation_design'
import { Branding_design } from './Pages/Branding_design';


import './Styles/App.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Privacy from './Pages/Privacy';
import Terms from './Pages/Terms';
// import 'cleave.js/dist/addons/cleave-phone.i18n.js'




function App() {
  return (
    <>
    <Routes>
      <Route path="/" element={<Home/>} />
      <Route path="/about" element={<About/>}/>
      <Route path="/portfolio" element={<Portfoli/>}/>
      <Route path="/contact" element={<Contact_main/>}/>
      <Route path="/packages" element={<Packages/>}/>
      <Route path="/logo-design" element={<Main_logo/>}/>
 
      <Route path="/review" element={<Review/>}/>
      <Route path="/website" element={<Website/>}/>
      <Route path="/nft-design" element={<Nft_design/>}/>
      <Route path="/animation-design" element={<Animation_design/>}/>
      <Route path="/branding-design" element={<Branding_design/>}/>
      <Route path="/privacy" element={<Privacy/>}/>
      <Route path="/terms-condition" element={<Terms/>}/>
      

      

      
    </Routes>
    </>
    )
}

export default App;

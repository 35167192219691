import React, { createContext, useRef } from 'react';

const TawkContext = createContext();

export function TawkProvider({ children }) {
  const tawkRef = useRef();

  return (
    <TawkContext.Provider value={tawkRef}>
      {children}
    </TawkContext.Provider>
  );
}

export default TawkContext;

import React, { useState } from 'react'
import Modal from './Modal2'
import { scroller } from "react-scroll";
import { useNavigate } from 'react-router-dom';


export default function Portfolio() {
   const [active,setActive] = useState(false)
   const navigate = useNavigate();
  
   const scrollTo = (ref, id) => {
     navigate(ref);
     setTimeout(() => {
       scroller.scrollTo(id, {
         spy: true,
         smooth: true,
         offset: 0,
         duration: 500,
       });
     }, 100);
   };
  return (

    <>
     <section className="maincta" data-img="url(images/hero-1.jpg) ">
         <div className="container">
            <div className="row">
               <div className="col-lg-5 col-md-6 col-sm-7 padright0">
                  <h3 className="montfont">Together we create a <strong>Unique Digital Entity!</strong> </h3>
                  <p className="montfont">Contact our experts NOW to begin crafting your project.</p>
               </div>
               <div className="col-lg-7 col-md-6 col-sm-5 padright0">
                  <div className="btn-radius-top">
                     <a onClick={(ref, id) =>
                                scrollTo(
                                  (ref = "/packages"),
                                  (id = "pricing")
                                )
                              }  title="">Select Package</a>
                     <a onClick={()=>setActive(true)} className="popup-open" href="javascript:;" title=""><i className="fa fa-pencil-square-o" aria-hidden="true"></i> Request Custom Quote</a>
                  </div>
                  <div className="callsdivs montfont"><i className="sprite iconphone"></i>
                     <a href="tel:+442032898634" title="">Local Number: +1 917 267 7486</a>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <Modal active={active} setActive={setActive}/>
    </>
    )
}
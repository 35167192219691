import React, { useState } from 'react'
import Modal from './Modal2'
import { NavLink } from 'react-router-dom'



export const Test = ({text1,text2,text3,text4,title,image,link1text,link2text}) => {
   const [active,setActive] = useState(false)

  return (
    <>
    <section class="nft bannermm" style={{backgroundImage:`url(${image})`}}>
      <div class="container">
         <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12">
               <div class="leftcont-ban">
                  <h1 class="montfont"><span>{text1} </span> <br/><strong>{text2}</strong> {text3}</h1>
                  <p>{text4}</p>
                  <a onClick={()=>setActive(true)} class="montfont poundbtn animbtnsstart popup-open"  title="">{title}<i class="sprite btnarow"></i></a>
               </div>
            </div>
         </div>
      </div>
      
   </section>
   <Modal active={active} setActive={setActive}/>
   
   <section class="brdcrme">
      <div class="container">
         <div class="row">
            <div class="col-lg-12 col-md-12">
               <div class="content-incred">
                  <ul>
                     <li><NavLink to={"/"} >{link1text}</NavLink></li>
                     <li><NavLink to={"/nft-design"}>{link2text}</NavLink></li>
                  </ul>
               </div>
            </div>
         </div>
      </div>
   </section>
    </>
  )
}

import React, { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Link, animateScroll as scroll } from "react-scroll";

import Modal from './Modal2';



const Hero_Section = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    autoplay: false,
    autoplaySpeed: 2500,
  };
  const [active,setActive] = useState(false)
  return (
   <>
    <Modal active={active} setActive={setActive}/>
   <div class="mainhome mainsliders-homes">
    <Slider {...settings}>
            <div className="bannermm  bnj-1">
            <div className="container">
               <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                     <div className="leftcont-ban">
                        <h1 className="montfont">
                           <span>Impactful </span>
                           <br/>
                           <strong>Logo Designs</strong> That Speak For Your Brand
                        </h1>
                        <p>Spark Visual Identity of Your Brand with Our Persuasive Designs.</p>
                        <a class="montfont popup-open" onClick={()=>setActive(true)} title="">Start Your Logo Project from $21
                        <i class="sprite btnarow"></i>
                        </a>
                     </div>
                  </div>
               </div>
               <div className="scrolldown-fl">
                  <p>Scroll Down</p>
                  <Link
                                to="section08"
                                spy={true}
                                smooth={true}
                                offset={50}
                                duration={500}>
                  <span className="dotbol"></span>
                  <span className="dotbol"></span>
                  <span className="dotbol"></span>
                  <span></span>
                  </Link>
               </div>
            </div>
         </div>
         <div className="bannermm webbanner  bnj-2">
            <div className="container">
               <div className="row">
                  <div className="col-lg-7 col-md-7 col-sm-12">
                     <div className="leftcont-ban">
                        <h1 className="montfont">
                           <span>Exclusively </span>
                           <br/>
                           <strong>Designed Websites</strong> to Uplift Your Brand
                        </h1>
                        <p>Our web design & development services make you more accessible to your target market.</p>
                        <a class="montfont popup-open" onClick={()=>setActive(true)} title="" tabindex="0">  Start Your Web Project from $399
                        <i class="sprite btnarow"></i>
                        </a>
                     </div>
                  </div>
               </div>
               <div className="scrolldown-fl">
                  <p>Scroll Down</p>
                  
                  <Link
                                to="section08"
                                spy={true}
                                smooth={true}
                                offset={50}
                                duration={500}>
                  <span className="dotbol"></span>
                  <span className="dotbol"></span>
                  <span className="dotbol"></span>
                  <span></span>
                  </Link>
               </div>
            </div>
         </div>
         <div className="bannermm animbanner bnj-3">
            <div className="container">
               <div className="row">
                  <div className="col-lg-7 col-md-7 col-sm-12">
                     <div className="leftcont-ban">
                        <h1 className="montfont">
                           <span>Striking </span>
                           <br/>
                           <strong>Animated Videos </strong> to Illustrate your Brand's Story
                        </h1>
                        <p>Spread your message through the most effective medium.</p>
                        <a className="montfont popup-open" onClick={()=>setActive(true)} title="">Start Your Animation Project
                        <i className="sprite btnarow"></i>
                        </a>
                     </div>
                  </div>
               </div>
               <div className="scrolldown-fl">
                  <p>Scroll Down</p>
                  <Link
                                to="section08"
                                spy={true}
                                smooth={true}
                                offset={50}
                                duration={500}>
                  <span className="dotbol"></span>
                  <span className="dotbol"></span>
                  <span className="dotbol"></span>
                  <span></span>
                  </Link>
               </div>
            </div>
         </div>
    </Slider>
   </div>
   </>
  );
};

export default Hero_Section;
import React, { useState } from "react";
import Modal from "./Modal2";

export default function Bundle() {
   const [active, setActive] = useState(false);
  return (
    <>
    
    <Modal active={active} setActive={setActive} />
      <section
        className="enterprices-bundles"
        data-img="url(images/startup-bundle.png)"
      >
        <div className="container">
          <div className="sechead">
            <h3 className="montfont">Enterprise Startup Bundle</h3>
            <p>
              The Logos Designers offers the best bespoke design solutions to
              new business entrepreneurs in our Enterprise Startup Bundle.
            </p>
          </div>
          <div className="row">
            <div className="col-md-8">
              <div className="row">
                <div className="col-md-4">
                  <div className="pacbundles-det">
                    <div className="headbx-bundle">
                      <div className="fleft">
                        <h4 className="montfont martop15">Logo</h4>
                        <div className="packbundleprice">
                          Worth
                          <span>$550</span>
                        </div>
                      </div>
                      <ul className="listbundles-packbx">
                        <li className=""> Unlimited Logo Design Concepts</li>
                        <li>8 Dedicated Designers </li>
                        <li>Unlimited Revisions</li>
                        <li>Free Icon</li>
                        <li>48-72 Hours Turnaround Time</li>
                        <li>Unlimited Revisions</li>
                        <li>Dedicated Account Manager</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="pacbundles-det">
                    <div className="headbx-bundle">
                      <div className="fleft">
                        <h4 className="montfont martop15">Website</h4>
                        <div className="packbundleprice">
                          Worth
                          <span>$1,830</span>
                        </div>
                      </div>
                      <ul className="listbundles-packbx">
                        <li className="haschildren ">
                          <span className="child-tooltip"></span> 8 Page Custom
                          Website
                          <ul>
                            <li>10 Stock Photos</li>
                          </ul>
                        </li>
                        <li>Complete W3C Certified HTML</li>
                        <li>Google Friendly Sitemap</li>
                        <li>Google Page Speed Optimization </li>
                        <li>All Browser Compatibility </li>
                        <li>Content Management System included </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="pacbundles-det">
                    <div className="headbx-bundle">
                      <div className="fleft">
                        <h4 className="montfont martop15">Branding</h4>
                        <div className="packbundleprice">
                          Worth
                          <span>$1,615</span>
                        </div>
                      </div>
                      <ul className="listbundles-packbx">
                        <li className="haschildren ">
                          <span className="child-tooltip"></span> 4 Banner
                          Design <br />
                          Concepts
                          <ul>
                            <li>Any Size </li>
                            <li>6 Stock Photos</li>
                          </ul>
                        </li>
                        <li className="haschildren ">
                          <span className="child-tooltip"></span> Tri-Fold
                          Brochure
                          <ul>
                            <li>1 Design Concept</li>
                          </ul>
                        </li>
                        <li className="haschildren ">
                          <span className="child-tooltip"></span> Product
                          Packaging
                          <ul>
                            <li>2 Unique Design</li>
                          </ul>
                        </li>
                        <li>4 Packaging label Concepts</li>
                        <li>1 Product Catalog Design</li>
                        <li className="haschildren ">
                          <span className="child-tooltip"></span> 3 Stationery
                          Design Concepts
                          <ul>
                            <li>Free MS Letterhead</li>
                            <li>Business Cards</li>
                            <li>1 Invoice Design</li>
                          </ul>
                        </li>
                        <li>Free Fax Template</li>
                        <li>Unlimited Revisions</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="totalpackagespricess">
                <h4 className="montfont">
                  Total Package:{" "}
                  <b>
                    <span>$3995</span>
                  </b>{" "}
                  <span className="yellcol">$1799</span>
                </h4>
                <div className="btn-orders-bundles">
                  <a
                    className="montfont popup-open"
                    onClick={() => setActive(true)}
                  >
                    <span>Order Now</span>
                  </a>
                  <p>*Limited Time Offer</p>
                </div>
              </div>
            </div>
            <div className="col-md-4 md-hidden">
              <div className="imgbundles">
                <img src="images/startupbundles-img1.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

import React from 'react'
import { useNavigate } from 'react-router-dom';

import { scroller } from "react-scroll";

export default function Range_Section() {
   
   const navigate = useNavigate();
  
   const scrollTo = (ref, id) => {
     navigate(ref);
     setTimeout(() => {
       scroller.scrollTo(id, {
         spy: true,
         smooth: true,
         offset: 0,
         duration: 500,
       });
     }, 100);
   };
  return (
    <>
      <section class="what-offer" data-img="url(images/whatoffer-bg.jpg)">
         <div class="container">
            <h3 class="montfont">Comprehensive Range Of Services We Offer</h3>
            <p>Build Your Business with Our 360&#176; Designing & Branding Solutions</p>
            <ul class="slider-2" >
               <li>
                  <div onClick={(ref, id) =>
                                scrollTo(
                                  (ref = "/logo-design"),
                                  (id = "root")
                                )
                              }>
                     <h3 class="montfont redcol">Logo
                        <br/>Design
                     </h3>
                     <p>Get noticed by your audience with high-Quality, professional and affordable Logo Designs.</p>
                     <a  title="">Explore
                     <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                     </a>
                  </div>
                  <i class="sprite icon1"></i>
               </li>
               <li>
                  <i class="sprite icon2"></i>
                  <div onClick={(ref, id) =>
                                scrollTo(
                                  (ref = "/website"),
                                  (id = "root")
                                )
                              }>
                     <h3 class="montfont redcol">Website
                        <br/> Design
                     </h3>
                     <p>The Logos Designers offers a variety of web design services from mock-ups, to full website redesigns.</p>
                     <a  title="">Explore
                     <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                     </a>
                  </div>
               </li>
               <li>
                  <div onClick={(ref, id) =>
                                scrollTo(
                                  (ref = "/branding-design"),
                                  (id = "root")
                                )
                              }>
                     <h3 class="montfont redcol">Branding
                        <br/> Design
                     </h3>
                     <p>In a strategic and holistic approach to branding, The Logos Designers is an expert, which thrives on change.</p>
                     <a  title="">Explore
                     <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                     </a>
                  </div>
                  <i class="sprite icon3"></i>
               </li>
               <li>
                  <i class="sprite icon4"></i>
                  <div onClick={(ref, id) =>
                                scrollTo(
                                  (ref = "/animation-design"),
                                  (id = "root")
                                )
                              }>
                     <h3 class="montfont redcol">Animated
                        <br/> Videos
                     </h3>
                     <p>From concept to completion, The Logos Designers provides a full suite of illustrative animation to glow your business.
                     </p>
                     <a  title="">Explore
                     <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                     </a>
                  </div>
               </li>
            </ul>
         </div>
      </section>
    </>
  )
}
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Site_about = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    autoplay: false,
    autoplaySpeed: 2500,
  };
 
  return (
    <>
      <section className="incred-expe" id="section08">
        <div className="container fullslider">
          <Slider {...settings}>
            <div className="slides-mm">
              <div className="col-lg-6">
                <div className="content-incred">
                  <h3 className="montfont">
                    Altering the ways of
                    <strong className="redcol"> business growth</strong> with our
                    <strong className="redcol"> innovative design solutions.</strong>
                  </h3>
                  <p className="fnt-18">
                    The Logos Designers is a single stop solution for all your designing
                    needs. We create logos, websites, brands and animations to
                    give a boost to your brand.
                  </p>
                  <p>
                    {" "}
                    With an experience spread over a decade, we have marked our
                    positive presence in the market by enriching some of the
                    most prestigious names. With our comprehensive approach, we
                    have been able to create designs that reciprocate your
                    originality and brand essence.{" "}
                  </p>
                </div>
              </div>
              <div>
                <img src="images/strategy-design.png" alt="" />
              </div>
            </div>
            <div className="slides-mm">
              <div className="col-lg-6">
                <div className="content-incred">
                  <h3 className="montfont">
                    Providing a
                    <strong className="redcol"> competitive edge</strong> while
                    keeping your
                    <strong className="redcol">original identity</strong> and
                    vision.
                  </h3>
                  <p className="fnt-18">
                    Our designs either logo, website, or corporate branding,
                    instil the basic concept that your brand originally carries,
                    but giving it a competitive edge that innovates the whole
                    outlook.
                  </p>
                  <p>
                    {" "}
                    With our range of services we can alter the overall
                    personality of your brand, in compliance with your vision.
                    Our created websites, logos, banners, packaging designs,
                    brochures and animated videos will serve as a catalyst to
                    attract customers and providing a business upsurge.{" "}
                  </p>
                </div>
              </div>
              <div>
                <img src="images/strategy-design2.png" alt="" />
              </div>
            </div>
            <div className="slides-mm">
              <div className="col-lg-6">
                <div className="content-incred">
                  <h3 className="montfont">
                    Make your
                    <strong className="redcol"> brand’s success</strong>, an
                    exciting and enthralling journey.
                  </h3>
                  <p className="fnt-18">
                    The Logos Designers aims at building businesses from an unknown entity
                    to a popular marque recognized by the customers as a success
                    story.
                  </p>
                  <p>
                    {" "}
                    Our team of exceptionally creative designers provide
                    innovative solutions for your business’s recognition among
                    the masses. We ensure to make your journey as exciting as
                    possible by sharing our enthusiasm and eagerness to serve
                    you the aesthetically best designs.{" "}
                  </p>
                </div>
              </div>
              <div>
                <img src="images/strategy-design3.png" alt="" />
              </div>
            </div>
          </Slider>
        </div>
      </section>
    </>
  );
};
export default Site_about;

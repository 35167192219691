import React from 'react'
export default function Banner_about() {
  return (
    <>
    <div className="about" id="top">
    <section className="bannermm">
  <div className="container">
    <div className="row">
      <div className="col-lg-6 col-md-6 col-sm-12">
         <div className="leftcont-ban">
             <h1 className="montfont"><span>A Creative Squad To </span> <br/><strong>Treat Your Brand </strong>  With Brilliance </h1>
             <p>Designs That Make Your Brand Stand Out. </p>
             {/* <!-- <a className="montfont" href="javascript:;" title="">Start Your Project <i className="sprite btnarow"></i></a> --> */}
          </div>
      </div>
    </div>
  </div>
</section>
    </div>
    </>
    )
}
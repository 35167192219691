import React from 'react'

export default function Web_Portfolio() {
  return (
    <>
    
    <div id="innerall3" className="innertabcontents active">
                        <div className="row responsive-slider">
                           <div className="col-md-4">
                              <div className="box-ports">
                                 <img data-src="images/portfolio/website/thumbnail/01.jpg" alt=""/>
                                 <div className="hovereffects-det">
                                    <h3>Ka Pai</h3>
                                    <div className="btnstyles-nn">
                                       <a data-fancybox="website" href="images/portfolio/website/Web-3.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div className="col-md-4">
                              <div className="box-ports">
                                 <img data-src="images/portfolio/website/thumbnail/02.jpg" alt=""/>
                                 <div className="hovereffects-det">
                                    <h3>Shipper Link</h3>
                                    <div className="btnstyles-nn">
                                       <a data-fancybox="website" href="images/portfolio/website/web-2.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div className="col-md-4">
                              <div className="box-ports">
                                 <img data-src="./images/portfolio/website/thumbnail/02.png" alt=""/>
                                 <div className="hovereffects-det">
                                    <h3>It Consulting</h3>
                                    <div className="btnstyles-nn">
                                       <a data-fancybox="website" href="./images/portfolio/website/02.webp" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           {/* <div className="col-md-4">
                              <div className="box-ports">
                                 <img data-src="images/portfolio/website/thumbnail/03.jpg" alt=""/>
                                 <div className="hovereffects-det">
                                    <h3>Clinical Pilates Education</h3>
                                    <div className="btnstyles-nn">
                                       <a data-fancybox="website" href="images/portfolio/website/web-4.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div className="col-md-4">
                              <div className="box-ports">
                                 <img data-src="images/portfolio/website/thumbnail/04.jpg" alt=""/>
                                 <div className="hovereffects-det">
                                    <h3>BMApps</h3>
                                    <div className="btnstyles-nn">
                                       <a data-fancybox="website" href="images/portfolio/website/web-5.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div className="col-md-4">
                              <div className="box-ports">
                                 <img data-src="images/portfolio/website/thumbnail/05.jpg" alt=""/>
                                 <div className="hovereffects-det">
                                    <h3>Cauragi</h3>
                                    <div className="btnstyles-nn">
                                       <a data-fancybox="website" href="images/portfolio/website/web-6.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div className="col-md-4">
                              <div className="box-ports">
                                 <img data-src="images/portfolio/website/thumbnail/06.jpg" alt=""/>
                                 <div className="hovereffects-det">
                                    <h3>Freshly Budded Photography</h3>
                                    <div className="btnstyles-nn">
                                       <a data-fancybox="website" href="images/portfolio/website/web-7.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div className="col-md-4">
                              <div className="box-ports">
                                 <img data-src="images/portfolio/website/thumbnail/07.jpg" alt=""/>
                                 <div className="hovereffects-det">
                                    <h3>m&g Shop</h3>
                                    <div className="btnstyles-nn">
                                       <a data-fancybox="website" href="images/portfolio/website/web-8.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div className="col-md-4">
                              <div className="box-ports">
                                 <img data-src="images/portfolio/website/thumbnail/08.jpg" alt=""/>
                                 <div className="hovereffects-det">
                                    <h3>Noble Health</h3>
                                    <div className="btnstyles-nn">
                                       <a data-fancybox="website" href="images/portfolio/website/web-9.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div className="col-md-4">
                              <div className="box-ports">
                                 <img data-src="images/portfolio/website/thumbnail/09.jpg" alt=""/>
                                 <div className="hovereffects-det">
                                    <h3>Australian Harley Adventures</h3>
                                    <div className="btnstyles-nn">
                                       <a data-fancybox="website" href="images/portfolio/website/web-10.jpg" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                    </div>
                                 </div>
                              </div>
                           </div> */}
                        </div>
                        
                     </div>
                     <div id="innerall3" className="innertabcontents active">
                        <div className="row responsive-slider">
                           <div className="col-md-4">
                              <div className="box-ports">
                                 <img data-src="./images/portfolio/website/thumbnail/04.png" alt=""/>
                                 <div className="hovereffects-det">
                                    <h3>Gaming</h3>
                                    <div className="btnstyles-nn">
                                       <a data-fancybox="website" href="./images/portfolio/website/04.webp" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div className="col-md-4">
                              <div className="box-ports">
                                 <img data-src="./images/portfolio/website/thumbnail/05.png" alt=""/>
                                 <div className="hovereffects-det">
                                    <h3>Shipper Link</h3>
                                    <div className="btnstyles-nn">
                                       <a data-fancybox="website" href="./images/portfolio/website/05.webp" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div className="col-md-4">
                              <div className="box-ports">
                                 <img data-src="./images/portfolio/website/thumbnail/06.png" alt=""/>
                                 <div className="hovereffects-det">
                                    <h3>It Consulting</h3>
                                    <div className="btnstyles-nn">
                                       <a data-fancybox="website" href="./images/portfolio/website/06.webp" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                    </div>
                                 </div>
                              </div>
                           </div>
                         
                        </div>
                        
                     </div>
                     <div id="innerall3" className="innertabcontents active">
                        <div className="row responsive-slider">
                           <div className="col-md-4">
                              <div className="box-ports">
                                 <img data-src="./images/portfolio/website/thumbnail/08.png" alt=""/>
                                 <div className="hovereffects-det">
                                    <h3>Gaming</h3>
                                    <div className="btnstyles-nn">
                                       <a data-fancybox="website" href="./images/portfolio/website/08.webp" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div className="col-md-4">
                              <div className="box-ports">
                                 <img data-src="./images/portfolio/website/thumbnail/09.png" alt=""/>
                                 <div className="hovereffects-det">
                                    <h3>Shipper Link</h3>
                                    <div className="btnstyles-nn">
                                       <a data-fancybox="website" href="./images/portfolio/website/09.webp" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div className="col-md-4">
                              <div className="box-ports">
                                 <img data-src="./images/portfolio/website/thumbnail/010.png" alt=""/>
                                 <div className="hovereffects-det">
                                    <h3>It Consulting</h3>
                                    <div className="btnstyles-nn">
                                       <a data-fancybox="website" href="./images/portfolio/website/010.webp" title="">Detail View <i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                    </div>
                                 </div>
                              </div>
                           </div>
                         
                        </div>
                        
                     </div>
    </>
  )
}

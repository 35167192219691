import React from 'react'

import Nft_cards from '../Sections/Nft_cards'
import Portfolio from '../Sections/Portfolio' 
import Testimonail_Section from '../Sections/Testimonail_Section'
import { Test } from '../Sections/test'



export const Nft_design = () => {
    return (
        <>
        
        <Test text1={"Designing Your"} text2={"Non-Fungible Tokens"} text3={" With Utmost Creativity"}  text4={"Turning your NFT idea into an appealing NFT Design that is sure to sell."} title={"Let's Begin Your Project"} image={"./images/nft-design/banner-nft.jpg"} link1text={"Home"}  link2text={'NFT-Design'}/> 
        <Nft_cards/>
        <Portfolio/>
        <Testimonail_Section/>
        </>
        )
}
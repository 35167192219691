import React, { useState } from "react";
import Modal from "./Modal2";
import { NavLink, useNavigate } from "react-router-dom";
import { scroller } from "react-scroll";
import Web_Portfolio from "./Web_Portfolio";
import TawkContext from "../TawkContext";
import { useContext } from "react";

export default function Website_development() {
  const [active, setActive] = useState(false);
  const navigate = useNavigate();

  const scrollTo = (ref, id) => {
    navigate(ref);
    setTimeout(() => {
      scroller.scrollTo(id, {
        spy: true,
        smooth: true,
        offset: 0,
        duration: 500,
      });
    }, 100);
  };
  const tawkRef = useContext(TawkContext);
  

  // You can access the TawkMessengerReact ref through tawkRef.current

  // Example usage
  const handleClick = () => {
    
      tawkRef.current.maximize()
    
  };

  return (
    
    <>
      <div className="web-design" id="top">
        <section class="bannermm">
          <div class="container">
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="leftcont-ban">
                  <h1 class="montfont">
                    <span>Exclusively </span>
                    <br />
                    <strong>Designed Websites</strong> to Uplift Your Brand
                  </h1>
                  <p>Get complete web development services at The Logos Designers.</p>
                  <a
                    class="montfont poundbtn webbtnsstart popup-open"
                    onClick={() => setActive(true)}
                    title=""
                  >
                    Start Your Web Project from $399
                    <i class="sprite btnarow"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <Modal active={active} setActive={setActive} />

      <section class="brdcrme">
        <div class="container">
          <div class="row">
            <div class="col-lg-12 col-md-12">
              <div class="content-incred">
                <ul>
                  {" "}
                  <li>
                  <NavLink to="/" >Home</NavLink>
                  </li>
                  <li>
                 
                    <NavLink to="/website" >Web Designs</NavLink>
                    
                  </li>{" "}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        class="what-offer types-main"
        data-img="url(images/whatoffer-bg.jpg)"
      >
        <div class="container">
          <h3 class="montfont">A Wide Range Of Websites We Offer</h3>
          <p>
            Select the most appropriate category of Web Designs that compliments
            your brand.
          </p>
          <div class="row responsive-slider">
            <div class="col-md-3 col-sm-3 col-xs-12">
              <div class="types-box">
                <img src="images/types-img1.png" alt="" />
                <h5>Static</h5>
                <p>
                  The Logos Designers ensures that your website is looking at its best with
                  a static design and portrays your brand identity in its true
                  sense.{" "}
                </p>
              </div>
            </div>

            <div class="col-md-3 col-sm-3 col-xs-12">
              <div class="types-box">
                <img src="images/types-img2.png" alt="" />
                <h5>E-Commerce</h5>
                <p>
                  Set up the most practical E-commerce platform for your
                  products by The Logos Designers. We design easy to understand and
                  interactive user interface.{" "}
                </p>
              </div>
            </div>

            <div class="col-md-3 col-sm-3 col-xs-12">
              <div class="types-box">
                <img src="images/types-img3.png" alt="" />
                <h5>B2B and B2C Portals</h5>
                <p>
                  We design B2B and B2C portals, to increase the connectivity
                  with in your organization and with your customers.
                </p>
              </div>
            </div>

            <div class="col-md-3 col-sm-3 col-xs-12">
              <div class="types-box">
                <img src="images/types-img4.png" alt="" />
                <h5>CMS</h5>
                <p>
                  With the CMS websites developed by our highly skilled team,
                  you do not need to be an adept with the technicalities.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="homeport-mm" id="web_portfolio">
        <div class="container">
          <div class="sechead">
            <h3 class="montfont">Take A Sneak Peek At Our Portfolio</h3>
            <p>
              We take immense pride for the associations we created with some
              prestigious names through our outstanding designs.
            </p>
          </div>
        </div>

        <div class="portfoliomaindivs tabsmainport">
          <div class="topbartabs">
            <div id="webport" class="tabs-portfolio innertabcontents active">
              <div class="portfolio-mmbx">
                <Web_Portfolio/>

                <div class="container leftcont-ban">
                <a class="montfont" onClick={(ref, id) =>
                                scrollTo(
                                  (ref = "/portfolio"),
                                  (id = "top")
                                )
                              } title="">View All Projects <i class="sprite btnarow"></i></a>
                
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="det-packages" id="web_pricing">
        <div class="container">
          <div class="sechead">
            <h3 class="montfont">
              Affordable Packages Crafted As Per The Needs Of Your Business{" "}
            </h3>
            <p>
              The Logos Designers helps you with design solutions, branding solutions,
              social media solutions and a whole host of creative design ideas.{" "}
            </p>
          </div>
          <div class="packagesbxs">
            <div class="row">
              <div id="pack1" class="tabscontent responsive-slider is-edit-1">
                <div class="col-md-3">
                  <div class="boxpackages" data-package-box>
                    <div class="packheads">
                      <h3 class="montfont">Basic Website</h3>
                      <p style={{ display: "none" }}>
                        Ideal for beginners and start-ups
                      </p>
                    </div>
                    <div class="packdetails">
                      <div class="packtitles">
                        <div class="fleft">
                          <h3 class="opensansfont">
                            $399 <sub class="">60% off</sub>
                          </h3>
                        </div>
                        <div class="fright">
                          <h4>
                            WAS
                            <b>
                              <span class="cutprice">$1330</span>
                            </b>
                          </h4>
                        </div>
                      </div>
                      <div class="scroll listpacks" data-package-scroll>
                        <ul>
                          <li>3 Page Website </li>
                          <li>Custom Layout Design </li>
                          <li>Contact/Query Form </li>
                          <li>1 Banner Design </li>
                          <li>2 Stock Photos </li>
                          <li>
                            <span>FREE Favicon Design</span>
                          </li>
                          <li>Cross Browser Compatible </li>
                          <li> Complete W3C Certified HTML </li>
                          <li>Website Initial Concepts in 48 Hours</li>
                          <li>Complete Design & Deployment </li>
                          <li>Complete Source Files </li>
                          <li>Dedicated Project Manager </li>
                          <li>100% Ownership Rights </li>
                          <li>100% Satisfaction Guarantee </li>
                          <li>100% Money Back Guarantee </li>
                          <li>Value Added Services </li>
                          <li>Mobile Responsive will be Additional $200* </li>
                          <li>CMS will be Additional $250* </li>
                          <li> *NO MONTHLY OR ANY HIDDEN FEE*</li>
                        </ul>{" "}
                      </div>
                      <div class="botarea-pack">
                        <div class="fleft">
                          <a
                            class="montfont btn-orders order-package"
                            onClick={() => setActive(true)}
                            data-sku="WEB_INFORMATIVE_BASIC_WEBSITE"
                            data-promotion-id="0"
                            data-price="399"
                            data-price-text="$399.00"
                            data-title="Basic Website"
                            data-package-id="2787"
                          >
                            Order Now
                          </a>
                        </div>
                        <div class="fright">
                          <div class="liovechats-bx">
                            <a
                              href="javascript:;"
                              title=""
                                onClick={handleClick}
                            >
                              <span>Click here to</span>
                              <h5 class="montfont"   onClick={handleClick}>
                                Live Chat
                              </h5>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>{" "}
                </div>
                <div class="col-md-3">
                  <div class="boxpackages" data-package-box>
                    <div class="packheads">
                      <h3 class="montfont">Startup Website</h3>
                      <p style={{ display: "none" }}>
                        Ideal for beginners and start-ups
                      </p>
                    </div>
                    <div class="packdetails">
                      <div class="packtitles">
                        <div class="fleft">
                          <h3 class="opensansfont">
                            $799 <sub class="">60% off</sub>
                          </h3>
                        </div>
                        <div class="fright">
                          <h4>
                            WAS
                            <b>
                              <span class="cutprice">$2663</span>
                            </b>
                          </h4>
                        </div>
                      </div>
                      <div class="scroll listpacks" data-package-scroll>
                        <ul>
                          <li>5 Page Website </li>
                          <li>Custom Layout Design </li>
                          <li>Contact/Query Form </li>
                          <li>3 Banner Designs </li>
                          <li>5 Stock Photos</li>
                          <li>
                            <span>FREE Favicon Design</span>
                          </li>
                          <li>FREE Google Friendly Sitemap</li>
                          <li>
                            <span>Unlimited Revisions</span>
                          </li>
                          <li>Complete W3C Certified HTML </li>
                          <li>Website Initial Concepts in 48 Hours </li>
                          <li>Complete Design & Deployment </li>
                          <li>Complete Source Files </li>
                          <li>Dedicated Project Manager </li>
                          <li>100% Ownership Rights </li>
                          <li>100% Satisfaction Guarantee </li>
                          <li>100% Money Back Guarantee </li>
                          <li>Value Added Services </li>
                          <li>Mobile Responsive will be Additional $200* </li>
                          <li>CMS will be Additional $250* </li>
                          <li>*NO MONTHLY OR ANY HIDDEN FEE*</li>
                        </ul>{" "}
                      </div>
                      <div class="botarea-pack">
                        <div class="fleft">
                          <a
                            class="montfont btn-orders order-package"
                            onClick={() => setActive(true)}
                            data-sku="WEB_INFORMATIVE_STARTUP_WEBSITE"
                            data-promotion-id="0"
                            data-price="799"
                            data-price-text="$799.00"
                            data-title="Startup Website"
                            data-package-id="2788"
                          >
                            Order Now
                          </a>
                        </div>
                        <div class="fright">
                          <div class="liovechats-bx">
                            <a
                              href="javascript:;"
                              title=""
                                onClick={handleClick}
                            >
                              <span>Click here to</span>
                              <h5 class="montfont"   onClick={handleClick}>
                                Live Chat
                              </h5>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>{" "}
                </div>
                <div class="col-md-3">
                  <div class="boxpackages" data-package-box>
                    <div class="packheads">
                      <h3 class="montfont">Professional Website</h3>
                      <p style={{ display: "none" }}>
                        Ideal for beginners and start-ups
                      </p>
                    </div>
                    <div class="packdetails">
                      <div class="packtitles">
                        <div class="fleft">
                          <h3 class="opensansfont">
                            $1199 <sub class="">60% off</sub>
                          </h3>
                        </div>
                        <div class="fright">
                          <h4>
                            WAS
                            <b>
                              <span class="cutprice">$3996</span>
                            </b>
                          </h4>
                        </div>
                      </div>
                      <div class="scroll listpacks" data-package-scroll>
                        <ul>
                          <li>Up to 10 Unique Pages Website </li>
                          <li>CMS /Admin Panel Integration </li>
                          <li>5+ Stock Photos & Banner Designs </li>
                          <li>FREE Social Media Integration </li>
                          <li>FREE Favicon Design </li>
                          <li>FREE Google Friendly Sitemap</li>
                          <li>Unlimited Revisions</li>
                          <li>Cross Browser Compatible </li>
                          <li>Complete W3C Certified HTML </li>
                          <li>Website Initial Concepts in 48 Hours</li>
                          <li>Complete Design & Deployment</li>
                          <li>Custom, Interactive & Dynamic Web Design </li>
                          <li>
                            Industry specified Team of Expert Designers and
                            Developers{" "}
                          </li>
                          <li>Complete Source Files </li>
                          <li>Dedicated Project Manager </li>
                          <li>100% Ownership Rights </li>
                          <li>100% Satisfaction Guarantee </li>
                          <li>100% Money Back Guarantee </li>
                          <li>Value Added Services </li>
                          <li>Mobile Responsive will be Additional $200* </li>
                          <li>CMS will be Additional $250* </li>
                          <li>*NO MONTHLY OR ANY HIDDEN FEE*</li>
                        </ul>{" "}
                      </div>
                      <div class="botarea-pack">
                        <div class="fleft">
                          <a
                            class="montfont btn-orders order-package"
                            onClick={() => setActive(true)}
                            data-sku="WEB_INFORMATIVE_PROFESSIONAL_WEBSITE"
                            data-promotion-id="0"
                            data-price="1199"
                            data-price-text="$1199.00"
                            data-title="Professional Website"
                            data-package-id="2789"
                          >
                            Order Now
                          </a>
                        </div>
                        <div class="fright">
                          <div class="liovechats-bx">
                            <a
                              href="javascript:;"
                              title=""
                                onClick={handleClick}
                            >
                              <span>Click here to</span>
                              <h5 class="montfont"   onClick={handleClick}>
                                Live Chat
                              </h5>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>{" "}
                </div>
                <div class="col-md-3">
                  <div class="boxpackages" data-package-box>
                    <div class="packheads">
                      <h3 class="montfont">E-Commerce Website</h3>
                      <p style={{ display: "none" }}>
                        Ideal for beginners and start-ups
                      </p>
                    </div>
                    <div class="packdetails">
                      <div class="packtitles">
                        <div class="fleft">
                          <h3 class="opensansfont">
                            $1799 <sub class="">60% off</sub>
                          </h3>
                        </div>
                        <div class="fright">
                          <h4>
                            WAS
                            <b>
                              <span class="cutprice">$5996</span>
                            </b>
                          </h4>
                        </div>
                      </div>
                      <div class="scroll listpacks" data-package-scroll>
                        <ul>
                          <li>Custom Ecommerce Website </li>
                          <li>Up to 200 Products </li>
                          <li>CMS /Admin Panel Integration </li>
                          <li>Fully Mobile Responsive </li>
                          <li>Shopping Cart Integration </li>
                          <li>Payment Gateway Integration</li>
                          <li>
                            <span>Product Listing & Management</span>
                          </li>
                          <li>Order Management & Tracking </li>
                          <li>Banner Designs </li>
                          <li>Unlimited Revisions</li>
                          <li>
                            <span>FREE Social Media Integration</span>
                          </li>
                          <li>FREE Favicon Design </li>
                          <li>
                            FREE Google Friendly Sitemap Search Engine
                            Submission{" "}
                          </li>
                          <li>Complete W3C Certified HTML </li>
                          <li>
                            Industry specified Team of Expert Designers and
                            Developers{" "}
                          </li>
                          <li>Complete Deployment </li>
                          <li>Complete Source Files </li>
                          <li>Dedicated Project Manager </li>
                          <li>100% Ownership Rights </li>
                          <li>100% Satisfaction Guarantee </li>
                          <li>100% Money Back Guarantee </li>
                          <li>*NO MONTHLY OR ANY HIDDEN FEE*</li>
                        </ul>{" "}
                      </div>
                      <div class="botarea-pack">
                        <div class="fleft">
                          <a
                            class="montfont btn-orders order-package"
                            onClick={() => setActive(true)}
                            data-sku="WEB_INFORMATIVE_E_COMMERCE_WEBSITE"
                            data-promotion-id="0"
                            data-price="1799"
                            data-price-text="$1799.00"
                            data-title="E-Commerce Website"
                            data-package-id="2790"
                          >
                            Order Now
                          </a>
                        </div>
                        <div class="fright">
                          <div class="liovechats-bx">
                            <a
                              href="javascript:;"
                              title=""
                                onClick={handleClick}
                            >
                              <span>Click here to</span>
                              <h5 class="montfont"   onClick={handleClick}>
                                Live Chat
                              </h5>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>{" "}
                </div>
                <div class="col-md-3">
                  <div class="boxpackages" data-package-box>
                    <div class="packheads">
                      <h3 class="montfont">Corporate Website</h3>
                      <p style={{ display: "none" }}>
                        Ideal for beginners and start-ups
                      </p>
                    </div>
                    <div class="packdetails">
                      <div class="packtitles">
                        <div class="fleft">
                          <h3 class="opensansfont">
                            $2599 <sub class="">60% off</sub>
                          </h3>
                        </div>
                        <div class="fright">
                          <h4>
                            WAS
                            <b>
                              <span class="cutprice">$8663</span>
                            </b>
                          </h4>
                        </div>
                      </div>
                      <div class="scroll listpacks" data-package-scroll>
                        <ul>
                          <li>Up to 15 Unique Pages Website </li>
                          <li>Custom Made, Interactive & Dynamic Design </li>
                          <li>Customized WordPress or PHP Development </li>
                          <li>Fully Mobile Responsive </li>
                          <li>Interactive Sliding Banners </li>
                          <li>Up to 10 Custom Made Banner Designs </li>
                          <li>
                            <span>10 Stock Images</span>{" "}
                          </li>
                          <li>Unlimited Revisions </li>
                          <li>
                            <span>Content Management System</span>{" "}
                          </li>
                          <li>
                            Online Signup Area (For Newsletters, Offers etc.){" "}
                          </li>
                          <li>Search Bar </li>
                          <li>
                            Live Feeds of Social Networks integration (Optional){" "}
                          </li>
                          <li>Google Friendly Sitemap </li>
                          <li>Search Engine Submission </li>
                          <li>Cross Browser Compatible </li>
                          <li>Complete W3C Certified HTML </li>
                          <li>
                            Industry specified Team of Expert Designers and
                            Developers{" "}
                          </li>
                          <li>Complete Deployment </li>
                          <li>Complete Source Files </li>
                          <li>Dedicated Project Manager </li>
                          <li>100% Ownership Rights </li>
                          <li>100% Satisfaction Guarantee </li>
                          <li>100% Money Back Guarantee </li>
                          <li>*NO MONTHLY OR ANY HIDDEN FEE* </li>
                        </ul>{" "}
                      </div>
                      <div class="botarea-pack">
                        <div class="fleft">
                          <a
                            class="montfont btn-orders order-package"
                            onClick={() => setActive(true)}
                            data-sku="WEB_INFORMATIVE_CORPORATE_WEBSITE"
                            data-promotion-id="0"
                            data-price="2599"
                            data-price-text="$2599.00"
                            data-title="Corporate Website"
                            data-package-id="2791"
                          >
                            Order Now
                          </a>
                        </div>
                        <div class="fright">
                          <div class="liovechats-bx">
                            <a
                              href="javascript:;"
                              title=""
                                onClick={handleClick}
                            >
                              <span>Click here to</span>
                              <h5 class="montfont"   onClick={handleClick}>
                                Live Chat
                              </h5>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>{" "}
                </div>
                <div class="col-md-3">
                  <div class="boxpackages" data-package-box>
                    <div class="packheads">
                      <h3 class="montfont">Platinum Website</h3>
                      <p style={{ display: "none" }}>
                        Ideal for beginners and start-ups
                      </p>
                    </div>
                    <div class="packdetails">
                      <div class="packtitles">
                        <div class="fleft">
                          <h3 class="opensansfont">
                            $3999 <sub class="">60% off</sub>
                          </h3>
                        </div>
                        <div class="fright">
                          <h4>
                            WAS
                            <b>
                              <span class="cutprice">$13330</span>
                            </b>
                          </h4>
                        </div>
                      </div>
                      <div class="scroll listpacks" data-package-scroll>
                        <ul>
                          <li>Up to 20 Unique Pages Website </li>
                          <li>
                            Custom Made, Interactive, Dynamic & High-End Design{" "}
                          </li>
                          <li>Customized WordPress or PHP Development </li>
                          <li>Fully Mobile Responsive </li>
                          <li>Interactive Sliding Banners </li>
                          <li>Up to 15 Custom Made Banner Designs</li>
                          <li>
                            <span>15 Stock Images</span>
                          </li>
                          <li>Unlimited Revisions</li>
                          <li>
                            <span>Content Management System</span>
                          </li>
                          <li>
                            <span>
                              Online Appointment/Booking/Scheduling/Online
                              Ordering Integration (Optional)
                            </span>
                          </li>
                          <li>Online Payment Integration (Optional) </li>
                          <li>Multi Lingual (Optional) </li>
                          <li>Custom Dynamic Forms (Optional) </li>
                          <li>Signup Area (For Newsletters, Offers etc.)</li>
                          <li>
                            <span>Search Bar</span>
                          </li>
                          <li>
                            Live Feeds of Social Networks integration (Optional){" "}
                          </li>
                          <li>Google Friendly Sitemap </li>
                          <li>Search Engine Submission </li>
                          <li>Cross Browser Compatible </li>
                          <li>Complete W3C Certified HTML </li>
                          <li>
                            Industry specified Team of Expert Designers and
                            Developers{" "}
                          </li>
                          <li>Complete Deployment </li>
                          <li>Complete Source Files </li>
                          <li>Dedicated Project Manager </li>
                          <li>100% Ownership Rights </li>
                          <li>100% Satisfaction Guarantee </li>
                          <li>100% Money Back Guarantee </li>
                          <li>*NO MONTHLY OR ANY HIDDEN FEE*</li>
                        </ul>{" "}
                      </div>
                      <div class="botarea-pack">
                        <div class="fleft">
                          <a
                            class="montfont btn-orders order-package"
                            onClick={() => setActive(true)}
                            data-sku="WEB_INFORMATIVE_PLATINUM_WEBSITE"
                            data-promotion-id="0"
                            data-price="3999"
                            data-price-text="$3999.00"
                            data-title="Platinum Website"
                            data-package-id="2792"
                          >
                            Order Now
                          </a>
                        </div>
                        <div class="fright">
                          <div class="liovechats-bx">
                            <a
                              href="javascript:;"
                              title=""
                                onClick={handleClick}
                            >
                              <span>Click here to</span>
                              <h5 class="montfont"   onClick={handleClick}>
                                Live Chat
                              </h5>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>{" "}
                </div>
              </div>
            </div>
            <div class="row packagesall">
              <div class="col-md-12 portfolio-mmbx">
                <div class="leftcont-ban">
                  {/* <!-- <a class="montfont" href="javascript:;" title="">View All Packages <i class="fa fa-caret-down" aria-hidden="true"></i></a> --> */}
                  <a class="montfont" onClick={(ref, id) =>
                                scrollTo(
                                  (ref = "/packages"),
                                  (id = "pricing")
                                )
                              } title="">
                    View All Packages
                    <i class="sprite btnarow"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="how-works">
        <div class="container">
          <div class="sechead">
            <h3 class="montfont">Our Recipe to Create an Exceptional Design</h3>
            <p>
              We observe a thorough and elaborated process to present unique and
              exclusive products.
            </p>
          </div>

          <div class="howworks-mm">
            <div class="row slider-2">
              <div class="col-md-3">
                <div class="designbrief">
                  <div class="processicons">
                    <i class="sprite icon5"></i>
                  </div>
                  <h3>Client Brief </h3>
                  <p>
                    One of our experts will collect client’s design brief and
                    make sure all necessary details are covered in detail.{" "}
                  </p>
                </div>
              </div>
              <div class="col-md-3">
                <div class="designbrief">
                  <div class="processicons">
                    <i class="sprite icon6"></i>
                  </div>
                  <h3>Prototype Design </h3>
                  <p>
                    A prototype design will be created and presented to identify
                    required changes and hitches, if any, so that they can be
                    rectified at an initial stage.{" "}
                  </p>
                </div>
              </div>
              <div class="col-md-3">
                <div class="designbrief">
                  <div class="processicons">
                    <i class="sprite icon7"></i>
                  </div>
                  <h3>Development </h3>
                  <p>
                    Our highly skilled developers will code the website, which
                    will enable website’s functionality as per the client's
                    requirements.{" "}
                  </p>
                </div>
              </div>
              <div class="col-md-3">
                <div class="designbrief">
                  <div class="processicons">
                    <i class="sprite icon8"></i>
                  </div>
                  <h3>Deployment </h3>
                  <p>
                    Final website will be deployed to live server and will be
                    pointed to client’s domain.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="maincta" data-img="url(images/ctabg.jpg) ">
        <div class="container">
          <div class="row">
            <div class="col-lg-5 col-md-6 col-sm-7 padright0">
              <h3 class="montfont">
                Together we create a <strong>Unique Digital Entity!</strong>{" "}
              </h3>
              <p class="montfont">
                Contact our experts NOW to begin crafting your project.
              </p>
            </div>
            <div class="col-lg-7 col-md-6 col-sm-5 padright0">
              <div class="btn-radius-top">
                <a
                  onClick={(ref, id) =>
                    scrollTo((ref = "/packages"), (id = "pricing"))
                  }
                  title=""
                >
                  Select Package
                </a>
                <a class="popup-open" onClick={() => setActive(true)} title="">
                  <i class="fa fa-pencil-square-o" aria-hidden="true"></i>{" "}
                  Request Custom Quote
                </a>
              </div>
              <div class="callsdivs montfont">
                <i class="sprite iconphone"></i>
                <a href="tel:+1 (917) 267 7486" title="">
                  Local Number: +1 (917) 267 7486
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

import React, { useState } from 'react'
import Modal from './Modal2'

export default function Mainlogo_banner() {
   const [active,setActive] = useState(false)
  return (
    <>
    <div className='logodesign-p' id="main_logo">
        <section class="bannermm">
         <div class="container">
            <div class="row">
               <div class="col-lg-6 col-md-6 col-sm-12">
                  <div class="leftcont-ban">
                     <h1 class="montfont">
                        <span>Creating </span>
                        <br/>
                        <strong>Exclusive Designs</strong> to Enhance Your Brand
                     </h1>
                     <p>Stand apart through our brilliant logo designs. </p>
                     <a onClick={()=>setActive(true)}  class="montfont poundbtn logobtnsstart popup-open" href="javascript:;" title="">Start Your Logo Project from $21
                     <i class="sprite btnarow"></i>
                     </a>
                     
                  </div>
               </div>
            </div>
         </div>
      </section>
    </div>
    <Modal active={active} setActive={setActive}/>
    </>
  )
}  

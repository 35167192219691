import React from 'react'
import { useNavigate } from 'react-router-dom';

import { scroller } from "react-scroll";
export default function Footer_site() {
	
	const navigate = useNavigate();
  
  const scrollTo = (ref, id) => {
	
    navigate(ref);
    setTimeout(() => {
      scroller.scrollTo(id, {
        spy: true,
        smooth: true,
        offset: 0,
        duration: 500,
      });
    }, 100);
  };
  return (
    <>
          <footer>
	<div className="container">
		<div className="row listpge">
			<div className="col-md-5ths col-sm-5ths">
				<div className="ulsme">
					<h3 >Logo Services</h3>
					<ul>
						<li><a onClick={(ref, id) =>
                                scrollTo(
                                  (ref = "/logo-design"),
                                  (id = "main_logo")
                                )
                              }>Logo Design</a></li>
						<li><a onClick={(ref, id) =>
                                scrollTo(
                                  (ref = "/logo-design"),
                                  (id = "pricing")
                                )
                              }>Logo Packages</a></li>
						{/* <!-- <li><a href="/javascript:;">How it Works</a></li> --> */}
						<li><a onClick={(ref, id) =>
                                scrollTo(
                                  (ref = "/logo-design"),
                                  (id = "porfolio")
                                )
                              }>Logo Portfolio</a></li>
						<li><a onClick={(ref, id) =>
                                scrollTo(
                                  (ref = "/review"),
                                  (id = "top")
                                )
                              }>Reviews</a></li>
					</ul>
				</div>
			</div>
			<div className="col-md-5ths col-sm-5ths">
				<div className="ulsme">
					<h3>Website Services</h3>
					<ul>
						<li><a onClick={(ref, id) =>
                                scrollTo(
                                  (ref = "/website"),
                                  (id = "top")
                                )
                              }>Website Design</a></li>
						<li><a onClick={(ref, id) =>
                                scrollTo(
                                  (ref = "/website"),
                                  (id = "web_pricing")
                                )
                              }>Website Packages</a></li>
						{/* <!-- <li><a href="/javascript:;">How it Works</a></li> --> */}
						<li><a onClick={(ref, id) =>
                                scrollTo(
                                  (ref = "/website"),
                                  (id = "web_portfolio")
                                )
                              }>Website Portfolio</a></li>
						<li><a onClick={(ref, id) =>
                                scrollTo(
                                  (ref = "/review"),
                                  (id = "top")
                                )
                              }>Reviews</a></li>
					</ul>
				</div>
			</div>
			<div className="col-md-5ths col-sm-5ths">
				<div className="ulsme-ctact">
					<h3>Other Services</h3>
					<ul>
						<li><a href="#">Animated Videos</a></li>
						<li><a href="#">Brochure Design</a></li>
						<li><a href="#">Stationery Design</a></li>
						<li><a href="#">Banner Design</a></li>
						<li><a href="#">Packaging Design</a></li>
					</ul>
				</div>
			</div>
			<div className="col-md-5ths col-sm-5ths">
				<div className="ulsme">
					<h3>Quick Links</h3>
					<ul>
						<li><a onClick={(ref, id) =>
                                scrollTo(
                                  (ref = "/about"),
                                  (id = "top")
                                )
                              }>About Us</a></li>
						<li><a href="#">FAQs</a></li>
						<li><a href="#">Our Blog</a></li>
						<li><a onClick={(ref, id) =>
                                scrollTo(
                                  (ref = "/privacy"),
                                  (id = "top")
                                )
                              }>Privacy & Security</a></li>
						<li><a onClick={(ref, id) =>
                                scrollTo(
                                  (ref = "/terms-condition"),
                                  (id = "top")
                                )
                              }>Terms & Conditions</a></li>
						{/* <!-- <li><a href="/sitemap.php">Sitemap</a></li> --> */}
					</ul>
				</div>
			</div>
			<div className="col-md-5ths col-sm-5ths">
				<div className="ulsme-ctct">
					<h3>Contact Us</h3>
					<ul>
						<li className="phone-list"><a href="tel:+1 917 267 7486">+1 917 267 7486</a></li>
						<li className="email-list"><a href="mailto:info@thelogosdesigners.net">info@thelogosdesigners.net</a></li>
						{/* <!-- <li className="chat-list"><a href="javascript_%3b.html" className="chat">Live Chat</a></li> --> */}
						<li className="address-list"><a>Address: 188 Grand Street 2nd Fl New York, NY 10013</a></li>
					</ul>
				</div>
			</div>
		</div>
		<div className="row brdr-top ">
			<div className="col-md-6 col-sm-6 col-xs-6">
				<div className="footer-logo padd-btd">
					<img className="img-responsive" src="./images/Asset 11.png"/>
				</div>
			</div>
			<div className="col-md-6 col-sm-6 col-xs-64">
				<div className="social-icons padd-btd">
					<h4>Follow Us</h4>
					<a href="https://www.facebook.com/thelogosdesigner" target="_blank"><i className="fa-brands fa-facebook-f"></i></a>
					<a href="https://www.instagram.com/TheLogosDesigners/?hl=en" target="_blank"><i className="fa-brands fa-instagram"></i></a>
					
				</div>
			</div>
		</div>
	</div>
	<div className="footer-bottom">
		<div className="container">
			<div className="row">
				<div className="col-md-12">
					<h4>© 2023 The Logos Designers, All Rights Reserved.</h4>
					<p>Disclaimer: The logo, name and graphics of The Logos Designers and its products & services are the trademarks of The Logos Designers. All other company names, brand names, trademarks and logos mentioned on this website are the property of their respective owners and do not constitute or imply endorsement, sponsorship or recommendation thereof by The Logos Designers and do not constitute or imply endorsement, sponsorship or recommendation of The Logos Designers by the respective trademark owner.</p>
				</div>
			</div>
		</div>
	</div>
</footer>
    </> 
  )
}
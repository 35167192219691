import React from 'react'
import Testimonail_Section from '../Sections/Testimonail_Section'
import Hero_Section from '../Sections/Hero_section'
import Site_about from '../Sections/Site_about'
import Range_section from '../Sections/Range_section'
import Portfolio from '../Sections/Portfolio' 
import Show_case from '../Sections/Show_case'
import Pricing_cards from '../Sections/Pricing_cards'
import Bundle from '../Sections/Bundle'
// import Testimonail_Section from './Sections/Testimonail_Section'


export const Home = () => {
    return (
        <>
            <Hero_Section/>
            <Site_about/>
            <Range_section/>
            <Portfolio/>
            <Show_case/>
            <Pricing_cards/>
            <Bundle/>
            <Testimonail_Section/>          
        </>
    )
}

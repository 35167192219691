import React from 'react'

export default function Ideology_section() {
  return (
    <>  
        <section className="about-phylosophy-bg py-5">
	<div className="container">
    	<div className="sechead">
            <h3 className="montfont">The Ideology We Promote</h3>
            <p>We have crafted our business guidelines based on the ideology of reaching the heights with integrity, pay back, and creativity. We treat every project with a diverse approach to maintain the individuality of every brand. We believe in working hard, delivering best results earning efficiently and sharing it with our competent team.</p>
        </div>


    	<div className="row responsive-serv-area">
        	  <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
            	<div className="phylosophy-box">
                	<i>
                    	<span className="montfont">01</span>
                    </i>
                    <h4 className="montfont">10 Years Experience</h4>
                    <p>We have been serving the industry with remarkable designs for a decade.</p>
                </div>
            </div>
            
            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
            	<div className="phylosophy-box round">
                	<i className="two">
                    	<span className="montfont num-bot">02</span>
                    </i>
                    <h4 className="montfont">5000+ Projects Delivered</h4>
                    <p>Our portfolio flashes 5000+ projects with a diverse range of clientele handled.</p>
                </div>
            </div>
            
            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
            	<div className="phylosophy-box">
                	<i className="three">
                    	<span className="montfont">03</span>
                    </i>
                    <h4 className="montfont">4500+ Satisfied Clients</h4>
                    <p>We take pride in delivering the products won us a huge contented clientele.</p>
                </div>
            </div>
            
            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
            	<div className="phylosophy-box no-sep">
                	<i className="four">
                    	<span className="montfont num-bot">04</span>
                    </i>
                    <h4 className="montfont">1250+ Business Solutions</h4>
                    <p>Offering an extensive variability of business solutions to our clientele all over the globe.</p>
                </div>
            </div>
        </div>
    </div>
</section>
    </>
  )
}
